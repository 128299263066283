import React ,{ useState, useEffect } from "react"
import { useTable, usePagination } from "react-table";
import * as myConstClass from '../utils/fileWithConstants';
import myAxiosInstance from '../utils/axios-intercept';
import classNames from "classnames";
// import axios from "axios";
import "../TableComponent/TableDevice.css";
import { useHistory } from 'react-router-dom';
import { nthIndex } from '../utils/commonFunc';
// import loaderimage from "../PagingComponent/assets/loader-table.gif";


import {
    Table,
    Pagination,
    PaginationItem,
    PaginationLink,
    Alert
} from "reactstrap";
const initialState = {
    queryPageIndex: 0,
    queryPageSize: myConstClass.PAGESIZE,
    totalCount: null,
};

const PAGE_CHANGED = 'PAGE_CHANGED';
const PAGE_SIZE_CHANGED = 'PAGE_SIZE_CHANGED';
const TOTAL_COUNT_CHANGED = 'TOTAL_COUNT_CHANGED';

const reducer = (state, { type, payload }) => {
    switch (type) {
        case PAGE_CHANGED:
            return {
                ...state,
                queryPageIndex: payload,
            };
        case PAGE_SIZE_CHANGED:
            return {
                ...state,
                queryPageSize: payload,
            };
        case TOTAL_COUNT_CHANGED:
            return {
                ...state,
                totalCount: payload,
            };
        default:
            throw new Error(`Unhandled action type: ${type}`);
    }
}
const ShowAlarmTablev2 = ({ onchoiceChange, selectedGroup}) => {
    const [data, setData] = useState("")
    const [isError, setisError] = useState(false)
    const [sortdirection,setSortdirection] = useState("");
    const [isLoading,setisLoading] = useState(false)
    const [canPreviousPage, setcanPreviousPage] = useState(false)
    const [canNextPage, setcanNextPage] = useState(true)
    const [sortkey,setSortkey] = useState("")
    const [sortnextkey,setSortnextkey] = useState("")
    const [sortprekey,setSortprekey] = useState("")
    const [fristsort, setfristSort] = useState()
    const [visible, setvisible] = useState(false)
    const [feedback, setfeedback] = useState("")
    const [alertType, setalertType] = useState("info")
    const history = useHistory();
    const [{ queryPageIndex, queryPageSize, totalCount }, dispatch] =
        React.useReducer(reducer, initialState);
    const columns = React.useMemo(
        () => [
          {
            Header: 'IMEI',
            accessor: 'imei', // accessor is the "key" in the data
          },
        //   {
        //     Header: 'hdralarmStatus',
        //     accessor: 'hdralarmStatus',
        //   },
          {
            Header: '报警',
            accessor: 'alarm_display',
          },
          {
            Header: '用户地址',
            accessor: 'MeterLocation',
          },
          {
            Header: '水表号码',
            accessor: 'deviceId', // accessor is the "key" in the data
          },
          {
            Header: "最后更新",
            accessor: "timestampstring",
            className: "t-cell-6 text-left",
            Cell: ({ cell: { value } }) => {
              if (typeof value !== 'undefined') {
                const timeStampIndex = nthIndex(value, ":", 2);
                const displayTimeStamp = value.substring(0, timeStampIndex);
                return (
                  `${displayTimeStamp}`
                )
              } else {
                return "";
              }
            }
          },
        ],[])

    console.log("you choice to children " ,onchoiceChange )
    console.log("you grp to children " ,selectedGroup )   
    const customnextPage = () => {
        console.log("you press next button")
        setSortdirection("Next")
        setcanPreviousPage(true)
        setSortkey(sortnextkey)
    } 
    const custompreviousPage = () => {
        console.log("you press previous button")
        setSortdirection("Previous")
        setcanNextPage(true)
        setSortkey(sortprekey)
    } 
    const customgotoPage = () =>{
        setcanPreviousPage(false)
        setSortdirection("")
        setSortkey("")
    }

    useEffect( async () => {
        console.log("queryPageSize changed",queryPageSize)
        console.log("sort direction changed",sortdirection)
        console.log("sort key changed",sortkey)
        const token = localStorage.getItem("token");
        const api_host = process.env.REACT_APP_API_URL || myConstClass.REACT_APP_API_URL;
        const uri = `${api_host}/devices/alarm`
        var encodedURI = encodeURI(uri);
        //this is the data sent
        const data_tosend = JSON.stringify({ 'choice': onchoiceChange, 'grp':selectedGroup, "size":queryPageSize,"direction":sortdirection,"sortvalue":sortkey})
        const test = await myAxiosInstance.post(encodedURI,data_tosend,{
        headers: {
                    'Authorization': `ApiKey ${token}`,
                    'Content-Type': 'application/json'
                }
        })
        .then(res => {
            console.log("response: ",res.data); //res catch the while response
            //for previous data we need to reverse the content
            console.log("if i can tacre the direction here",sortdirection)
            //initial call
            if(res.data.length == 0)
            {
                setData(res.data) //?
            }
            if(res.data.length>0)
            {
                if(sortdirection === "")
                {
                    setcanPreviousPage(false)
                    setfristSort(res.data[0]["sort"][0])
                    setData(res.data)
                    if (res.data.length < queryPageSize) {
                        setcanNextPage(false)
                    }        
                    console.log("data1",res.data.length)
                    console.log("data2",queryPageSize)

                }
                if(sortdirection === "Next")
                {               
                    setData(res.data)
                    if (res.data.length < queryPageSize) {
                        setcanNextPage(false)
                    }    
                }
                if(sortdirection === "Previous")
                {
                    console.log("data before reverse",data)
                    console.log("i run the reverse condition here")
                    setData(res.data.reverse())
                    console.log("data after reverse",data)
                    if (res.data[0].sort[0] === fristsort) {
                        setcanPreviousPage(false)
                    }
                }
                // mind the positioning#sequential
                setSortnextkey(res.data[res.data.length-1].sort[0])
                setSortprekey(res.data[0].sort[0])
            }
        })
        .catch(err => {
                const errorObject=JSON.parse(JSON.stringify(err));
                console.log("err",errorObject);
                console.log("error： ", err.statustext);
                setvisible(true);
                setalertType("danger")
                setisError(true)
                setfeedback("錯誤：" + err.statustext);
        })
  
    }, [onchoiceChange, selectedGroup, queryPageSize, sortdirection, sortkey]);
    console.log("data from axios",data)

    //use table hook
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page, // Instead of using 'rows', we'll use page,
        setPageSize,
        state: { pageIndex, pageSize },
      } = useTable(
        {
            columns,
            data: (data) ? data : [],
            initialState: 
            {
                pageIndex: queryPageIndex,
                pageSize: queryPageSize,
            },
            manualPagination: true, // Tell the usePagination
          //initialState: { pageIndex: 0 }, ok 
        },
        usePagination
      )
    useEffect(() => {
    console.log("pageindex change")
    dispatch({ type: PAGE_CHANGED, payload: pageIndex });
    }, [pageIndex]);

    useEffect(() => {
    console.log("pagesize change")
    dispatch({ type: PAGE_SIZE_CHANGED, payload: pageSize });
    }, [pageSize]);

    useEffect(() => {
    console.log("datacount change")

    if (data?.count) {
        dispatch({
        type: TOTAL_COUNT_CHANGED,
        payload: data.count,
        });
    }
    }, [data?.count]);
    function onDismiss() {
        setvisible(false)
    }
    if (isError) {
        return (
            <Alert color={alertType} isOpen={visible} toggle={() => onDismiss()}>
                {feedback}
            </Alert>
        )
    }

    const handleRowClick = (row) => {

        console.log('Just clicked on row', row);
        if (row.original.type !== "device") {
          return;
        }
        if (row.original.lastRecord === null) {
          return;
        }
        //console.log('Device ID:', row.original.oneNetDevieId);
        console.log('Device ID:', row.original.imei);
    
        const deviceNo = row.original.deviceId
        const hdralarmStatus_display = row.original.alarm_display
        const alarmStatus = row.original.hdralarmStatus
        const timestamp = row.original.timestampstring
        const imei = row.original.imei
        const imsi = row.original.imsi
        const deviceName = row.original.deviceName
        console.log('pushing into state:',  deviceNo, alarmStatus, hdralarmStatus_display, row.original.imei,imei);
        history.push({
                pathname: `/Devices/${imei}`,
                state: {
                            deviceNo: deviceNo,
                            currPage: 0,
                            deviceName: deviceName,
                            deviceimsi: imsi,
                            // hdralarmStatus: hdralarmStatus,
                            alarmStatus: alarmStatus,
                            timestamp: timestamp,
                            selectedGroup: selectedGroup,
                            onchoiceChange:onchoiceChange,
                            referredBy: `Alarm`

                }
        })
      }

    return (
        <>
            {data ? (
                <>
                    <Table {...getTableProps()} hover bordered responsive>
                        <thead>
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th {...column.getHeaderProps()}>
                                            {column.render('Header')}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        {page.length === 0 ?
                            <tbody>
                                <tr>
                                    <td colSpan="10000" className="text-left">
                                        <p style={{
                                            "textAlign": "left"
                                        }}
                                        >
                                            没有数据显示
                                        </p>
                                    </td>
                                </tr>
                            </tbody>
                            :
                            <tbody {...getTableBodyProps()}>
                                {page.map((row) => {
                                    prepareRow(row);
                                    return (
                                        <tr {...row.getRowProps()} onClick={()=> handleRowClick(row)}>
                                            {row.cells.map((cell) => (
                                                <td {...cell.getCellProps({
                                                    className: cell.column.className
                                                }
                                                )}>
                                                    {cell.render('Cell')}
                                                </td>
                                            ))}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        }
                    </Table>
                    {page.length > 0 && (
                        <div className={classNames("div-pagination", { "d-none": isLoading })}>
                            <div className="div-pagination-2-2">
                                <div className="div-pagination-2-2">
                                       每页显示{" "}
                                    <select
                                        className="selectan"
                                        value={pageSize}
                                        onChange={(e) => {
                                            setPageSize(Number(e.target.value));
                                        }}
                                    >
                                        {[10, 20, 30, 50, 100].map((pageSize) => (
                                            <option key={pageSize} value={pageSize}>
                                                {pageSize}
                                            </option>
                                        ))}
                                    </select>{" "}
                                    记录
                                </div>
                            </div>

                            <div className="div-pagination-1">
                                <Pagination className="pagina">
                                    <PaginationItem disabled={!canPreviousPage}>
                                        <PaginationLink onClick={() => customgotoPage()}>
                                            {"<<"}
                                        </PaginationLink>
                                    </PaginationItem>
                                    <PaginationItem disabled={!canPreviousPage}>
                                        <PaginationLink onClick={() => custompreviousPage()}>
                                            {"<"}
                                        </PaginationLink>
                                    </PaginationItem>
                                    <PaginationItem disabled={!canNextPage}>
                                        <PaginationLink onClick={() => customnextPage()}>
                                            {">"}
                                        </PaginationLink>
                                    </PaginationItem>
                                </Pagination>
                            </div>
                        </div>
                    )}
                </>
            ) : <p style={
                {
                    "textAlign": "left"
                }
            }>没有数据显示</p>}
        </>
    );
}

export default ShowAlarmTablev2
