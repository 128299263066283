import React, { PureComponent, Fragment } from "react";
import { withRouter } from "react-router";
import { Container, Row, Col, Alert, Input } from "reactstrap";
//import BillTable from "./TableComponent/TableBill";
//import DynamicSelect from "./TableComponent/DynamicSelect";
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import MomentLocaleUtils from 'react-day-picker/moment';
import 'moment/locale/zh-cn';
//import { CSVDownloader } from "react-papaparse";
import './styles.css';
import './TableComponent/Table.css';
import myAxiosInstance from './utils/axios-intercept.jsx';
import GroupMenuNew from "./GroupMenuNew";
import './GroupComponent/GroupTree.css';
import * as myConstClass from './utils/fileWithConstants';
import { QueryClientProvider, QueryClient } from 'react-query';
import moment from 'moment';
import Loader from 'react-loader-spinner';
import WaterBillDayMonthTable from './PagingComponent/WaterBillDayMonthTable'

//headers of the data to be extracted
const extractHeader = [
  {
    //Header: "Billing Type",
    Header: "客户类别",
    accessor: "CustomerBillingType"
  },
  {
    Header: "客户编号",
    //Header: "Billing ID",
    accessor: "CustomerBillingID"
  },
  {
    //Header: "Device ID",
    Header: "水表ID",
    accessor: "did"
  },
  {
    //Header: "Start Date",
    Header: "客户账单开始日期",
    accessor: "start"
  },
  {
    //Header: "Start Volume",
    Header: "客户账单开始计数",
    accessor: "start_volume",
  },
  {
    //Header: "End Date",
    Header: "客户账单终止日期",
    accessor: "end"
  },
  {
    //Header: "End Volume",
    Header: "客户帐单终止计数",
    accessor: "end_volume"
  },
  {
    //Header: "Bill Amount",
    Header: "总计数",
    accessor: "bill"
  },
  {
    //Header: "Billing Name",
    Header: "客户名称",
    accessor: "CustomerBillingName"
  },
  {
    //Header: "Billing Address",
    Header: "客户地址",
    accessor: "CustomerBillingAddress"
  },
  {
    //Header: "Billing Contact",
    Header: "客户联络方式",
    accessor: "CustomerBillingContact"
  },
  {
    //Header: "Billing Cut-off Date",
    Header: "客户账单截数日",
    accessor: "CustomerBillingCutoffDate"
  },
  {
    //Header: "Billing Cycle",
    Header: "客户账单结算周期",
    accessor: "CustomerBillingCycle"
  },
  {
    //Header: "Billing Email",
    Header: "客户电子邮箱",
    accessor: "CustomerBillingEmail"
  },
  {
    //Header: "Billing Gross Floor Area",
    Header: "客户单位建筑面积 (㎡)",
    accessor: "CustomerBillingGrossFloorArea"
  },
  {
    //Header: "Billing Mobile",
    Header: "客户手机号码",
    accessor: "CustomerBillingMobile"
  },
  {
    //Header: "Billing NetFloorArea",
    Header: "客户单位使用面积 (㎡)",
    accessor: "CustomerBillingNetFloorArea"
  },
  {
    //Header: "Billing Population",
    Header: "客户单位人口数",
    accessor: "CustomerBillingPopulation"
  },
  {
    //Header: "Billing Postal Code",
    Header: "客户地址邮编",
    accessor: "CustomerBillingPostalCode"
  }
]

const Meter_column = [
  {
    Header: "水表编号",
    accessor: "did"
  },
  {
    Header: "客户编号",
    accessor: "CustomerBillingID"
  },
  {
    Header: "用户地址",
    accessor: "MeterLocation"
  },
  {
    Header: "客户名称",
    accessor: "CustomerBillingName"
  },
  {
    Header: "信号强度",
    accessor: "CQS"
  },
  {
    Header: "电压",
    accessor: "batt"
  },
  {
    Header: "水表口径",
    accessor: "MeterBodyDiameterString"
  },
  {
    Header: "起始时间",
    accessor: "start"
  },
  {
    Header: "消耗流量",
    accessor: "bill"
  }
]

const templates = [
  {
    key: 1,
    name: "新洲水务"
  },
  {
    key: 2,
    name: "指尖水务"
  },
  {
    key: 3,
    name: "吉安水务"
  },
  {
    key: 4,
    name: "周口银龙"
  },
  {
    key: 5,
    name: "增城导出"
  },
  {
    key: 6,
    name: "彭泽银龙"
  },
  {
    key: 7,
    name: "新余水务"
  },
  {
    key: 8,
    name: "日月报告"
  }


]

const month = [
  {
    key: 1,
    name: 1
  },
  {
    key: 2,
    name: 2
  },
  {
    key: 3,
    name: 3
  },
  {
    key: 4,
    name: 4
  },
  {
    key: 5,
    name: 5
  },
  {
    key: 6,
    name: 6
  },
  {
    key: 7,
    name: 7
  },
  {
    key: 8,
    name: 8
  },
  {
    key: 9,
    name: 9
  },
  {
    key: 10,
    name: 10
  },
  {
    key: 11,
    name: 11
  },
  {
    key: 12,
    name: 12
  }
]

const year = [
  {
    key: 1,
    name: new Date().getFullYear()
  },
  {
    key: 1,
    name: new Date().getFullYear() - 1
  },
  {
    key: 1,
    name: new Date().getFullYear() - 2
  },
  {
    key: 1,
    name: new Date().getFullYear() - 3
  },
  {
    key: 1,
    name: new Date().getFullYear() - 4
  }
]

const queryClient = new QueryClient();

class WaterBill_day_Month extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      billList: [],
      groups: [],
      loadingGroups: false,
      startDate: "",
      endDate: "",
      isLoggedIn: false,
      isSuccess: false,
      selectedGroup: "ALL",
      selectedNodeName: "",
      selectedTemplate: "日月报告",
      dataToDownload: [],
      loading: false,
      visible: false,
      feedback: "",
      alertType: "info",
      selectedTime: "day",
      selectedMonth: "1",
      selectedYear: new Date().getFullYear(),
    };
    this.onChangeTemplate = this.onChangeTemplate.bind(this);
    this.onChangeMonth = this.onChangeMonth.bind(this);
    this.onChangeYear = this.onChangeYear.bind(this);
  }

  handleCallback = (childData, childKey) => {
    if (!this.state.loading) {
      console.log("Parent received:", childData);
      this.setState({
        selectedGroup: childData,
        selectedNodeName: childKey
      },
        this.handleSelect);
      this.setState({ isSuccess: false});
      this.setState({ billList: [] });
      this.setState({ feedback: "" });
      this.setState(
        {
          visible: false
        }
      );
    }
  }
  // handleStartDate = (startDate) => {
  //   console.log(startDate)
  //   startDate.setHours(0, 0, 0, 0);
  //   console.log("Start Date:", startDate);
  //   this.setState({ startDate: startDate });
  //   this.setState({ billList: [] });
  //   this.setState({ feedback: "" });
  //   this.setState(
  //     {
  //       visible: false
  //     }
  //   );
  // }

  // handleEndDate = (endDate) => {
  //   endDate.setHours(0, 0, 0, 0);
  //   console.log("End Date:", endDate);
  //   this.setState({ endDate: endDate });
  //   this.setState({ billList: [] });
  //   this.setState({ feedback: "" });
  //   this.setState(
  //     {
  //       visible: false
  //     }
  //   );
  // }

  handleSelect = () => {
    console.log("Updated group:", this.state.selectedGroup);
    console.log("Updated node name:", this.state.selectedNodeName)
  }

  onDismiss = () => {
    this.setState({ visible: false });
  }

  onChangeTemplate(event) {
    console.log("Switching to template:", event.target.value);
    this.setState({ selectedTemplate: event.target.value });
  }

  handleSelectTime(event) {
    console.log("time selete:", event.target.value);
    this.setState({ selectedTime: event.target.value });
    if (event.target.value === "day") {
      this.handleDay(new Date())
    }
    else if (event.target.value === "month") {
      const startDate = new Date()
      const endDate = new Date()
      startDate.setFullYear(this.state.selectedYear)
      startDate.setMonth(0)
      startDate.setDate(1)
      startDate.setHours(0, 0, 0, 0);
      endDate.setFullYear(this.state.selectedYear)
      endDate.setMonth(1)
      endDate.setDate(1)
      endDate.setHours(0, 0, 0, 0);
      console.log("Start Date", startDate);
      console.log("End Date:", endDate);
      this.setState({ startDate: startDate });
      this.setState({ endDate: endDate });
      this.setState({ isSuccess: false});
      this.setState({ settime: 1 })
      this.setState({ billList: [] });
      this.setState({ feedback: "" });
      this.setState(
        {
          visible: false
        }
      );
    }
  }

  onChangeMonth(month) {
    console.log("change month to ", month.target.value);
    this.setState({ selectedMonth: month.target.value })
    var startDate = new Date()
    var endDate = new Date()
    startDate.setFullYear(this.state.selectedYear)
    startDate.setMonth(month.target.value - 1)
    startDate.setDate(1)
    startDate.setHours(0, 0, 0, 0);
    endDate.setFullYear(this.state.selectedYear)
    endDate.setMonth(month.target.value)
    endDate.setDate(1)
    endDate.setHours(0, 0, 0, 0);
    console.log("Start Date", startDate);
    console.log("End Date:", endDate);
    this.setState({ startDate: startDate });
    this.setState({ endDate: endDate });
    this.setState({ isSuccess: false});
    this.setState({ billList: [] });
    this.setState({ feedback: "" });
    this.setState(
      {
        visible: false
      }
    );
  }

  onChangeYear(year) {
    console.log("change year to ", year.target.value);
    this.setState({ selectedYear: year.target.value })
    var startDate = new Date()
    var endDate = new Date()
    startDate.setFullYear(year.target.value)
    startDate.setMonth(this.state.selectedMonth - 1)
    startDate.setDate(1)
    startDate.setHours(0, 0, 0, 0);
    endDate.setFullYear(year.target.value)
    endDate.setMonth(this.state.selectedMonth)
    endDate.setDate(1)
    endDate.setHours(0, 0, 0, 0);
    console.log("Start Date", startDate);
    console.log("End Date:", endDate);
    this.setState({ startDate: startDate });
    this.setState({ endDate: endDate });
    this.setState({ isSuccess: false});
    this.setState({ billList: [] });
    this.setState({ feedback: "" });
    this.setState(
      {
        visible: false
      }
    );
  }
  // handleMonth(month) {
  //   const startDate = new Date()
  //   const endDate = new Date()
  //   startDate.setMonth(month.target.value - 1)
  //   startDate.setDate(1)
  //   startDate.setHours(0, 0, 0, 0);
  //   endDate.setMonth(month.target.value)
  //   endDate.setDate(1)
  //   endDate.setHours(0, 0, 0, 0);
  //   console.log("End Date:", endDate);
  //   console.log("Start Date", startDate);
  //   this.setState({ startDate: startDate });
  //   this.setState({ endDate: endDate });
  //   this.setState({ settime: month.target.value })
  //   this.setState({ billList: [] });
  //   this.setState({ feedback: "" });
  //   this.setState(
  //     {
  //       visible: false
  //     }
  //   );
  // }

  handleDay(date) {
    console.log(date)
    date.setHours(23, 59, 59, 0);
    const startDate = new Date(date)
    const endDate = new Date(date)
    endDate.setDate(endDate.getDate() + 1)
    console.log("Start Date:", startDate);
    console.log("End Date:", endDate);
    this.setState({ startDate: startDate });
    this.setState({ endDate: endDate });
    this.setState({ isSuccess: false});
    this.setState({ billList: [] });
    this.setState({ feedback: "" });
    this.setState(
      {
        visible: false
      }
    );
  }

  handleAxiosError = (e) => {
    this.setState({ alertType: "danger" });
    this.setState(
      {
        feedback: `Axios user request failed: ${e}`
      }
    );
    this.setState(
      {
        visible: true
      }
    );
  }

  generateGroups = () => {

    const token = localStorage.getItem("token");
    const api_host = process.env.REACT_APP_API_URL || myConstClass.REACT_APP_API_URL;

    var uri = `${api_host}/hierarchy/entities/navigate`;
    var encodedURI = encodeURI(uri);
    console.log("encoded URI:", encodedURI)
    console.log("decoded URI:", decodeURI(encodedURI))

    this.setState({ loadingGroups: true });
    this.setState({ isSuccess: false});

    myAxiosInstance.get(encodedURI, {
      headers: {
        'Authorization': `ApiKey ${token}`
      }
    })
      .then(res => {
        const groupList = res.data;
        console.log('This is your data', groupList);
        if (groupList.length > 0) {
          this.setState({ groups: groupList });
          console.log("length of groupMenu:", groupList.length);
          console.log("groupMenu:", groupList);
        } else {
          console.log("Empty menu!");
        }
        this.setState({ loadingGroups: false });
      })
      .catch(error => {
        const errorObject = JSON.parse(JSON.stringify(error));
        console.log(errorObject.message);
        this.setState({
          islogout: true
        }, () => {
          this.handleAxiosError(error);
        });
        this.setState({ loadingGroups: false });
      })

  }

  async componentDidMount() {

    this.generateGroups();

  }

  generateDownload = (billData) => {

    console.log("Generating data for download....");
    var data_to_download = [];

    for (var index = 0; index < billData.length; index++) {
      let record_to_download = {};
      for (var colIndex = 0; colIndex < extractHeader.length; colIndex++) {
        record_to_download[extractHeader[colIndex].Header] = billData[index][extractHeader[colIndex].accessor];
      }
      data_to_download.push(record_to_download);
    }

    console.log("Data to download:");
    console.log(data_to_download);
    this.setState({ dataToDownload: data_to_download })

  }

  saveFile = async (blob, filename) => {
    const a = document.createElement('a');
    a.download = filename;
    a.href = URL.createObjectURL(blob);
    a.addEventListener('click', (e) => {
      setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
    });
    a.click();
  }

  getWaterBill = (event) => {

    console.log('selectedGroup:', this.state.selectedGroup);
    console.log('start date:', this.state.startDate);
    console.log('end date:', this.state.endDate);
    console.log('template:', this.state.selectedTemplate);

    if (this.state.selectedGroup === "ALL") {
      this.setState({
        alertType: "danger"
      });
      this.setState({ feedback: "必须选择群组" });
      this.setState({
        visible: true
      });
      return;
    }

    if ((this.state.startDate === "") || (this.state.endDate === "")) {
      this.setState({
        alertType: "danger"
      });
      this.setState({ feedback: "必须输入开始和终止日期" });
      this.setState({
        visible: true
      });
      return;
    }

    //convert dates to epoch
    var startDate = new Date(this.state.startDate.toString());
    var endDate = new Date(this.state.endDate.toString());
    var startEpoch = startDate.getTime();
    var endEpoch = endDate.getTime();

    console.log("start epoch:", startEpoch);
    console.log("end epoch:", endEpoch);

    if (startEpoch > endEpoch) {
      this.setState({
        alertType: "danger"
      });
      this.setState({ feedback: "终止日期不能少于开始日期" });
      this.setState({
        visible: true
      });
      return;
    }

    if (this.state.selectedTemplate === "") {
      this.setState({
        alertType: "danger"
      });
      this.setState({ feedback: "必须选择模板" });
      this.setState({
        visible: true
      });
      return;
    }

    const token = localStorage.getItem("token");
    const api_host = process.env.REACT_APP_API_URL || myConstClass.REACT_APP_API_URL;

    this.setState(
      {
        visible: false
      }
    );
    this.setState({ isSuccess: true});
    this.setState({ loading: true });

    const uri = `${api_host}/hierarchy/bill?path=${this.state.selectedGroup}&start=${startEpoch}&end=${endEpoch}&template=日月报告`;
    const encodedURI = encodeURI(uri);
    console.log("encoded URI:", encodedURI)
    console.log("decoded URI:", decodeURI(encodedURI))

    myAxiosInstance.get(encodedURI, {
      headers: {
        'Authorization': `ApiKey ${token}`
      },
      responseType: "blob"
    })
      .then(res => {
        console.log('excel file size', res.data.size)
        if (res.data instanceof Blob) {
          const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
          var fileName = this.state.selectedGroup + "_" + moment(startDate).format('YYYYMMDD') + "_" + moment(endDate).format('YYYYMMDD') + "_" + this.state.selectedTemplate + ".xlsx"
          var x, i, y
          for (i = 0, x = 0; x !== -1 && i < 3; i++) {
            y = x
            x = fileName.indexOf('/', y + 1)
            //console.log("i", i, "x", x)
          }
          console.log("substr ", y)
          fileName = fileName.substr(y + 1)
          console.log("writing to excel file ", fileName)
          this.saveFile(blob, fileName)
          this.setState({
            loading: false
          });
          this.setState({
            alertType: "success"
          });
          this.setState({ feedback: "水費單下載成功" });
          this.setState({
            visible: true
          });
        }
      })
      .catch(e => {
        console.log(`Axios user request failed: ${e}`);
        this.handleAxiosError(e);
        this.setState({
          loading: false
        });
      });

    event.preventDefault();

  }

  render() {
    let timeInput
    if (this.state.selectedTime === "day") {
      timeInput = [
        <p> 选择日期</p>,
        <DayPickerInput
          onDayChange={Date => this.handleDay(Date)}
          inputProps={{ className: 'daypicker-control' }}
          dayPickerProps={{
            locale: 'zh-cn',
            localeUtils: MomentLocaleUtils,
          }}
        />
      ]
    } else if (this.state.selectedTime === "month") {
      timeInput = [
        <div>
          <p> 选择月份</p>
          <select
            value={this.state.selectedMonth}
            onChange={this.onChangeMonth}
          >
            {month.map((month, index) => {
              return <option value={month.name}>{month.name}</option>;
            })}
          </select>
          月
          <select
            value={this.state.selectedYear}
            onChange={this.onChangeYear}
          >
            {year.map((year, index) => {
              return <option value={year.name}>{year.name}</option>;
            })}
          </select>
          年
        </div>
      ]
    }

    return (
      <Fragment>
        <h2>日报表/月报表</h2>
        <div className="App">
          <div className="demo-container">
            <div className="column-left">
              <GroupMenuNew
                groups={this.state.groups}
                loading={this.state.loadingGroups}
                parentCallback={this.handleCallback}
                initialGroup={
                  this.state.selectedGroup
                }
              />
            </div>
            <div className="column-right">
              <Alert color={this.state.alertType} isOpen={this.state.visible} toggle={() => this.onDismiss()}>
                {this.state.feedback}
              </Alert>
              <Container className="themed-container" fluid={true}>
                <Row>
                  {(this.state.groups.length > 0) && (
                    <Col>
                      <>
                        <p>群组</p>
                        <div className="daypicker-control">
                          {
                            this.state.selectedNodeName !== "" ?
                              <>
                                {this.state.selectedNodeName}
                              </>
                              :
                              `请选择群组`
                          }
                        </div>
                      </>
                    </Col>
                  )}
                  <Col>
                    <p> 选择报表(日/月)</p>
                    <div>
                      <select
                        value={this.state.selectedTime}
                        onChange={Time => this.handleSelectTime(Time)}
                      >
                        <option value="day">日报表</option>
                        <option value="month">月报表</option>
                      </select>
                    </div>
                  </Col>
                  <Col>
                    {timeInput}
                  </Col>
                  {/* <Col> */}
                    {/* <p>模板</p>
                    <div> */}
                      {/* <select
                        value={this.state.selectedTemplate}
                        onChange={this.onChangeTemplate}
                      >
                        <option value="">
                          点击选择模板
                        </option>
                        {templates.map((template, index) => {
                          return <option value={template.name}>{template.name}</option>;
                        })}
                      </select> */}
                    {/* </div>
                  </Col> */}
                  <Col>
                    <button
                      className="btn btn-primary"
                      style={{
                        margin: '15px',
                        backgroundColor: '#8DB6FA'
                      }}
                      onClick={(event) => this.getWaterBill(event)}>
                      生成日报表/月报表
                    </button>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {this.state.loading && (
                      <>
                        <p>. . . 进行中 . . .</p>
                        <div
                          style={{
                            width: "100%",
                            height: "100",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                          }}
                        >
                          <Loader type="ThreeDots" color="#2BAD60" height="100" width="100" />
                        </div>
                      </>
                    )}
                  </Col>
                </Row>
                { this.state.startDate && this.state.endDate && this.state.selectedGroup && this.state.isSuccess &&
                  <QueryClientProvider client={queryClient}>
                    <WaterBillDayMonthTable
                      columns={Meter_column}
                      startDate={this.state.startDate}
                      endDate={this.state.endDate}
                      path={this.state.selectedGroup}
                    />
                  </QueryClientProvider>
                }
              </Container>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }

}

export default withRouter(WaterBill_day_Month);
