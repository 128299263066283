import React, { Fragment, useState, useEffect } from "react";
import UserTable from './UserComponent/UserTable';
import AddUserForm from './UserComponent/AddUserForm';
import EditUserForm from './UserComponent/EditUserForm';
import { Container, Row, Col, Alert } from 'reactstrap';
import "./styles.css";
import "./TableComponent/Table.css";
import useAsyncRequest from './UserComponent/useAsyncRequest';
import myAxiosInstance from './utils/axios-intercept';
import * as myConstClass from './utils/fileWithConstants';

const IndexUser = () => {

    const [data, loading] = useAsyncRequest(3);
    const [users, setUsers] = useState(null);
    const [editing, setEditing] = useState(false);
    const [currentUser, setCurrentUser] = useState('');
    const [visible, setVisible] = useState(false);
    const [feedback, setFeedback] = useState("");
    const [alertType, setAlertType] = useState("info");

    const token = localStorage.getItem("token");
    const api_host = process.env.REACT_APP_API_URL || myConstClass.REACT_APP_API_URL;

    useEffect(() => {
        if (data) {
          setUsers(data);
        }
    }, [data]);

    const onDismiss = () => setVisible(false);

    const addUser = (user) => {

        const new_username = user.username.trim();
        const userinfo = {
            "display_name" : user.displayname.trim(),
            "password": user.password.trim()
        }

        //API call to add the user
        myAxiosInstance.post(`${api_host}/user/`+new_username, userinfo, {
          headers: {
            'Authorization': `ApiKey ${token}`
          }
        })
        .then ((res) => {
            console.log("res:", res.data)
            console.log("status:", res.status)
            if (res.data.created) {
                //new user was added successfully
                var userdict = {};
                userdict['username'] = new_username;
                userdict['full_name'] = user.displayname.trim();
                //setUsers([...users, user.username]);
                setUsers([...users, userdict]);
                setFeedback(`用户 ${new_username} 已成功添加.`);
                setAlertType("success");
                setVisible(true);
                //alert(`User ${new_username} was added successfully.`);
            } else {
                setFeedback(`用户 ${new_username} 添加不成功 (原因: 用户名可能重复).`);
                setAlertType("danger");
                setVisible(true);
                //alert(`User ${new_username} could not be added (reason: possible duplicate).`);
            }
        })
        .catch ((e) => {
            const errorObject=JSON.parse(JSON.stringify(e))
            console.log(errorObject)
            setFeedback(`用户 ${new_username} 添加不成功 (原因: ${errorObject}).`);
            setAlertType("danger");
            setVisible(true);
            //alert(`User could not be added (reason: ${errorObject}).`)
        })

    }

    const updateEditState = (user, editState) => {
        if (editState) {
            setEditing(true);
            setCurrentUser(user);    
        } else {
            setEditing(false);
            setCurrentUser('');                
        }
 
    }

    const editUser = (user, newPassword) => {

        const editUsername = user.trim();
        const userinfo = {
            "password": newPassword.trim()
        }

        //API call to update the user information
        myAxiosInstance.post(`${api_host}/user/`+editUsername, userinfo, {
          headers: {
            'Authorization': `ApiKey ${token}`
          }
        })
        .then ((res) => {
            console.log("res:", res.data);
            console.log("status:", res.status);
            setFeedback(`用户 ${editUsername} 密码已成功修改`);
            setAlertType("success");
            setVisible(true);            
        })
        .catch ((e) => {
            const errorObject=JSON.parse(JSON.stringify(e));
            console.log(errorObject);
            setFeedback(`用户 ${editUsername} 密码修改不成功 (原因: ${errorObject}).`);
            setAlertType("danger");
            setVisible(true); 
        })
    }
    const deleteUser = (username) => {

        //API call to delete the user
        myAxiosInstance.delete(`${api_host}/user/`+username, {
            headers: {
                'Authorization': `ApiKey ${token}`
            }
        })
        .then ((res) => {
            console.log("res:", res.data)
            console.log("status:", res.status)
            setFeedback(`用户 ${username} 已成功刪除`);
            setAlertType("success");
            setVisible(true);

            var updatedUsers = [];

            for (var index = 0; index < users.length; index++) {
                if (users[index].username !== username) {
                    updatedUsers.push(users[index]);
                }                 
            }

            console.log('updated users:', updatedUsers);            
            setUsers(updatedUsers);
            
        })
        .catch ((e) => {
            const errorObject=JSON.parse(JSON.stringify(e))
            console.log(errorObject)
            setFeedback(`刪除用户失败. 原因: ${errorObject}`);
            setAlertType("danger");
            setVisible(true); 
        })

    }

    return (
      <Fragment>

        <div className="App">
            <Alert color={alertType} isOpen={visible} toggle={onDismiss}>
                {feedback}
            </Alert>
            <Container className="themed-container" fluid={true}>
            {localStorage.getItem('userrole').includes('admin') ? 
                (<Row>
                <Col>
                    {loading ? (
                        <p>载入中. . . . .</p>
                    ) : (
                        !users ? (
                            <p>無法訪問用戶. . . . .</p>
                        ) : (
                            <div className="flex-child">
                                <h2>用户一览</h2>
                                <UserTable users={users} setEditing={updateEditState} deleteUser={deleteUser}/>
                            </div>
                        )
                    )
                    }
                </Col>
                <Col>
                {editing ? 
                (<>
                    <h2>修改密码</h2>
                    <EditUserForm
                        users={users}
                        setEditing={updateEditState} 
                        currentUser={currentUser}
                        editUser={editUser} 
                    />
                    </>
                ) : (
                <>
                    <h2>新增用户</h2>
                    <AddUserForm users={users} addUser={addUser} 
                    />
                </>
                )}
                  </Col>
                </Row>) 
                : (<Row>
                <Col>
                    <span className="text-danger">仅管理员用户可以管理用户</span>
                </Col>
                </Row>
                )}
            </Container>
        </div>

      </Fragment>
    )

}

export default IndexUser;
