import axios from "axios";
import history from "./history.jsx";

const CancelToken = axios.CancelToken
const myAxiosInstance = axios.create();
// try to set timeout but seems have no effect
//myAxiosInstance.defaults.timeout = 500000; 
const requestPool = /** @type { {[url: string]: import('axios').Canceler } } */ ({})

// Add a request interceptor
myAxiosInstance.interceptors.request.use(
   config => {
       return config;
   },
   error => {
       Promise.reject(error)
   }
);


//Add a response interceptor

myAxiosInstance.interceptors.response.use((response) => {
   return response
}, function (error) {

    if (error.response && error.response.status === 401) {
       
        localStorage.clear();

        history.push({
            pathname: "/login",
            state: {
                infoMsg: "您当前的会话已结束。请重新登录."
            }
        });
        return Promise.reject(error);

    }

    return Promise.reject(error);

});

export default myAxiosInstance;