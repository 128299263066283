import {useState, useEffect} from 'react';
import axios from 'axios';
import * as myConstClass from '../../utils/fileWithConstants';

const useAsyncRequest = (amount) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [status, setStatus] = useState("500");

    useEffect(() => {
        const fetchData = async () => {

            try {
                setLoading(true);
                const user_token = localStorage.getItem("token");
                const api_host = process.env.REACT_APP_API_URL || myConstClass.REACT_APP_API_URL;
                const uri = `${api_host}/hierarchy/entities`;

                const encodedURI = encodeURI(uri);
                console.log("encoded URI:", encodedURI)
                console.log("decoded URI:", decodeURI(encodedURI))
                                
                const response = await axios.get(
                    encodedURI, {
                        headers: {
                            'Authorization': `ApiKey ${user_token}`
                        },

                    }
                )
                .then ((res) => {
                    console.log("response from get:", res)
                    setData(res.data);
                    setStatus("200");
                    let timer1 = setTimeout (
                        () => setLoading(false), 1000
                    );

                    // this will clear Timeout
                    // when component unmount like in willComponentUnmount
                    // and show will not change to true
                    return () => {
                      clearTimeout(timer1);
                    };

                }
                )
                .catch ((e) => {
                    console.log("error when loading tree:", e);
                    // assume that the error is due to 401 unauthorized
                    if (String(e).includes("401")) {
                        setStatus("401");
                    }
                    setData(null, setLoading(false));
                }
                );
            } catch (err) {
                console.log("Something went wrong fetching the data...", err);
                setLoading(false);
                setStatus("500");
            }
        }

        if (amount) {
            fetchData(amount);
        }

    },[amount]);

    console.log("return:", data, ' ', status, ' ', loading);
    return [data, status, loading];
}

export default useAsyncRequest;