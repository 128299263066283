import React, { useEffect, useState } from 'react';
import { useTable, usePagination } from 'react-table';
import * as myConstClass from '../utils/fileWithConstants';
import classNames from "classnames";
import loaderimage from "./assets/loader-table.gif";
import "../TableComponent/TableDevice.css";
import myAxiosInstance from '../utils/axios-intercept';
import {
    Table,
    Pagination,
    PaginationItem,
    PaginationLink,
    Button
} from "reactstrap";
import { ExportToCsv } from "export-to-csv";

const initialState = {
    queryPageIndex: 0,
    queryPageSize: myConstClass.PAGESIZE,
    totalCount: null,
};

const PAGE_CHANGED = 'PAGE_CHANGED';
const PAGE_SIZE_CHANGED = 'PAGE_SIZE_CHANGED';
const TOTAL_COUNT_CHANGED = 'TOTAL_COUNT_CHANGED';

const reducer = (state, { type, payload }) => {
    switch (type) {
        case PAGE_CHANGED:
            return {
                ...state,
                queryPageIndex: payload,
            };
        case PAGE_SIZE_CHANGED:
            return {
                ...state,
                queryPageSize: payload,
            };
        case TOTAL_COUNT_CHANGED:
            return {
                ...state,
                totalCount: payload,
            };
        default:
            throw new Error(`Unhandled action type: ${type}`);
    }
};

const ModifyGroupTable = ({ columns, errorID, errorMessage }) => {

    const [{ queryPageIndex, queryPageSize }, dispatch] =
        React.useReducer(reducer, initialState);

    const data = errorID
    const count = errorID.length

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        rows,
        canPreviousPage,
        canNextPage,
        // pageOptions,
        // pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        // Get the state from the instance
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            //data: isSuccess ? trimData(data.results) : [],
            data: data,
            initialState: {
                pageIndex: queryPageIndex,
                pageSize: queryPageSize,
            },
            manualPagination: true, // Tell the usePagination
            // hook that we'll handle our own data fetching
            // This means we'll also have to provide our own
            // pageCount.
        },
        usePagination
    );

    useEffect(() => {
        dispatch({ type: PAGE_CHANGED, payload: pageIndex });
    }, [pageIndex]);

    useEffect(() => {
        dispatch({ type: PAGE_SIZE_CHANGED, payload: pageSize });
    }, [pageSize]);

    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        filename: '导入错误_'+errorMessage,
        useKeysAsHeaders: true,
      };

    const csvExporter = new ExportToCsv(csvOptions);


    const handleExportRows = (rows) => {
        csvExporter.generateCsv(rows.map((row) => {
            var csv_dict = {}
            if ("deviceId" in row.values){
                csv_dict["模块ID"] = "\t"+row.values["deviceId"]
            }
            if ("paths" in row.values){
                csv_dict["群组"] = row.values["paths"]
            }
            if ("CustomerBIllingId" in row.values){
                csv_dict["客户编号"] = row.values["CustomerBIllingId"]
            }
            console.log(row)
            console.log(csv_dict)
            return csv_dict
        }));
    };

    return (
        <> 
            <h4>{errorMessage}</h4>
            <h4>总数: {count}</h4>
            <Button
                color="primary"
                size="sm"
                style={
                    {
                      "width": "30%"
                    }
                }
                onClick={() => handleExportRows(rows)}
            >
                错误文件导出
            </Button>
            <Table {...getTableProps()} hover bordered responsive>
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps()}>
                                    {column.render('Header')}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                {page.length === 0 ?
                    <tbody>
                        <tr>
                            <td colSpan="10000" className="text-left">
                                <p style={{
                                    "textAlign": "left"
                                }}
                                >
                                    没有数据显示
                                </p>
                            </td>
                        </tr>
                    </tbody>
                    :
                    <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => (
                                        <td {...cell.getCellProps({
                                            className: cell.column.className
                                        }
                                        )}>
                                            {cell.render('Cell')}
                                        </td>
                                    ))}
                                </tr>
                            );
                        })}
                    </tbody>
                }
            </Table>
        </>
    );
}

export default ModifyGroupTable;