import { Fragment, useState, useEffect } from "react";
import { withRouter } from "react-router";
import { useParams, useHistory } from "react-router-dom";
import { Container, Row, Col, Alert } from "reactstrap";
import ZTable from "./TableComponent/TableReading";
import './styles.css';
import DeviceChart from "./Charts/DeviceChart";
import myAxiosInstance from "./utils/axios-intercept";
import * as myConstClass from './utils/fileWithConstants';

//new comment
const listHeaderDebug = [
  {
    Header: "数据包序列号",
    className: "t-cell-1 text-right",
    accessor: a => a._source.hasOwnProperty("data") ? a._source.data.seqNum : a._source.seqNum
  },
  {
    Header: "成功上报计数",
    className: "t-cell-1 text-right",
    accessor: a => a._source.hasOwnProperty("data") ? a._source.data.okCount : a._source.okCount
  },
  {
    Header: "用水量 m³",
    className: "t-cell-4a text-right",
    accessor: a => a._source.hasOwnProperty("data") ? a._source.data.totalVol : a._source.totalVol,
    Cell: ({ cell: { value } }) => {
      const showVol = value/10000
      const displayVol = showVol.toFixed(2)
      return (
        `${displayVol}`
      )
    }    
  },
  {
    Header: "正转计数 m³",
    className: "t-cell-4a text-right",
    accessor: a => a._source.hasOwnProperty("data") ? a._source.data.fwdVol : a._source.fwdVol,
    Cell: ({ cell: { value } }) => {
      const showVol = value/10000
      const displayVol = showVol.toFixed(2)
      return (
        `${displayVol}`
      )
    }
  },
  {
    Header: "反转计数 m³",
    className: "t-cell-4a text-right",
    accessor: a => a._source.hasOwnProperty("data") ? a._source.data.revVol : a._source.revVol,
    Cell: ({ cell: { value } }) => {
      const showVol = value/10000
      const displayVol = showVol.toFixed(2)
      return (
        `${displayVol}`
      )
    }    
  },
  {
    Header: "连续用水时间",
    accessor: a => a._source.hasOwnProperty("data") ? a._source.data.usageCt : a._source.usageCt,
    className: "t-cell-4a text-right",
    disableFilters: true
  },
  {
    Header: "外部干扰累计时间",
    accessor: a => a._source.hasOwnProperty("data") ? a._source.data.tamperCt : a._source.tamperCt,
    className: "t-cell-1 text-right",
    disableFilters: true
  },
  {
    Header: "移除累计时间",
    accessor: a => a._source.hasOwnProperty("data") ? a._source.data.removeCt : a._source.removeCt,
//    accessor: "lastRecord.removeCt",    
    className: "t-cell-1 text-right",
    disableFilters: true
  },
  {
    Header: "爆管累计时间",
    accessor: a => a._source.hasOwnProperty("data") ? a._source.data.burstCt : a._source.burstCt,
//    accessor: "lastRecord.burstCt",     
    className: "t-cell-1 text-right",
    disableFilters: true
  },
  {
    Header: "温度",
    accessor: a => a._source.hasOwnProperty("data") ? a._source.data.temp : a._source.temp,
//    accessor: "lastRecord.temp",
    className: "t-cell-1 text-right",
    disableFilters: true
  },
  {
    Header: "上报前电池电压",
    accessor: a => a._source.hasOwnProperty("data") ? a._source.data.preBatt : a._source.preBatt,
//    accessor: "lastRecord.preBatt",
    className: "t-cell-1 text-right",
    disableFilters: true
  },
  {
    Header: "上报后电池电压",
    accessor: a => a._source.hasOwnProperty("data") ? a._source.data.postBatt : a._source.postBatt,
//    accessor: "lastRecord.preBatt",
    className: "t-cell-1 text-right",
    disableFilters: true
  },
  {
    Header: "上一次上报结束",
    accessor: a => a._source.hasOwnProperty("data") ? a._source.data.lastIndex : a._source.lastIndex,
//    accessor: "lastRecord.lastIndex",    
    className: "t-cell-1 text-right",
    disableFilters: true
  },
  {
    Header: "上一次上报所用时间",
    accessor: a => a._source.hasOwnProperty("data") ? a._source.data.lastUpload : a._source.lastUpload,
//    accessor: "lastRecord.lastUpload",
    className: "t-cell-1 text-right",
    disableFilters: true
  },
  {
    Header: "上一次上报后NB电源状态",
    accessor: a => a._source.hasOwnProperty("data") ? a._source.data.lastPwr : a._source.lastPwr,
//    accessor: "lastRecord.lastPwr",    
    className: "t-cell-1 text-right",
    disableFilters: true
  },
  {
    Header: "本次NB上报STEPS",
    accessor: a => a._source.hasOwnProperty("data") ? a._source.data.lastSteps : a._source.lastSteps,
//    accessor: "lastRecord.lastSteps",    
    className: "t-cell-1 text-right",
    disableFilters: true
  },
  {
    Header: "信号强度",
    accessor: a => a._source.hasOwnProperty("data") ? a._source.data.CQS : a._source.CQS,
//    accessor: "lastRecord.CQS",    
    className: "t-cell-1 text-right",
    disableFilters: true
  },
  {
    Header: "信号功率",
    accessor: a => a._source.hasOwnProperty("data") ? a._source.data.sigPwr : a._source.sigPwr,
//    accessor: "lastRecord.sigPwr",    
    className: "t-cell-1 text-right",
    disableFilters: true
  },
  {
    Header: "总功率",
    accessor: a => a._source.hasOwnProperty("data") ? a._source.data.totalPwr : a._source.totalPwr,
//    accessor: "lastRecord.totalPwr",
    className: "t-cell-1 text-right",
    disableFilters: true
  },    
  {
    Header: "终端发射功率",
    accessor: a => a._source.hasOwnProperty("data") ? a._source.data.txPwr : a._source.txPwr,
//    accessor: "lastRecord.txPwr",
    className: "t-cell-1 text-right",
    disableFilters: true
  },
  {
    Header: "覆盖增强等级",
    accessor: a => a._source.hasOwnProperty("data") ? a._source.data.cellID : a._source.cellID,
//    accessor: "lastRecord.cellID",    
    className: "t-cell-1 text-right",
    disableFilters: true
  },
  {
    Header: "信噪比",
    accessor: a => a._source.hasOwnProperty("data") ? a._source.data.SNR : a._source.SNR,
 //   accessor: "lastRecord.SNR",       
    className: "t-cell-1 text-right",
    disableFilters: true
  },
  {
    Header: "中心频点",
    accessor: a => a._source.hasOwnProperty("data") ? a._source.data.EARFCN : a._source.EARFCN,
//    accessor: "lastRecord.EARFCN",    
    className: "t-cell-1 text-right",
    disableFilters: true
  },
  {
    Header: "物理小区标识",
    accessor: a => a._source.hasOwnProperty("data") ? a._source.data.PCI : a._source.PCI,
//    accessor: "lastRecord.PCI",    
    className: "t-cell-1 text-right",
    disableFilters: true
  },
  {
    Header: "参考信号接收质量",
    accessor: a => a._source.hasOwnProperty("data") ? a._source.data.RSRQ : a._source.RSRQ,
//    accessor: "lastRecord.RSRQ",    
    className: "t-cell-1 text-right",
    disableFilters: true
  },
  {
    Header: "参考信号接收功率",
    accessor: a => a._source.hasOwnProperty("data") ? a._source.data.RSRP : a._source.RSRP,
//    accessor: "lastRecord.RSRP",
    className: "t-cell-1 text-right",
    disableFilters: true
  },
  {
    Header: "线圈L1电感动态数值",
    accessor: a => a._source.hasOwnProperty("data") ? a._source.data.lc1Delta : a._source.lc1Delta,
//    accessor: "lastRecord.lc1Delta",
    className: "t-cell-1 text-right",
    disableFilters: true
  },
  {
    Header: "线圈L2电感动态数值率",
    accessor: a => a._source.hasOwnProperty("data") ? a._source.data.lc2Delta : a._source.lc2Delta,
//    accessor: "lastRecord.lc2Delta",    
    className: "t-cell-1 text-right",
    disableFilters: true
  },
  {
    Header: "线圈L3电感动态数值",
    accessor: a => a._source.hasOwnProperty("data") ? a._source.data.lc3Delta : a._source.lc3Delta,
//    accessor: "lastRecord.lc3Delta",
    className: "t-cell-1 text-right",
    disableFilters: true
  },
  {
    Header: "设备状态",
    className: "t-cell-1 text-right",
    accessor: a => a._source.hasOwnProperty("data") ? a._source.data.hdralarmStatus : a._source.hdralarmStatus,
    disableFilters: true
  },
  {
    Header: "最后更新",
    accessor: a => a._source.hasOwnProperty("data") ? a._source.data.timestampstring : a._source.timestampstring,
//    accessor: "lastRecord.timestampstring",
    // id: "timestamp",
    className: "t-cell-6 text-left",
    disableFilters: true
  }
];

const listHeaderNormal = [
  {
    Header: "设备状态",
    className: "t-cell-1 text-right",
    accessor: a => a._source.hasOwnProperty("data") ? a._source.data.hdralarmStatus : a._source.hdralarmStatus,
    Cell: props => 
    ((props.value === 0||props.value === 16) ? "正常":
    (props.value === 1||props.value === 17) ?"过流":
    (props.value === 2||props.value === 18) ? "移除":
    (props.value === 4||props.value === 20) ?"干扰":
    props.value === 8 ? "泄漏":
    props.value === 3 ? "过流+移除":
    props.value === 5 ? "过流+干扰":
    props.value === 6 ? "移除+干扰":
    props.value === 7 ? "过流+移除+干扰":
    props.value === 9 ? "过流+泄漏":
    props.value === 10 ? "移除+泄漏":
    props.value === 11 ? "过流+移除+泄漏":
    props.value === 12 ? "干扰+泄漏":
    props.value === 13 ? "过流+干扰+泄漏":
    props.value === 14 ? "移除+干扰+泄漏":
    props.value === 15 ? "过流+移除+干扰+泄漏":
    props.value > 20 ? "其他":
    props.value === null ? "NULL":
    props.value === undefined ? "NULL":
    props.value),
      
  },
  {
    Header: "用水量 m³",
    className: "t-cell-4a text-right",
    accessor: a => a._source.hasOwnProperty("data") ? a._source.data.totalVol : a._source.totalVol,
    Cell: ({ cell: { value } }) => {
      const showVol = value/10000
      const displayVol = showVol.toFixed(2)
      return (
        `${displayVol}`
      )
    }    
  },
  {
    Header: "正转计数 m³",
    className: "t-cell-4a text-right",
    accessor: a => a._source.hasOwnProperty("data") ? a._source.data.fwdVol : a._source.fwdVol,
    Cell: ({ cell: { value } }) => {
      const showVol = value/10000
      const displayVol = showVol.toFixed(2)
      return (
        `${displayVol}`
      )
    }
  },
  {
    Header: "反转计数 m³",
    className: "t-cell-4a text-right",
    accessor: a => a._source.hasOwnProperty("data") ? a._source.data.revVol : a._source.revVol,
    Cell: ({ cell: { value } }) => {
      const showVol = value/10000
      const displayVol = showVol.toFixed(2)
      return (
        `${displayVol}`
      )
    }    
  },
  {
    Header: "温度",
    className: "t-cell-1 text-right",
    accessor: a => a._source.hasOwnProperty("data") ? a._source.data.temp : a._source.temp
  },
  {
    Header: "上报前电池电压",
    className: "t-cell-1 text-right",
    accessor: a => a._source.hasOwnProperty("data") ? a._source.data.preBatt : a._source.preBatt,
    Cell: props => 
    (props.value <100? props.value:props.value/100)+"V"
        // const showVoltage = value/100
        // const displayVoltage = showVoltage.toFixed(2)  

      // return (
      //   `${displayVoltage}V`
      // )
  },
  {
    Header: "信号强度",
    className: "t-cell-1 text-right",
    accessor: a => a._source.hasOwnProperty("data") ? a._source.data.CQS : a._source.CQS
  },
  {
    Header: "覆盖增强等级",
    className: "t-cell-1 text-right",
    accessor: a => a._source.hasOwnProperty("data") ? a._source.data.cellID : a._source.cellID,
    disableFilters: true
  },
  {
    Header: "最后更新",
    className: "t-cell-6 text-left",
    // id: "timestamp",
    accessor: a => a._source.hasOwnProperty("data") ? a._source.data.timestampstring : a._source.timestampstring
  }
];

const AboutDevice = (props) => {

  const [result, setResult] = useState([]);
  const { deviceId } = useParams()
  const [battChart, setBattChart] = useState({});
  const [sigChart, setSigChart] = useState({});
  const [tempChart, setTempChart] = useState({});
  const [volChart, setVolChart] = useState({});
  const [visible, setVisible] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [alertType, setAlertType] = useState("info");

  const token = localStorage.getItem("token");
  const api_host = process.env.REACT_APP_API_URL || myConstClass.REACT_APP_API_URL;

  const history = useHistory();

  var battery_data = {
    labels: [],
    datasets: [
      {
        label: '电压',
        fill: true,
        lineTension: 0.1,
        backgroundColor: '#F2F3F4',
        //borderColor: 'rgba(75,192,192,1)',
        borderColor: 'rgba(52, 73, 94, 0.4)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        borderWidth: 1,
        //pointBorderColor: 'rgba(75,192,192,1)',
        pointStyle: 'circle',
        pointBorderColor: 'rgba(52, 73, 94, 1)',
        pointBackgroundColor: 'rgba(52, 73, 94, 1)',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(75,192,192,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 2,
        pointHitRadius: 10,
        data: []
      }
    ]
  }

  var signal_data = {
    labels: [],
    datasets: [
      {
        //label: 'Signal Strength',
        label: '信号强度',
        fill: true,
        lineTension: 0.1,
        backgroundColor: '#D1F2EB',
        //borderColor: 'rgba(175,192,192,1)',
        borderColor: 'rgba(68, 108, 179, 0.4)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        borderWidth: 1,
        pointStyle: 'star',
        pointBorderColor: 'rgba(68, 108, 179, 1)',
        pointBackgroundColor: 'rgba(68, 108, 179, 1)',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(175,192,192,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 2,
        pointHitRadius: 10,
        data: []
      }
    ]
  }

  var temp_data = {
    labels: [],
    datasets: [
      {
        label: '温度',
        fill: true,
        lineTension: 0.1,
        backgroundColor: '#D6EAF8',
        //borderColor: 'rgba(175,192,192,1)',
        borderColor: 'rgba(51, 110, 123, 0.4)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        borderWidth: 1,
        pointStyle: 'triangle',
        pointBorderColor: 'rgba(51, 110, 123, 0.5)',
        pointBackgroundColor: 'rgba(51, 110, 123, 0.5)',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(175,192,192,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 2,
        pointHitRadius: 10,
        data: []
      }
    ]
  }

  var volume_data = {
    labels: [],
    datasets: [
      {
        label: '水量',
        fill: true,
        lineTension: 0.1,
        backgroundColor: '#F4ECF7',
        //borderColor: 'rgba(175,192,192,1)',
        borderColor: 'rgba(77, 19, 209, 0.4)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        borderWidth: 1,
        pointStyle: 'triangle',
        pointBorderColor: 'rgba(77, 19, 209, 0.4)',
        pointBackgroundColor: 'rgba(77, 19, 209, 0.4)',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(175,192,192,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 2,
        pointHitRadius: 10,
        data: []
      }
    ]
  }

  var now = new Date();
  var start = new Date();
  start.setTime(now.getTime() - (720 * 24 * 60 * 60 * 1000));
  if (props.location.state.MeterInstallDateString_v2 !== undefined){
    var start_timestamp = new Date(props.location.state.MeterInstallDateString_v2).getTime()
    console.log("installtime: ",start_timestamp)
    if (isNaN(start_timestamp)){
      start_timestamp = start.getTime()
    }
  }
  else{
    var start_timestamp = start.getTime()
    console.log("time: ",props.location.state)
  }

  var end_timestamp = now.getTime();

  const battChartTitle = "电压 (V)";
  const sigChartTitle = "信号强度";
  const tempChartTitle = "温度";
  const volChartTitle = "水量";

  function getField(data, field) {
    var ret = []
    //console.log("getting field %s ...", field)
    for (var i = 0; i < data.length; i++) {
      ret[i] = data[i]["_source"][field]
    }
    
    return ret;
  }

  //const history = useHistory();

  const onDismiss = () => setVisible(false);

  useEffect(() => {
    console.log('about device initial props location', props.location);
    myAxiosInstance
      .get(
        `${api_host}/data/?q=${deviceId}&from=0&size=10000&start_timestamp=${start_timestamp}&end_timestamp=${end_timestamp}`,
        {
          headers: {
            'Authorization': `ApiKey ${token}`
          }
        }
      )
      .then(
        res => {
          console.log('This is the list of your device readings', res.data);
          setResult(res.data);

          //setting up the data and labels for the line chart
          var dates = getField(res.data, "timestampstring");
          //console.log(dates);
          var battery = getField(res.data, "preBatt");
          console.log(battery);

          //divide preBatt by 100
          for (var i = 0; i < battery.length; i++) {
            if(battery[i]>100)
            {
              battery[i] = battery[i]/100

            }
          }
          console.log("Battery data in line chart:", battery);

          var signals = getField(res.data, "CQS");
          //console.log(signals);
          var temps = getField(res.data, "temp");
          //console.log(temps);
          var water_volumes = getField(res.data, "totalVol");
          //console.log(water_volumes);

          //data for Line Chart #1
          battery_data.labels = dates;
          battery_data.datasets[0].data = battery;

          signal_data.labels = dates;
          signal_data.datasets[0].data = signals;

          temp_data.labels = dates;
          temp_data.datasets[0].data = temps;

          volume_data.labels = dates;
          var real_water = water_volumes.map((water_vol) => {
            return parseInt(water_vol / 10000, 10);
          });

          volume_data.datasets[0].data = real_water;

          setBattChart(battery_data);
          setSigChart(signal_data);
          setTempChart(temp_data);
          setVolChart(volume_data);

        }
      )
      .catch(error => {
        const errorObject = JSON.parse(JSON.stringify(error));
        console.log(errorObject);
        setFeedback(`Axios request failed (原因: ${errorObject}).`);
        setAlertType("danger");
        setVisible(true);        

      })
  },[]);

  const backToDashboard = () => {

    console.log("all props", props);
    console.log("props.history", props.history);
    console.log("history", history);

    var pathname = "";

    if (props.location.state.referredBy === "IndexDashboard") {
      pathname = "/dashboard";
      props.history.push(
        {
          pathname: pathname,
          state: {
            //hi: 'there',
            //from: "AboutDevice",
            pageIndex: props.location.state.currPage,
            selectedGroup: props.location.state.selectedGroup,
            selectedNodeName: props.location.state.selectedNodeName
          }
        }
      );
    } 
    else if (props.location.state.referredBy === "Alarm")
    {
      pathname = "/dashboard/Alarm";
      props.history.push(
        {
          pathname: pathname,
          state: {
            path: props.location.state.selectedGroup,
            onchoiceChange:props.location.state.onchoiceChange
          }
      })
    }
    else {
      pathname = "/dashboard/FactoryDashboard";
      props.history.push(
        {
          pathname: pathname,
          state: {
            //hi: 'there',
            //from: "AboutDevice",
            pageIndex: props.location.state.currPage,
            searchBatch: props.location.state.searchBatch,
            searchField: props.location.state.searchField,
            searchValue: props.location.state.searchValue,
            isSearchOn: props.location.state.isSearchOn,
          }
        }
      );
    }

  }

  console.log("page Index:", props.location.state.currPage);

  return (
    // props.match.params.name
    <Fragment>
      <h2>设备详情</h2>
      <div className="App">
        <Alert color={alertType} isOpen={visible} toggle={onDismiss}>
          {feedback}
        </Alert>        
        <Container className="themed-container" fluid={true}>
          <Row className="justify-content-md-center">
            <Col xs lg="2">
              &nbsp;
                </Col>
            <Col md="auto">
              <button 
                className="btn btn-secondary"
                onClick={backToDashboard} 
              >
                返回
                  </button>
            </Col>
            <Col xs lg="2">
              &nbsp;
                </Col>
          </Row>
          <Row>
            <Col>
              <div className="d-head1">水表序号 SN</div>
              <div>{props.location.state.deviceName}</div>
            </Col>
            <Col>
              <span className="d-head1">水表号码 ID</span>
              <div>{props.location.state.deviceNo}</div>
            </Col>
            <Col>
              <span className="d-head1">IMSI</span>
              <div>{props.location.state.deviceimsi}</div>
            </Col>
            <Col>
              <span className="d-head1">IMEI</span>
              <div>{deviceId}</div>
            </Col>
            <Col>
              <span className="d-head1">最后更新</span>
              <div>{props.location.state.timestamp}</div>
            </Col>
            <Col>
              <span className="d-head1">设备状态</span>
              <div>
              {((props.location.state.alarmStatus === 0||props.location.state.alarmStatus === 16) ? "正常":
                (props.location.state.alarmStatus === 1||props.location.state.alarmStatus === 17) ?"过流":
                (props.location.state.alarmStatus === 2||props.location.state.alarmStatus === 18) ? "移除":
                (props.location.state.alarmStatus === 4||props.location.state.alarmStatus === 20) ?"干扰":
                props.location.state.alarmStatus === 8 ? "泄漏":
                props.location.state.alarmStatus === 3 ? "过流+移除":
                props.location.state.alarmStatus === 5 ? "过流+干扰":
                props.location.state.alarmStatus === 6 ? "移除+干扰":
                props.location.state.alarmStatus === 7 ? "过流+移除+干扰":
                props.location.state.alarmStatus === 9 ? "过流+泄漏":
                props.location.state.alarmStatus === 10 ? "移除+泄漏":
                props.location.state.alarmStatus === 11 ? "过流+移除+泄漏":
                props.location.state.alarmStatus === 12 ? "干扰+泄漏":
                props.location.state.alarmStatus === 13 ? "过流+干扰+泄漏":
                props.location.state.alarmStatus === 14 ? "移除+干扰+泄漏":
                props.value === 15 ? "过流+移除+干扰+泄漏":
                props.location.state.alarmStatus > 20 ? "其他":
                props.location.state.alarmStatus === null ? "NULL":
                props.location.state.alarmStatus === undefined ? "NULL":
                props.location.state.alarmStatus)}
              
              </div>
            </Col>
          </Row>
          {(props.location.state.referredBy !== "FactoryDashboard") && 
            <>
              <Row>
                <Col><DeviceChart data={battChart} title={battChartTitle} /></Col>
                <Col><DeviceChart data={sigChart} title={sigChartTitle} /></Col>
              </Row>
              <Row>
                <Col><DeviceChart data={tempChart} title={tempChartTitle} /></Col>
                <Col><DeviceChart data={volChart} title={volChartTitle} /></Col>
              </Row>
            </>
          }
          {localStorage.getItem('userrole').includes('debug')
            ? (
              <ZTable 
              columns={listHeaderDebug}
              loading={false} 
              data={result} 
              deviceId={props.location.state.deviceNo}
              deviceName={props.location.state.deviceName}
              imei = {deviceId}
              />
            )
            : (
              <ZTable 
              columns={listHeaderNormal}
              loading={false} 
              data={result} 
              deviceId={props.location.state.deviceNo}
              deviceName={props.location.state.deviceName}
              imei = {deviceId}
              />
            )
          }
        </Container>
      </div>
    </Fragment>
  )
};

export default withRouter(AboutDevice);