import React from "react";
import { Bar } from "react-chartjs-2";

const BarChart = ({ data, title }) => {

    console.log("In the BarChart:", data);

    const options = {
        responsive: true,
        //height: '600px',
        //width: '600px',
        //maintainAspectRatio: true,
        title: {
            display: true,
            text: title,
            fontSize: 16
        }, 
        scales: {
            xAxes: [{
                type: 'time',
                displayFormats: {
                    day: 'MMM DD'
                },
                barPercentage: 0.6,
                gridLines:{
                    display:false
                    }
                },
            ]
            },
            yAxes: [{
                gridLines:{
                    display:false
                }
            }],
        legend: {
            display: false
        }
    }

    return <Bar data={data} options={options} />;
    
};

export default BarChart;