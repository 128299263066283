import React, { useEffect, useState} from 'react';
import { useTable, useSortBy, usePagination, useFilters } from 'react-table';
import myAxiosInstance from '../utils/axios-intercept';
import * as myConstClass from '../utils/fileWithConstants';
import classNames from "classnames";
import loaderimage from "./assets/loader-table.gif";
import "../TableComponent/TableDevice.css";
import { 
  Input,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  FormGroup,
  Button
} from "reactstrap";

const initialState = {
  queryPageIndex: 0,
  queryPageSize: myConstClass.PAGESIZE,
  totalCount: null,  
  querySortBy: null,
  queryFilters: null
};

const PAGE_CHANGED = 'PAGE_CHANGED';
const PAGE_SIZE_CHANGED = 'PAGE_SIZE_CHANGED';
const TOTAL_COUNT_CHANGED = 'TOTAL_COUNT_CHANGED';
const SORT_CHANGED = 'SORT_CHANGED';
const FILTER_CHANGED = 'FILTER_CHANGED';

const reducer = (state, { type, payload }) => {
  switch (type) {
    case PAGE_CHANGED:
      return {
        ...state,
        queryPageIndex: payload,
      };
    case PAGE_SIZE_CHANGED:
      return {
        ...state,
        queryPageSize: payload,
      };
    case TOTAL_COUNT_CHANGED:
      return {
        ...state,
        totalCount: payload,
      };
    case SORT_CHANGED:
      return {
        ...state,
        querySortBy: payload,
        //currentFilter: currentFilter
      };
    case FILTER_CHANGED:
      return {
        ...state,
        queryFilters: payload,
        //currentFilter: currentFilter
      }
    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
};

const MeterTableFactory = ({ columns, field, query, batch, lastTargetFilter, lastTargetFilterValue, callbackParent, aboutCallback,FilterCallback, outdatatime}) => {

  useEffect(() => {
    console.log("MeterTableFactory received:", field, query, batch);
  },[field, query, batch])

  const [{ queryPageIndex, queryPageSize, totalCount, querySortBy, queryFilters }, dispatch] =
    React.useReducer(reducer, initialState);

  // const { isLoading, error, data, isSuccess } = useQuery(
  //   ['meters', queryPageIndex, queryPageSize, field, query, batch],
  //   () => fetchFactoryMeterData(queryPageIndex, queryPageSize, field, query, batch, outdatatime),
  //   {
  //     keepPreviousData: true,
  //     staleTime: Infinity,
  //     cacheTime: 0
  //   }
  // );

  // console.log('data from fetch:', data);

  const [token] = useState(localStorage.getItem("token"));
  const [api_host] = useState(process.env.REACT_APP_API_URL || myConstClass.REACT_APP_API_URL);

  const [isLoading] = useState(false)
  const [isSuccess, setisSuccess] = useState(false)
  const [data, setdata] = useState([])
  const [count, setcount] = useState()

  const [canPreviousPage, setcanPreviousPage] = useState(false)
  const [canNextPage, setcanNextPage] = useState(true)
  
  const [dataCount, setdataCount] = useState(0)
  const [fristsortValue, setfristsortValue] = useState()

  const [sortField, setsortField] = useState("");
  const [sortOrder, setsortOrder] = useState("");
  const [filterField, setfilterField] = useState("");
  const [filterValue, setfilterValue] = useState("");

  const [lastTarget, setLastTarget] = useState("");
  const [lastTargetValue, setLastTargetValue] = useState("");

  useEffect(() => {
    var uri = `${api_host}/factory/devices/count?`;
    if (batch !== "") {
      var batchInfo = batch.split(":");
      batch = batchInfo[0];
      uri = `${uri}&batch=${batch}`
    }
    if(field !== "" && query !== "") {
      uri = `${uri}&field=${field}&field_value=${query}`
    }
    if (outdatatime !== "") {
      uri = `${uri}&outdatatime=${outdatatime}`
    }
    var encodedURI = encodeURI(uri);
    console.log("uri:", uri)
    console.log("encodeURI:", encodedURI)

    myAxiosInstance(encodedURI, {
        method: 'GET',
        headers: {
            'Authorization': `ApiKey ${token}`,
            'Content-Type': 'application/json'
        },
    })
        .then(res => {
            console.log("response: ",res.data);
            setcount(res.data)
        })
        .catch(err => {
            console.log("error： ", err.statustext);
        })
},[field, query, api_host, token, outdatatime])

  useEffect(() => {
    var uri = `${api_host}/devices/searchafter?&limit=${queryPageSize}`;
    if (batch !== "") {
      var batchInfo = batch.split(":");
      batch = batchInfo[0];
      uri = `${uri}&batch=${batch}`
    }
    if(field !== "" && query !== "") {
      uri = `${uri}&field=${field}&field_value=${query}`
    }
    if (filterField !== "" && filterValue !== "") {
      uri = `${uri}&filterField=${filterField}&filterValue=${filterValue}`;
    }
    if (sortField !== "" && sortOrder !== "") {
      uri = `${uri}&sortField=${sortField}&sortOrder=${sortOrder}`;
    }
    if (outdatatime !== "") {
      uri = `${uri}&outdatatime=${outdatatime}`
    }
    var encodedURI = encodeURI(uri);
    console.log("uri:", uri)
    console.log("encodeURI:", encodedURI)

    myAxiosInstance(encodedURI, {
        method: 'GET',
        headers: {
            'Authorization': `ApiKey ${token}`,
            'Content-Type': 'application/json'
        },
    })
        .then(res => {
            // console.log("response: ",res);
            setdata(res.data);
            setdataCount(res.data.length);
            setfristsortValue(res.data[0]["sort"][0])
            setisSuccess(true);
            setcanPreviousPage(false)
            if (res.data.length < queryPageSize) {
                setcanNextPage(false)
            } else {
                setcanNextPage(true)
            }
        })
        .catch(err => {
            console.log("error： ", err.statustext);
        })
}, [batch, field, query, queryPageSize, api_host, token, filterField, filterValue, sortField, sortOrder, outdatatime])

function gotoPage() {
  var uri = `${api_host}/devices/searchafter?&limit=${queryPageSize}`;
  if (batch !== "") {
    var batchInfo = batch.split(":");
    batch = batchInfo[0];
    uri = `${uri}&batch=${batch}`
  }
  if(field !== "" && query !== "") {
    uri = `${uri}&field=${field}&field_value=${query}`
  }
  if (filterField !== "" && filterValue !== "") {
    uri = `${uri}&filterField=${filterField}&filterValue=${filterValue}`;
  }
  if (sortField !== "" && sortOrder !== "") {
    uri = `${uri}&sortField=${sortField}&sortOrder=${sortOrder}`;
  }
  if (outdatatime !== "") {
    uri = `${uri}&outdatatime=${outdatatime}`
  }
  var encodedURI = encodeURI(uri);
  console.log("uri:", uri)
  console.log("encodeURI:", encodedURI)

  myAxiosInstance(encodedURI, {
      method: 'GET',
      headers: {
          'Authorization': `ApiKey ${token}`,
          'Content-Type': 'application/json'
      },
  })
      .then(res => {
          // console.log("response: ",res);
          setdata(res.data);
          setdataCount(res.data.length);
          setisSuccess(true);
          setcanNextPage(true)
          setcanPreviousPage(false)
      })
      .catch(err => {
          console.log("error： ", err.statustext);
      })
}

function previousPage() {
  var uri = `${api_host}/devices/searchafter?&limit=${queryPageSize}`;
  if (batch !== "") {
    var batchInfo = batch.split(":");
    batch = batchInfo[0];
    uri = `${uri}&batch=${batch}`
  }
  if(field !== "" && query !== "") {
    uri = `${uri}&field=${field}&field_value=${query}`
  }
  if (sortField !== "" && sortOrder !== "") {
    if (sortOrder === "asc") {
      uri = `${uri}&sortField=${sortField}&searchafterValue=${data[0]["sort"][1]}&sortValue=${data[0]["sort"][0]}&sortOrder=desc`;
    }
    else if (sortOrder === "desc") {
      uri = `${uri}&sortField=${sortField}&searchafterValue=${data[0]["sort"][1]}&sortValue=${data[0]["sort"][0]}&sortOrder=asc`;
    }
  }
  if (outdatatime !== "") {
    uri = `${uri}&outdatatime=${outdatatime}`
  }
  var encodedURI = encodeURI(uri);
  console.log("uri:", uri)
  console.log("encodeURI:", encodedURI)

  myAxiosInstance(encodedURI, {
      method: 'GET',
      headers: {
          'Authorization': `ApiKey ${token}`,
          'Content-Type': 'application/json'
      },
  })
      .then(res => {
          //console.log("response: ",res);
          setdata(res.data.reverse());
          setdataCount(res.data.length);
          setcanNextPage(true)
          setisSuccess(true);
          setcanNextPage(true)
          if (res.data[0]["sort"][0] === fristsortValue) {
              setcanPreviousPage(false)
          }
      })
      .catch(err => {
          console.log("error： ", err.statustext);
      })
}
function nextPage() {
  var uri = `${api_host}/devices/searchafter?&limit=${queryPageSize}`;
  if (batch !== "") {
    var batchInfo = batch.split(":");
    batch = batchInfo[0];
    uri = `${uri}&batch=${batch}`
  }
  if(field !== "" && query !== "") {
    uri = `${uri}&field=${field}&field_value=${query}`
  }
  if (sortField !== "" && sortOrder !== "") {
    uri = `${uri}&sortField=${sortField}&searchafterValue=${data[dataCount - 1]["sort"][1]}&sortValue=${data[dataCount - 1]["sort"][0]}&sortOrder=${sortOrder}`;
  }
  else {
    uri = `${uri}&searchafterValue=${data[dataCount - 1]["sort"][0]}&sortOrder=asc`;
  }
  if (outdatatime !== "") {
    uri = `${uri}&outdatatime=${outdatatime}`
  }
  var encodedURI = encodeURI(uri);
  console.log("uri:", uri)
  console.log("encodeURI:", encodedURI)

  myAxiosInstance(encodedURI, {
      method: 'GET',
      headers: {
          'Authorization': `ApiKey ${token}`,
          'Content-Type': 'application/json'
      },
  })
      .then(res => {
          //console.log("response: ",res);
          setdata(res.data);
          setdataCount(res.data.length);
          setcanPreviousPage(true)
          setisSuccess(true);
          if (res.data.length < queryPageSize) {
              setcanNextPage(false)
          }
      })
      .catch(err => {
          console.log("error： ", err.statustext);
      })
}

const handleCellClick = (row) => {
  console.log('Just clicked on row', row);
  if (row.original.type !== "device") {
    return;
  }
  if (row.original.lastRecord === null || row.original.lastRecord === undefined) {
    return;
  }
  //console.log('Device ID:', row.original.oneNetDevieId);
  console.log('Device ID:', row.original.imei);

  const deviceName = row.original.deviceName
  const deviceNo = row.original.deviceId
  const timestamp = row.original.lastRecord.timestampstring
  const hdralarmStatus = row.original.lastRecord.hdralarmStatus

  console.log('pushing into state:', deviceName, deviceNo, hdralarmStatus, pageIndex);

  aboutCallback(
    //row.original.oneNetDevieId,
    row.original.imei,
    row.original.imsi,
    deviceName,
    deviceNo,
    timestamp,
    hdralarmStatus,
    pageIndex
  );
}

useEffect(() => {
  console.log('data from axios:', data);
  console.log('data count:', count)
}, [data, count])

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter, id }
}) {

  return (
    <Input
      type="text"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
        setLastTarget(id);
        setLastTargetValue(e.target.value);
        FilterCallback(id);
      }}
      placeholder="查询..."
      style={{
        fontSize: "12px"
      }}
    />
  );
}

const defaultColumn = React.useMemo(
  () => ({
    Filter: DefaultColumnFilter
  }),
  []
);


  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    // canPreviousPage,
    // canNextPage,
    // pageOptions,
    // pageCount,
    // gotoPage,
    // nextPage,
    // previousPage,
    setPageSize,
    setAllFilters,
    setSortBy,
    // Get the state from the instance
    state: { pageIndex, pageSize, sortBy, filters },
  } = useTable(
    {
      columns,
      //data: isSuccess ? trimData(data.results) : [],
      data: isSuccess ? data : [],
      initialState: {
        pageIndex: queryPageIndex,
        pageSize: queryPageSize,
      },      
      defaultColumn,
      manualFilters: true,
      manualPagination: true, // Tell the usePagination
      // hook that we'll handle our own data fetchings
      // This means we'll also have to provide our own
      // pageCount.
      manualSortBy: true,
      // pageCount: isSuccess ? Math.ceil(totalCount / queryPageSize) : null,
    },
    useFilters,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    dispatch({ type: PAGE_CHANGED, payload: pageIndex });
  }, [pageIndex]);

  useEffect(() => {
      dispatch({ type: PAGE_SIZE_CHANGED, payload: pageSize });
  }, [pageSize]);

  useEffect(() => {
    console.log('selected updated:', {query, field, batch});

    //when a new path is selected, reset any filters or sort ordering 
    setAllFilters([]);
    setSortBy([]);

    Object.entries(headerGroups).map((headerGroup) => {
      headerGroup[1].headers.forEach((column, index) => {
        if (column.canFilter) {
          if (column.filterValue !== undefined && column.filterValue !== "") {
            column.setFilter("");
            column.filterValue = "";
          }
        }
      });

    })

    //direct user back to the first page of the table

  }, [query, field, batch, setAllFilters, setSortBy])

  useEffect(() => {
    console.log('sortBy has changed in the UI:', sortBy);
    dispatch({ type: SORT_CHANGED, payload: sortBy });
  }, [sortBy])

  useEffect(() => {
    console.log('filter has changed in the UI:', filters);

    if (lastTargetFilter !== "" && lastTargetFilterValue !== "") {

      //filter has been selected from dropdown, override any filters previously entered in other columns
      setLastTarget("");

      Object.entries(headerGroups).map((headerGroup) => {
        headerGroup[1].headers.forEach((column, index) => {
          if (column.canFilter) {
            if (column.filterValue !== undefined && column.filterValue !== "" && column.id !== lastTargetFilter) {
              column.setFilter("");
              column.filterValue = "";
            }
          }
        });

      })
    } else if (lastTarget !== "" && lastTargetValue !== "") {
      //filter has been entered in textbox, override any filters previously entered in other columns

      Object.entries(headerGroups).map((headerGroup) => {
        headerGroup[1].headers.forEach((column, index) => {
          if (column.canFilter) {
            if (column.filterValue !== undefined && column.filterValue !== "" && column.id !== lastTarget) {
              column.setFilter("");
              column.filterValue = "";
            }
          }
        });

      })

    }

    //dispatch({type: FILTER_CHANGED, payload: filters, currentFilter: lastTargetFilter || lastTarget});
    dispatch({ type: FILTER_CHANGED, payload: filters });
  }, [filters])

  useEffect(() => {
    if (querySortBy !== null && querySortBy.length > 0) {
      console.log('sortBy', querySortBy);

      if (querySortBy[0].id === "lastRecord.timestampstring") {
        setsortField("timestampstring");
      }
      else{
        setsortField(querySortBy[0].id);
      }
      if (querySortBy[0].desc) {
        setsortOrder("desc")
      } else {
        setsortOrder("asc")
      }
    }
    else {
      setsortField("");
      setsortOrder("");
    }

    if (queryFilters !== null && queryFilters.length > 0) {

      var filterIndex = 0;

      for (let i = 0; i < queryFilters.length; i++) {
        if (queryFilters[i].value.trim() !== "") {
          filterIndex = i;
        }
      }

      console.log("using this as the filter:", queryFilters[filterIndex].id);

      if (queryFilters[filterIndex].id === "deviceId") {

        //wait till minimum of 8 characters has been entered before setting deviceId filter in querystring

        if (queryFilters[filterIndex].value.trim().length >= 8) {
          setfilterField(queryFilters[filterIndex].id);
          setfilterValue(queryFilters[filterIndex].value.trim());
        }

      }

      if (queryFilters[filterIndex].id === "CustomerBillingID_str") {

        //wait till minimum of 6 characters has been entered before setting CustomerBillingId filter in querystring

        if (queryFilters[filterIndex].value.trim().length >= 1) {
          setfilterField(queryFilters[filterIndex].id);
          setfilterValue(queryFilters[filterIndex].value.trim());
        }

      }

      if (queryFilters[filterIndex].id === "oneNetDevieId") {

        //wait till minimum of 9 characters has been entered before setting oneNetDevieId filter in querystring

        if (queryFilters[filterIndex].value.trim().length >= 9) {
          setfilterField(queryFilters[filterIndex].id);
          setfilterValue(queryFilters[filterIndex].value.trim());
        }

      }

      if (queryFilters[filterIndex].id === "imei") {

        //wait till minimum of 15 characters has been entered before setting imei filter in querystring

        if (queryFilters[filterIndex].value.trim().length >= 15) {
          setfilterField(queryFilters[filterIndex].id);
          setfilterValue(queryFilters[filterIndex].value.trim());
        }

      }


      if (queryFilters[filterIndex].id === "CustomerBillingType" || queryFilters[filterIndex].id === "MeterBodyDiameterString") {
        setfilterField(queryFilters[filterIndex].id);
        setfilterValue(queryFilters[filterIndex].value.trim());
      }

      if (queryFilters[filterIndex].id === "lastRecord.hdralarmStatus") {
        setfilterField(queryFilters[filterIndex].id);
        setfilterValue(queryFilters[filterIndex].value.trim());
      }

    }
    else {
      setfilterField("");
      setfilterValue("");
    }

  }, [queryFilters, querySortBy, query, field, batch])

  if (isLoading) {
    return <img src={loaderimage} alt="载入中..." />;
  }

  return (
    <>
      {isSuccess ? (
        <>
          <h4>总数: {count}</h4>
          <Table {...getTableProps()} hover bordered responsive>
            {/* <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead> */}
            <thead>
              {headerGroups.map((headerGroup) => (
                <>
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    <th className="t-cell-1 text-mid">{"查询数据"}</th>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                        {column.render('Header')}
                        {/* Add a sort direction indicator */}
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? " 🔽"
                              : " 🔼"
                            : ""
                          }
                        </span>
                      </th>
                    ))}
                  </tr>
                  <tr style={{ backgroundColor: "aliceBlue" }}>
                    <th>{" "}</th>
                    {headerGroup.headers.map((column, index) => (
                      <th className="tfilter">
                        {column.canFilter ? (
                          <FormGroup className="mb-1">
                            {column.render("Filter")}
                          </FormGroup>
                        ) : null}
                      </th>
                    ))}
                  </tr>
                </>
              ))}
            </thead>
            {page.length === 0 ?
              <tbody>
                <tr>
                  <td colSpan="10000" className="text-left">
                    <p style={{
                      "text-align": "left"
                    }}
                    >
                      没有数据显示 {query}
                    </p>
                  </td>
                </tr>
              </tbody>
              :
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      <td className="t-cell-1 text-left">
                          <Button
                              type='edit'
                              onClick={() => handleCellClick(row)}
                              >
                                查询
                          </Button>
                      </td>
                      {row.cells.map((cell) => (
                        <td {...cell.getCellProps({
                          className: cell.column.className,
                        })}>
                          {cell.render('Cell')}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            }
          </Table>
          {page.length > 0 && (
            <div className={classNames("div-pagination", { "d-none": isLoading })}>
              <div className="div-pagination-2">
                <div className="div-pagination-2-2">
                  每页显示{" "}
                  <select
                    className="selectan"
                    value={pageSize}
                    onChange={(e) => {
                      setPageSize(Number(e.target.value));
                    }}
                  >
                    {[10, 20, 30, 50, 100].map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        {pageSize}
                      </option>
                    ))}
                  </select>{" "}
                  记录
                </div>
              </div>

              <div className="div-pagination-1">
                <Pagination className="pagina">
                  <PaginationItem disabled={!canPreviousPage}>
                    <PaginationLink onClick={() => gotoPage()}>
                      {"<<"}
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem disabled={!canPreviousPage}>
                    <PaginationLink onClick={() => previousPage()}>
                      {"<"}
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem disabled={!canNextPage}>
                    <PaginationLink onClick={() => nextPage()}>
                      {">"}
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>
              </div>
            </div>
          )}
        </>
      ) : <p style={
        {
          "text-align": "left"
        }
      }>没有数据显示</p>}
    </>
  );
}

export default MeterTableFactory;
