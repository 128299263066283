import React, { Component } from "react";
import "./Login.css";
import { Redirect } from "react-router-dom";
import axios from 'axios';
import { Container, Row, Col, Card, CardBody, CardTitle, CardText } from "reactstrap";
import { InputGroup, InputGroupAddon, InputGroupText, Input, Label } from 'reactstrap';
import { FaUserAlt } from "react-icons/fa"
import { AiFillLock } from "react-icons/ai";
import * as myConstClass from './utils/fileWithConstants';
import { Footer } from "./utils/footer";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      islogged: false,
      isError: false,
      passwordShown: false,
      loginParams: {
        user_id: "",
        user_password: ""
      }
    };
  }

  handleFormChange = event => {
    let loginParamsNew = { ...this.state.loginParams };
    let val = event.target.value;
    loginParamsNew[event.target.name] = val;
    this.setState({
      loginParams: loginParamsNew
    });
    this.setState({
      isError: false
    });
  };

  togglePasswordVisibility = event => {
    if (event.target.checked) {
      this.setState({
        passwordShown: true
      });      
    } else {
      this.setState({
        passwordShown: false
      });      
    }
  };
 
  login = event => {
    let user_id = this.state.loginParams.user_id;
    let user_password = this.state.loginParams.user_password;

    const user_token = Buffer.from(`${user_id}:${user_password}`, 'utf8').toString('base64')
    const api_host = process.env.REACT_APP_API_URL || myConstClass.REACT_APP_API_URL;

    try {
      axios.post(`${api_host}/login/`, {}, {
        headers: {
          'Authorization': `Basic ${user_token}`
        },
      })
      .then(
        response => {
          console.log("Login response:", response.status)
          console.log("Login data:", response.data)
          if (response.status === 200) {
            localStorage.setItem("token", response.data.apiKey);
            localStorage.setItem("username", user_id);
            localStorage.setItem("userrole", response.data.role);
            localStorage.setItem("displayname", response.data.display_name);
            localStorage.setItem("companyname", response.data.company_name);
            localStorage.setItem("rootpath", response.data.rootpath);
            localStorage.setItem("watersub", response.data.watersub);
            this.setState({
              islogged: true
            });
          } else {
            console.log("Invalid user ID or password")
            this.setState({
              isError: true
            });
            //alert("Invalid user ID or password. Please try again. Your response is not equal to 200.")
          }
        }
      ).catch ( error => {
          const errorObject=JSON.parse(JSON.stringify(error))
          console.log("Invalid login credentials:", errorObject)
          this.setState({
            isError: true
          });
          //alert('Invalid user ID or password. Please try again. ')
        }
      )      
    } catch (e) {
      console.log (`Axios login request failed: ${e}` )
      alert("账号验证失败，请重新登录")
    }

    event.preventDefault();
  };

  componentDidMount(props) {
    if (this.props.location) {
      console.log("from:", this.props.location);
      if (this.props.location.state && this.props.location.state.infoMsg) {
        console.log("infoMsg: ",this.props.location.state.infoMsg);
      } else {
        console.log("cannot read this.props.location.state.infoMsg");
      }
    } else {
      console.log("cannot read this.props.location");
    }
   
  }

  render() {
    if (localStorage.getItem("token")) {
      return <Redirect to="/" />;
    }

    console.log("API URL:", myConstClass.REACT_APP_API_URL);

    return (
        <div className="App">
          <Container 
            className="themed-container"
            style={{
              display: "flex",
              height: "100vh",
              margin: "0px",
              padding: "0px",
              justifyContent: "center"
            }}
            fluid
          >
          <Row>
          <Col xs="auto"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%"    
            }}>
            <img className="loginLogo" src="logo192.png" alt="Company Logo"/></Col>
          <Col xs="auto"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%"     
            }}>
          <Card>
              <CardBody>
                <CardTitle tag="h2">智能供水计量监控管理系统 2.5</CardTitle>
                <form onSubmit={this.login} className="form-signin">
                <div className="form-group">
                  <InputGroup>
                    <InputGroupAddon 
                      addonType="prepend">
                        <InputGroupText>
                          <FaUserAlt />
                        </InputGroupText>                        
                    </InputGroupAddon>
                    <Input
                      type="text"
                      name="user_id"
                      onChange={this.handleFormChange}
                      placeholder="账户"
                      style={{margin: '0'}}
                    />
                  </InputGroup>
                </div>
                <div className="form-group">
                  <InputGroup>
                    <InputGroupAddon 
                      addonType="prepend">
                        <InputGroupText>
                          <AiFillLock />
                        </InputGroupText>
                    </InputGroupAddon>
                    <Input
                    type={this.state.passwordShown ? "text" : "password"}
                    name="user_password"
                    onChange={this.handleFormChange}
                    placeholder="密码"
                    style={{margin: '0'}}
                    />
                  </InputGroup>
                </div>
                <div className="form-group">
                  <Label check>
                    <Input 
                      type="checkbox"
                      style={{width: 'auto'}}
                      onChange={this.togglePasswordVisibility}/>{' '} 
                      显示密码
                  </Label>
                </div>
                <input type="submit" value="登录" />
                { this.state.isError && <h3 className="text-danger">无法验证您的登录凭据，请重试.</h3>}
                { !this.state.isError && this.props.location.state && this.props.location.state.infoMsg && <h3 className="text-info">{this.props.location.state.infoMsg}</h3>}
                </form>
                <CardText> 
                  <Footer />
                </CardText>
              </CardBody>
          </Card>
          </Col>
          </Row>
          </Container>
        </div>
    );
  }
}

export default Login;
