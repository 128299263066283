import { getAllByAltText } from "@testing-library/dom";
import React, { PureComponent } from "react";
import TreeMenu from "react-simple-tree-menu";
//import myAxiosInstance from "./utils/axios-intercept";
//import podconnMenu from "./data/podconn_new.json";
import "./GroupComponent/main.css";
import {nthIndex} from './utils/commonFunc';

//function nthIndex (str, pat, n) {
//    var L = str.length, i = -1;
//    while(n-- && i++<L){
//        i= str.indexOf(pat, i);
//        if (i < 0) break;
//    }
//    return i;
//}

class GroupMenuNew extends PureComponent {

    constructor(props) {
      super();
      this.state = {
          isLoggedIn: false,
          islogout: false,
          feedback: ""
      }

      this.parentCallback = props.parentCallback.bind(this);
      this.sayTheWord = this.sayTheWord.bind(this);

    }

    async componentDidMount() {

        if (this.props.initialGroup !== "ALL") {
            var openNodesArray = [];
            var pathToRoot = "";

            const entities = this.props.initialGroup.split("/")

            console.log("entities after split:", entities);

            for (var index=2; index < entities.length; index++) {
                if (pathToRoot.length > 0) {
                    pathToRoot = pathToRoot + "/" + entities[index];
                } else {
                    pathToRoot = pathToRoot + entities[index];
                }
                openNodesArray.push(pathToRoot);
            }

            console.log("openNodes:", openNodesArray);

            const selectedMenuIndex = nthIndex(this.props.initialGroup,"/",2); 

            console.log("active Node:", this.props.initialGroup.substring(selectedMenuIndex));
        }
    }

    sayTheWord = ({ key, label, url, ...props }) => {
        console.log("You clicked on the menu node!", key, " ", label, " ", url, " ", props);
        //console.log("Your groups in sayTheWord:", this.props.groups);
        //const groups = ["zone1", "zone2", "我的小區一", "我的小区二", "zone3", "zone4", "zone8", "zone9"];
        //if (this.props.groups.some((item) => (item === label))) {
        console.log("We are gonna call the callback, woo hoo!");
        this.parentCallback(url,label);
        //} else {
        //    console.log("No need to refresh the group. We are all good.");
        //}
    }

    handleAxiosError = (e) => {
        this.setState({
            ...this.state,
            feedback: `Axios user request failed: ${e}`,
            loading: false
        });
    }

    render() {
        
        const groups = this.props.groups;
        const loading = this.props.loading;
        const selectedGroup = this.props.initialGroup;

        console.log("initially selected in the menu:", selectedGroup);

        var openNodesArray = [];
        var activeNode = "";

        if (selectedGroup !== "undefined" && selectedGroup !== "ALL") {
            var pathToRoot = "";

            const entities = selectedGroup.split("/")

            console.log("entities after split:", entities);

            for (var index=2; index < entities.length; index++) {
                if (pathToRoot.length > 0) {
                    pathToRoot = pathToRoot + "/" + entities[index];
                } else {
                    pathToRoot = pathToRoot + entities[index];
                }
                openNodesArray.push(pathToRoot);
            }

            console.log("openNodes:", openNodesArray);

            const selectedMenuIndex = nthIndex(selectedGroup,"/",3);
            
            activeNode = selectedGroup.substring(selectedMenuIndex+1);

            console.log("active Node:", activeNode);
        }


        return (
            <div className="controls-container">
            {this.state.feedback && (
                <p>{this.state.feedback}</p>
            )}
            {loading ? 
                <p>在下載過程中</p>
            : (
                <>
                {groups.length > 0 ?
                    (
                        <TreeMenu
                            cacheSearch
                            data={groups}
                            debounceTime={125}
                            disableKeyboard={false}
                            hasSearch={false}
                            onClickItem={this.sayTheWord}
                            initialOpenNodes={openNodesArray}
                            initialActiveKey={activeNode}
                            initialFocusKey={activeNode}
                            resetOpenNodesOnDataUpdate={false}
                        />
                    )
                : 
                    <p>没有群组可显示</p>
                }
                </>
            )}
            </div>            
        )

    }

}

export default GroupMenuNew;