import * as myConstClass from '../utils/fileWithConstants';
import myAxiosInstance from "../utils/axios-intercept";
import history from "../utils/history.jsx";

export const fetchFactoryMeterData = async (page, pageSize, field, query, batch, nodata) => {

  const offset = page * pageSize;

  console.log('in fetchFactoryMeterData with params', field, query, batch);

  try {
    const token = localStorage.getItem("token");
    const api_host = process.env.REACT_APP_API_URL || myConstClass.REACT_APP_API_URL;

    var queryString = "";

    if (field !== "") {
      if (query !== "") {
        // only set the field name if a search value has been entered for it
        queryString = `field=${field}`
      }
    }

    if (query !== "") {
      if (queryString === "") {
        queryString = `query=${query}`
      } else {
        queryString = `${queryString}&query=${query}`
      }
    }

    var uri, batchInfo;

    if (batch !== "") {
      batchInfo = batch.split(":");

      if (queryString === "") {
        queryString = `batch=${batchInfo[0]}`
      } else {
        queryString = `${queryString}&batch=${batchInfo[0]}`
      }
    }

   if(nodata) {
      queryString = `${queryString}&nodata`
    }
    console.log("here is the queryString:", queryString);
    
    if (queryString === "") {
      uri = `${api_host}/factory/devices?offset=${offset}&limit=${pageSize}`;
    } else {
      uri = `${api_host}/factory/devices?offset=${offset}&limit=${pageSize}&${queryString}`;
    }

    console.log("fetch meter URI:", uri);
    var encodedURI = encodeURI(uri);
    console.log("fetch meter encoded URI:", encodedURI);

    var data;

    await myAxiosInstance.get(encodedURI, {
      headers: {
        'Authorization': `ApiKey ${token}`,
        'Content-Type': 'application/json'
      }
    })
    .then(res => {
      data = res.data;
      console.log('uri to get meter data 8-Oct:', uri);
      console.log("Here is the meter data 8-Oct:", data);
    })
    .catch ( error => {
      const errorObject=JSON.parse(JSON.stringify(error));
      console.log(errorObject);
    })


    //const response = await fetch(encodedURI, {
    //  method: 'GET', 
    //  headers: {
    //    'Authorization': `ApiKey ${token}`,
    //    'Content-Type': 'application/json'
    //  }
    //});

    //const data = await response.json();
    console.log("returning:", data);
    //console.log("returning type:", typeof data);

    // hack to handle the 401 status code
    //if(String(data).includes("AuthenticationException(401")) {
    //  localStorage.clear();

    //  history.push({
    //      pathname: "/login",
    //      state: {
    //          infoMsg: "您当前的会话已结束。请重新登录."
    //      }
    //  });
    //  return Promise.reject(data);
    //}
    //populate the total number of meters in the result
    var data_count = 0;

    if (query !== "") {
      
      data_count = data.length;

    } else {

      if (batch === "") {
        uri = `${api_host}/factory/devices/count`;
        encodedURI = encodeURI(uri);

        await myAxiosInstance.get(encodedURI, {
          headers: {
            'Authorization': `ApiKey ${token}`,
            'Content-Type': 'application/json'
          }
        })
        .then(res => {
          const data_count = res.data;
          console.log("Here is the meter data count 8-Oct:", data_count);
        })
        .catch ( error => {
          const errorObject=JSON.parse(JSON.stringify(error));
          console.log(errorObject);
          return completeData;
        });
  
        //const response_count = await fetch(encodedURI, {
        //    method: 'GET', 
        //    headers: {
        //      'Authorization': `ApiKey ${token}`,
        //      'Content-Type': 'application/json'
        //    }
        //  });
      
        //data_count = await response_count.json();
  
      } else {
        //no need to call API to get the count since it's been passed via the function arguments
  
        batchInfo = batch.split(":");
        if( nodata) 
          data_count = data.length;
        else 
          data_count = batchInfo[1];
  
      }

    }
    
    const completeData = {};

    completeData['count'] = data_count;
    completeData['results'] = data;

    console.log("returning:", completeData);

    return completeData;

  } catch (e) {
    throw new Error(`API error:${e?.message}`);
  }
};
