import React, { PureComponent } from "react";
import { withRouter } from "react-router";
import { Container, Row, Col, Alert } from "reactstrap";
import './styles.css';
import './TableComponent/Table.css';
import BarChart from "./Charts/BarChart";
import PieChart from "./Charts/PieChart";
import myAxiosInstance from "./utils/axios-intercept";
import GroupMenuNew from "./GroupMenuNew";
import './GroupComponent/GroupTree.css';
import * as myConstClass from './utils/fileWithConstants';
import { nthIndex } from './utils/commonFunc';
import { QueryClientProvider, QueryClient } from 'react-query';
import MeterTable from './PagingComponent/MeterTable';
import CountData from "./AlarmComponent/CountData";
import Success_rate from './AlarmComponent/Success_Rate';
import axios from "axios";
const queryClient = new QueryClient();

const CancelToken = axios.CancelToken;
let cancel

class IndexDashboard extends PureComponent {

  constructor(props) {
    super(props);

    console.log("props in constructor:", props);

    this.state = {
      meterList: [],
      groups: [],
      billingTypeList: {},
      meterDiamList: {},
      lastTargetFilter: "",
      lastTargetFilterValue: "",
      loadingGroups: false,
      isLoggedIn: false,
      islogout: false,
      selectedGroup: "",
      selectedNodeName: "",
      selectedGroupInfo: {},
      selectedGroupWaterUsage: {},
      selectedGroupWaterDiff: {},
      selectedGroupAlarmData: {},
      loading: false,
      visible: false,
      feedback: "",
      alertType: "info",
      meterCount: 0,
      initPageIndex: 0,
      alarmnew1: [{"value": 1, "label": "过流" }],
      alarmnew2: [{"value": 2, "label": "移除" }],
      alarmnew4: [{"value": 4, "label": "干扰" }],
      alarmnew8: [{"value": 8, "label": "泄漏" }],
      alarmnew10: [{"value": 100, "label": "低電" }]
    };
  }

  handleCallback = (childData, childKey) => {
    console.log("Parent received:", childData);
    this.setState({ selectedGroupInfo: {} });
    this.setState({ selectedGroupWaterUsage: {} });
    this.setState({ selectedGroupWaterDiff: {} });
    this.setState({ selectedGroupAlarmData: {} });
    this.setState({ selectedGroup: childData }, this.handleSelect);
    this.setState({ selectedNodeName: childKey }, this.handleSelect);
  }

  handleSelect = () => {
    console.log("Updated group:", this.state.selectedGroup);
    console.log("Updated node:", this.state.selectedNodeName);
  }

  onDismiss = () => {
    this.setState({ visible: false });
  }

  handleDeviceCallback = (imsi,deviceId, deviceName, deviceNo, timestamp, hdralarmStatus, MeterInstallDateString_v2, pageIndex, selectedGroup) => {
    console.log("Parent received:",  imsi,deviceId, deviceName, deviceNo, timestamp, hdralarmStatus, MeterInstallDateString_v2, pageIndex, selectedGroup);
    this.props.history.push({
      pathname: `/Devices/${deviceId}`,
      state: {
        deviceimsi:imsi,
        deviceName: deviceName,
        deviceNo: deviceNo,
        timestamp: timestamp,
        alarmStatus: hdralarmStatus,
        MeterInstallDateString_v2: MeterInstallDateString_v2,
        currPage: pageIndex,
        selectedGroup: selectedGroup,
        selectedNodeName: this.state.selectedNodeName,
        referredBy: `IndexDashboard`
      }
    });
  }

  handleAxiosError = (e) => {
    if (axios.isCancel(e)) {
      console.log("cancelled");
    }
    // else {
    //   this.setState({ alertType: "danger" });
    //   this.setState(
    //     {
    //       feedback: `Axios user request failed: ${e}`
    //     }
    //   );
    //   this.setState(
    //     {
    //       visible: true
    //     }
    //   )};
  }

  getJsonField = (data, field) => {
    var ret = []
    console.log("getting field %s ...", field)
    for (var i = 0; i < data.length; i++) {
      //console.log("getting field %s %d %s", field, i, data[i])
      ret[i] = data[i][field]
    }
    return ret;
  }

  generateGroups = () => {
    const token = localStorage.getItem("token");
    const api_host = process.env.REACT_APP_API_URL || myConstClass.REACT_APP_API_URL;

    var uri = `${api_host}/hierarchy/entities/navigate`;
    var encodedURI = encodeURI(uri);
    console.log("encoded URI:", encodedURI)
    this.setState({ loadingGroups: true });

    myAxiosInstance.get(encodedURI, {
      headers: {
        'Authorization': `ApiKey ${token}`
      }
    })
      .then(res => {
        const groupList = res.data;
        const groups = [];
        for (let index = 0; index < groupList.length; index++) {
          if (groupList[index]._count > 1 ) {
            groups.push(groupList[index]);
          }
        }
        if (groups.length > 0) {
            this.setState({ groups: groups });
            console.log("groupMenu:", groups);
          // console.log("groupMenu1:", groupList[0].url); //
          // this.setState({selectedGroup:groupList[0].url})
          // this.setState({selectedNodeName:groupList[0].url})
          // console.log("node:", this.state.selectedNodeName);

        } else {
          console.log("Empty menu!");
        }
        this.setState({ loadingGroups: false });
      })
      .catch(error => {
        const errorObject = JSON.parse(JSON.stringify(error));
        console.log(errorObject.message);
        this.setState({
          islogout: true
        }, () => {
          this.handleAxiosError(error);
        });
        this.setState({ loadingGroups: false });
      })

  }

  getGroupInfo = () => {

    const token = localStorage.getItem("token");
    const api_host = process.env.REACT_APP_API_URL || myConstClass.REACT_APP_API_URL;
    var uri = "";

    var waterUsageData = {
      labels: [],
      datasets: [
        {
          label: 'Daily Water Usage',
          fill: false,
          lineTension: 0.1,
          backgroundColor: 'rgba(54,162,235,0.4)',
          borderColor: 'rgba(54,162,235,1)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(54,162,235,1)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(54,162,235,1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: []
        }
      ]
    }

    var waterDiffData = {
      labels: [],
      datasets: [
        {
          label: 'Daily Water Diff',
          fill: false,
          lineTension: 0.1,
          backgroundColor: 'rgba(54,162,235,0.4)',
          borderColor: 'rgba(54,162,235,1)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(54,162,235,1)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(54,162,235,1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: []
        }
      ]
    }

    var alarmData = {
      labels: ['工作正常', '基表过流', '外部干扰', '持续泄漏', '模块移除'],
      datasets: [
        {
          //backgroundColor: 'rgba(54,162,235,0.4)',
          backgroundColor: ['#bae1ff', '#ffffba', '#baffc9', '#ffb3ba', '#ffdfba'],
          data: [0, 0, 0, 0, 0]
        }
      ]
    }


    uri = `${api_host}/analytics/statistics?path=${this.state.selectedGroup}`

    var encodedURI = encodeURI(uri);
    var jsonData;

    console.log("encoded URI:", encodedURI);

    if (cancel) cancel();

    myAxiosInstance
      .get(encodedURI, {
        headers: {
          'Authorization': `ApiKey ${token}`
        },
        cancelToken: new CancelToken(function executor(c) {
          cancel = c;})
      })
      .then(res => {
        const alarmStatistics = res.data;
        this.setState({ selectedGroupInfo: res.data });
        console.log('The selected group:', this.state.selectedGroup)
        console.log('Setting the group info:', alarmStatistics);

        uri = `${api_host}/analytics/dailydiff?path=${this.state.selectedGroup}`

        //uri = `${api_host}/dailydiff/${this.state.selectedGroup}`;
        encodedURI = encodeURI(uri);
        console.log("encoded URI:", encodedURI);

        // get the bar chart data
        myAxiosInstance
          .get(
            encodedURI, {
            headers: {
              'Authorization': `ApiKey ${token}`
            }
          })
          .then(
            res => {
              console.log('daily diff ', res.data);
              //var jsonData = JSON.parse(res.data);
              jsonData = res.data;
              var dates = this.getJsonField(jsonData, "readabletime");
              console.log(dates);

              //convert date strings to date objects so they can be formatted properly as dates on the chart
              for (var x = 0; x < dates.length; x++) {
                dates[x] = new Date(dates[x]);
              }

              var usages = this.getJsonField(jsonData, "water_usage_per_day");
              console.log(usages);
              var diff = this.getJsonField(jsonData, "water_diff");
              console.log(diff);

              waterUsageData.labels = dates;
              waterUsageData.datasets[0].data = usages;
              this.setState({ selectedGroupWaterUsage: waterUsageData });
              console.log('waterUsageData:', waterUsageData);
              console.log('set state waterUsageData:', this.state.selectedGroupWaterUsage);

              waterDiffData.labels = dates;
              waterDiffData.datasets[0].data = diff;
              this.setState({ selectedGroupWaterDiff: waterDiffData });

              alarmData.datasets[0].data[0] = alarmStatistics.alarm016 + alarmStatistics.other16;
              alarmData.datasets[0].data[1] = alarmStatistics.alarm1;
              alarmData.datasets[0].data[2] = alarmStatistics.alarm4;
              alarmData.datasets[0].data[3] = alarmStatistics.alarm8;
              alarmData.datasets[0].data[4] = alarmStatistics.alarm2;

              this.setState({ selectedGroupAlarmData: alarmData });

            }
          )
          .catch(error => {
            const errorObject = JSON.parse(JSON.stringify(error));
            console.log(errorObject.message);
            this.setState({
              islogout: true
            }, () => {
              this.handleAxiosError(error);
            });
          })

      })
      .catch(e => {
        console.log(`Axios user request failed: ${e}`);
        this.setState({
          islogout: true
        }, () => {
          this.handleAxiosError(e);
        });
      });

  }

  async componentDidMount() {

    console.log('index dashboard props.history', this.props.history);
    
    var entities = this.state.selectedGroup.split("/")
    var index = entities.length-1
    var Node_name = entities[index]
    this.setState({selectedNodeName : Node_name})

    if (this.props.location.state) {
      //retrieve the saved state of IndexDashboard after user is referred back to it from AboutDevice
      console.log("redirect props:", this.props.location.state.selectedGroup, ", ", this.props.location.state.pageIndex);
      this.setState({
        selectedGroup: this.props.location.state.selectedGroup,
        selectedNodeName: this.props.location.state.selectedNodeName,
        initPageIndex: this.props.location.state.pageIndex
      })
    }else{
      this.setState({selectedGroup: localStorage.getItem("rootpath")})
    }

    //get the list of entities to be shown on the menu
    this.generateGroups();

    const token = localStorage.getItem("token");
    const api_host = process.env.REACT_APP_API_URL || myConstClass.REACT_APP_API_URL;

    var uri = `${api_host}/hierarchy/entities/devCount?path=${this.state.selectedGroup}`;
    var encodedURI = encodeURI(uri);

    await myAxiosInstance.get(encodedURI, {
      headers: {
        'Authorization': `ApiKey ${token}`,
        'Content-Type': 'application/json'
      }
    })
    .then(res => {
      const meterCount = res.data;
      console.log('uri to get devCount:', uri);
      console.log("Here is the count:", meterCount);
      this.setState({meterCount: meterCount});
      if (meterCount > 0) {
        this.getGroupInfo();
      }
    })
    .catch ( error => {
      const errorObject=JSON.parse(JSON.stringify(error));
      console.log(errorObject);
    })

    //get billing types for the dropdown list in the results table
    uri = `${api_host}/devices/billingtypes?path=${this.state.selectedGroup}`;
    encodedURI = encodeURI(uri);

    await myAxiosInstance.get(encodedURI, {
      headers: {
        'Authorization': `ApiKey ${token}`,
        'Content-Type': 'application/json'
      }
    })
    .then(res => {
      const billingTypeList = res.data;

      console.log("Here are your billing types:", billingTypeList);
      this.setState({billingTypeList: billingTypeList});
    })
    .catch ( error => {
      const errorObject=JSON.parse(JSON.stringify(error));
      console.log(errorObject);
    })

    //get meter diameter values for the dropdown list in the results table
    uri = `${api_host}/devices/meterdiameters?path=${this.state.selectedGroup}`;
    encodedURI = encodeURI(uri);
  
    await myAxiosInstance.get(encodedURI, {
      headers: {
        'Authorization': `ApiKey ${token}`,
        'Content-Type': 'application/json'
      }
    })
    .then(res => {
      const meterDiamList = res.data;
      console.log("Here are your meter diameter types:", meterDiamList);
      this.setState({meterDiamList: meterDiamList});
    })
    .catch ( error => {
      const errorObject=JSON.parse(JSON.stringify(error));
      console.log(errorObject);
    })

  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedGroup !== this.state.selectedGroup) {
      console.log('selectedGroup has changed.')

      const api_host = process.env.REACT_APP_API_URL || myConstClass.REACT_APP_API_URL;
      const token = localStorage.getItem("token");

      var uri = `${api_host}/hierarchy/entities/devCount?path=${this.state.selectedGroup}`;
      var encodedURI = encodeURI(uri);

      await myAxiosInstance.get(encodedURI, {
        headers: {
          'Authorization': `ApiKey ${token}`,
          'Content-Type': 'application/json'
        }
      })
      .then(res => {
        const meterCount = res.data;
        this.setState({meterCount: meterCount});
        if (meterCount > 0) {
          this.getGroupInfo();          
        }        
      })
      .catch ( error => {
        const errorObject=JSON.parse(JSON.stringify(error));
        console.log(errorObject);
      })

      //get billing types for the dropdown list in the results table
      uri = `${api_host}/devices/billingtypes?path=${this.state.selectedGroup}`;
      encodedURI = encodeURI(uri);

      await myAxiosInstance.get(encodedURI, {
        headers: {
          'Authorization': `ApiKey ${token}`,
          'Content-Type': 'application/json'
        }
      })
      .then(res => {
        const billingTypeList = res.data;

        console.log("Here are your billing types:", billingTypeList);
        this.setState({billingTypeList: billingTypeList});
      })
      .catch ( error => {
        const errorObject=JSON.parse(JSON.stringify(error));
        console.log(errorObject);
      })

      //get meter diameter values for the dropdown list in the results table
      uri = `${api_host}/devices/meterdiameters?path=${this.state.selectedGroup}`;
      encodedURI = encodeURI(uri);
    
      await myAxiosInstance.get(encodedURI, {
        headers: {
          'Authorization': `ApiKey ${token}`,
          'Content-Type': 'application/json'
        }
      })
      .then(res => {
        const meterDiamList = res.data;
        console.log("Here are your meter diameter types:", meterDiamList);
        this.setState({meterDiamList: meterDiamList});
      })
      .catch ( error => {
        const errorObject=JSON.parse(JSON.stringify(error));
        console.log(errorObject);
      })

    }

  }

  // This is a custom filter UI for selecting a unique billing type from a list
  SelectBillingTypeFilter = ({column: { filterValue, setFilter, preFilteredRows, id }}) => {

    // Render a multi-select box
    return (
      <select
        value={filterValue || ""}
        style={
          {
            padding: '6px',
            marginTop: '5px',
            marginBotton: '5px',
            border: '1px solid #ced4da'
          }
        }
        onChange={e => {
          setFilter(e.target.value || undefined);
          this.setState({
            lastTargetFilter: id,
            lastTargetFilterValue: e.target.value
          });
        }}
      >

        <option key="" value="">全选</option>
        {Object.entries(this.state.billingTypeList).map(([key, value]) =>
          <option key={key} value={key}>
            {key}
          </option>
        )}        

      </select>
    )
  }

  // This is a custom filter UI for selecting a unique billing type from a list
  SelectMeterDiamFilter = ({column: { filterValue, setFilter, preFilteredRows, id }}) => {

    // Render a multi-select box
    return (
      <select
        value={filterValue || ""}
        style={
          {
            padding: '6px',
            marginTop: '5px',
            marginBotton: '5px',
            border: '1px solid #ced4da'
          }
        }
        onChange={e => {
          setFilter(e.target.value || undefined);
          this.setState({
            lastTargetFilter: id,
            lastTargetFilterValue: e.target.value
          });
        }}
      >
        <option key="" value="">全选</option>
        {Object.entries(this.state.meterDiamList).map(([key, value]) =>
          <option key={key} value={key}>
            {key}
          </option>
        )}        

      </select>
    )
  }

  resetFilterCallback = (newTargetFilter) => {
    console.log("text filter entered:", newTargetFilter);
    //text filter has been entered so clear any dropdown filter
    this.setState({
      lastTargetFilter: "",
      lastTargetFilterValue: ""
    })
  }


  render() {

    const alarmDataTitle = "水表工作状态汇总"
    const waterUseTitle = "过去 14 天用水量 m³";
    const waterDiffTitle = "过去 14 天用水量差距 m³";

    const basicHeader = [
      {
        Header: "客户编号",
        className: "t-cell-1 text-left",
        accessor: "CustomerBillingID_str"
      },

      {
        Header: "用户性质",
        className: "t-cell-1 text-left",
        accessor: "CustomerBillingType",
        Filter: this.SelectBillingTypeFilter,
        filter: 'includes',
        disableSortBy: true
      },
      {
        Header: "用户名",
        accessor: "CustomerBillingName",
        className: "t-cell-2 text-left",
        disableFilters: true,
        canFilter: false,
        disableSortBy: true
      },
      {
        Header: "最后更新",
        accessor: "lastRecord.timestampstring",
        className: "t-cell-6 text-left",
        disableFilters: true,
        canFilter: false,
        disableSortBy: false,
        Cell: ({ cell: { value } }) => {
          if (typeof value !== 'undefined') {
            const timeStampIndex = nthIndex(value, ":", 2);
            const displayTimeStamp = value.substring(0, timeStampIndex);
            return (
              `${displayTimeStamp}`
            )
          } else {
            return "";
          }
        }
      },
      {
        Header: "用水量 m³",
        accessor: "lastRecord.totalVol",
        className: "t-cell-10 text-left",
        disableFilters: true,
        canFilter: false,
        disableSortBy: false,
        Cell: ({ cell: { value } }) => {
          if (typeof value !== 'undefined') {
            const showVol = value / 10000
            const displayVol = showVol.toFixed(2)
            return (
              `${displayVol}`
            )
          } else {
            return "";
          }
        }
      },
      {
        Header: "模块号码",
        accessor: "deviceId",
        className: "t-cell-1 text-left",
        filter: 'equals'
      },
      {
        Header: "口径",
        accessor: "MeterBodyDiameterString",
        className: "t-cell-2 text-left",
        Filter: this.SelectMeterDiamFilter,
        filter: 'equals',
        disableSortBy: true
      },
      {
        Header: "信号强度",
        accessor: "lastRecord.CQS",
        className: "t-cell-1 text-right",
        disableFilters: true
      },
      {
        Header: "覆盖增强等级",
        accessor: "lastRecord.cellID",
        className: "t-cell-1 text-right",
        disableFilters: true
      },
      {
        Header: "用户地址",
        accessor: "MeterLocation",
        className: "t-cell-5a text-left",
        disableFilters: true,
        canFilter: false,
        disableSortBy: true
      },
      {
        Header: "水表安装时间",
        accessor: "MeterInstallDateString_v2",
        className: "t-cell-6 text-left",
        disableFilters: true,
        canFilter: false,
        disableSortBy: true,
        Cell: ({ cell: { value } }) => {
          if (typeof value !== 'undefined') {
            try{
              const showDate = value.split("T")[0]
              return (
                `${showDate}`
              )
            }catch{
              return value;
            }
          } else {
            return "";
          }
        }
      }
    ];

    const listHeaderDebug = [
      // {
      //   Header: "IMSI",
      //   className: "t-cell-1 text-left",
      //   accessor: "imsi",
      //   disableFilters: true,
      //   show: false
      // },

      {
        Header: "水表SN",
        className: "t-cell-1 text-left",
        accessor: "deviceName",
        disableFilters: true
      },
      {
        Header: "中国移动号码",
        accessor: "oneNetDevieId",
        className: "t-cell-1 text-left",
        filter: 'equals'
      },
      {
        Header: "水表号码",
        className: "t-cell-1 text-left",
        accessor: "deviceId",
        filter: 'equals'
      },
      {
        Header: "IMEI",
        accessor: "imei",
        className: "t-cell-1 text-left",
        filter: 'equals'
      },
      {
        Header: "警报",
        accessor: "lastRecord.hdralarmStatus",
        className: "t-cell-1 text-left",
        filter: 'equals'

      },
      {
        Header: "客户地址",
        accessor: "MeterLocation",
        className: "t-cell-5a text-left",
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: "水表口径 (DN)",
        accessor: "MeterBodyDiameterString",
        className: "t-cell-2 text-left",
        Filter: this.SelectMeterDiamFilter,
        filter: 'equals',
        disableSortBy: true
      },
      {
        Header: "基表号",
        accessor: "MeterBodyId",
        className: "t-cell-1 text-left",
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: "数据包序列号",
        accessor: "lastRecord.seqNum",
        className: "t-cell-1 text-right",
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: "成功上报计数",
        accessor: "lastRecord.okCount",
        className: "t-cell-1 text-right",
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: "总用水 m³",
        accessor: "lastRecord.totalVol",
        className: "t-cell-1 text-right",
        disableFilters: true,
        Cell: ({ cell: { value } }) => {
          const showVol = value/10000
          const displayVol = showVol.toFixed(2)
          return (
            `${displayVol}`
          )
        }
      },
      {
        Header: "正转计数 m³",
        accessor: "lastRecord.fwdVol",
        className: "t-cell-4a text-right",
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ cell: { value } }) => {
          const showVol = value/10000
          const displayVol = showVol.toFixed(2)
          return (
            `${displayVol}`
          )
        }
      },
      {
        Header: "反转计数 m³",
        accessor: "lastRecord.revVol",
        className: "t-cell-4a text-right",
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ cell: { value } }) => {
          const showVol = value/10000
          const displayVol = showVol.toFixed(2)
          return (
            `${displayVol}`
          )
        }
      },
      {
        Header: "连续用水时间",
        accessor: "lastRecord.usageCt",
        className: "t-cell-4a text-right",
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: "外部干扰累计时间",
        accessor: "lastRecord.tamperCt",
        className: "t-cell-1 text-right",
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: "移除累计时间",
        accessor: "lastRecord.removeCt",
        className: "t-cell-1 text-right",
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: "爆管累计时间",
        accessor: "lastRecord.burstCt",
        className: "t-cell-1 text-right",
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: "温度",
        accessor: "lastRecord.temp",
        className: "t-cell-1 text-right",
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: "上报前电池电压",
        accessor: "lastRecord.preBatt",
        className: "t-cell-1 text-right",
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: "上报后电池电压",
        accessor: "lastRecord.postBatt",
        className: "t-cell-1 text-right",
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: "上一次上报结束",
        accessor: "lastRecord.lastIndex",
        className: "t-cell-1 text-right",
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: "上一次上报所用时间",
        accessor: "lastRecord.lastUpload",
        className: "t-cell-1 text-right",
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: "上一次上报后NB电源状态",
        accessor: "lastRecord.lastPwr",
        className: "t-cell-1 text-right",
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: "本次NB上报STEPS",
        accessor: "lastRecord.lastSteps",
        className: "t-cell-1 text-right",
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: "信号强度",
        accessor: "lastRecord.CQS",
        className: "t-cell-1 text-right",
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: "信号功率",
        accessor: "lastRecord.sigPwr",
        className: "t-cell-1 text-right",
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: "总功率",
        accessor: "lastRecord.totalPwr",
        className: "t-cell-1 text-right",
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: "终端发射功率",
        accessor: "lastRecord.txPwr",
        className: "t-cell-1 text-right",
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: "覆盖增强等级",
        accessor: "lastRecord.cellID",
        className: "t-cell-1 text-right",
        disableFilters: true
      },
      {
        Header: "信噪比",
        accessor: "lastRecord.SNR",
        className: "t-cell-1 text-right",
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: "中心频点",
        accessor: "lastRecord.EARFCN",
        className: "t-cell-1 text-right",
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: "物理小区标识",
        accessor: "lastRecord.PCI",
        className: "t-cell-1 text-right",
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: "参考信号接收质量",
        accessor: "lastRecord.RSRQ",
        className: "t-cell-1 text-right",
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: "参考信号接收功率",
        accessor: "lastRecord.RSRP",
        className: "t-cell-1 text-right",
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: "线圈L1电感动态数值",
        accessor: "lastRecord.lc1Delta",
        className: "t-cell-1 text-right",
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: "线圈L2电感动态数值率",
        accessor: "lastRecord.lc2Delta",
        className: "t-cell-1 text-right",
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: "线圈L3电感动态数值",
        accessor: "lastRecord.lc3Delta",
        className: "t-cell-1 text-right",
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: "最后更新",
        accessor: "lastRecord.timestampstring",
        className: "t-cell-6 text-left",
        disableFilters: true,
        Cell: ({ cell: { value } }) => {
          if (typeof value !== 'undefined') {
            const timeStampIndex = nthIndex(value, ":", 2);
            const displayTimeStamp = value.substring(0, timeStampIndex);
            return (
              `${displayTimeStamp}`
            )
          } else {
            return "";
          }
        }
      }
    ];
    
    return (
      <>
        <h2>设备详情</h2>
        <div className="App">
          <div className="demo-container">
            <div className="column-left">
              <GroupMenuNew
                groups={this.state.groups}
                loading={this.state.loadingGroups}
                parentCallback={this.handleCallback}
                initialGroup={
                  this.state.selectedGroup
                }
              />
            </div>
            <div className="column-right">
              <Alert color={this.state.alertType} isOpen={this.state.visible} toggle={() => this.onDismiss()}>
                {this.state.feedback}
              </Alert>
              <Container
                className="themed-container" fluid={true}
                style={{
                  "backgroundColor": "#f5f5f5",
                  "height": "100%"
                }}
              >
                <Row>
                  <Col>
                    <>
                      <span className="d-head1">小区名称</span>
                      <div className="d-body1">
                        <p>
                          {/* {this.state.selectedNodeName || this.state.selectedGroup} */}
                          {this.state.selectedNodeName}
                        </p>
                      </div>
                    </>
                  </Col>
                  {(this.state.meterCount > 0) && (
                    <>
                      <Col>
                        <span className="d-head1">水表总数</span>
                        <div className="d-body1">{this.state.selectedGroupInfo.total_device}</div>
                      </Col>
                      <Col>
                        <span className="d-head1">总用水量 m<sup>3</sup></span>
                        <div className="d-body1">{this.state.selectedGroupInfo.total_water_sum}</div>
                      </Col>
                      <Col>
                        <span className="d-head1">讯息总量</span>
                        <div className="d-body1">{this.state.selectedGroupInfo.total_msg}</div>
                      </Col>
                      <Col
                        xs="auto"
                        className="align-items-center"
                      >
                        <div style={{
                          'margin-top': '5px',
                          'text-align': 'left'
                        }}>
                          <span className="d-head3">正常: </span>
                          <span className="d-body3">{this.state.selectedGroupInfo.alarm016 + this.state.selectedGroupInfo.other16}</span>
                          <span className="d-head2">过流: </span>
                          <span className="d-body2" onClick={()=>this.props.history.push({
                                    pathname: "/dashboard/Alarm",
                                    state: {
                                        path: this.state.selectedGroup,
                                        onchoiceChange: [{"value": 1, "label": "过流" }]                                        
                                    }
                                  })}>         
                          <CountData onchoiceChange={this.state.alarmnew1} selectedGroup ={this.state.selectedGroup} />
                          </span>
                          {/* <span className="d-body2">{this.state.selectedGroupInfo.alarm1}</span> */}
                        </div>
                        {/* <div
                          style={{
                            'margin-top': '5px',
                            'text-align': 'left'
                          }}
                        >
                          <span className="d-head3">数据上传成功: </span>
                          <span className="d-body3">{this.state.selectedGroupInfo.total_device}</span>
                        </div> */}
                      </Col>
                      <Col
                        xs="auto"
                        className="align-items-center"
                      >
                        <div style={{
                          'margin-top': '5px',
                          'text-align': 'left'
                        }}>
                          <span className="d-head3" >干扰: </span>
                          <span className="d-body2" onClick={()=>this.props.history.push({
                                    pathname: "/dashboard/Alarm",
                                    state: {
                                        path: this.state.selectedGroup,
                                        onchoiceChange: [{"value": 4, "label": "干扰" }]
                                        
                                    }
                                  })}>
                            <CountData onchoiceChange={this.state.alarmnew4} selectedGroup ={this.state.selectedGroup} />
                          </span>
                          {/* <span className="d-body2">{this.state.selectedGroupInfo.alarm4}</span> */}

                          <span className="d-head2">泄漏: </span>
                          <span className="d-body2" onClick={()=>this.props.history.push({
                                    pathname: "/dashboard/Alarm",
                                    state: {
                                        path: this.state.selectedGroup,
                                        onchoiceChange: [{"value": 8, "label": "泄漏" }]                                        
                                    }
                                  })}>
                          <CountData onchoiceChange={this.state.alarmnew8} selectedGroup ={this.state.selectedGroup} />
                          </span>

                          {/* <span className="d-body2">{this.state.selectedGroupInfo.alarm8}</span> */}
                          <span className="d-head2">移除: </span>
                          <span className="d-body2" onClick={()=>this.props.history.push({
                                    pathname: "/dashboard/Alarm",
                                    state: {
                                        path: this.state.selectedGroup,
                                        onchoiceChange: [{"value": 2, "label": "移除" }]                                        
                                    }
                                  })}>                            
                          <CountData onchoiceChange={this.state.alarmnew2} selectedGroup ={this.state.selectedGroup} />
                          </span>
                          <span className="d-head2">低電: </span>
                          <span className="d-body2" onClick={()=>this.props.history.push({
                                    pathname: "/dashboard/Alarm",
                                    state: {
                                        path: this.state.selectedGroup,
                                        onchoiceChange: [{"value": 100, "label": "低電" }]                                      
                                    }
                                  })}>                            
                          <CountData onchoiceChange={this.state.alarmnew10} selectedGroup ={this.state.selectedGroup} />
                          </span>

                          {/* <span className="d-body3">{this.state.selectedGroupInfo.alarm2}</span> */}
                        </div>
                        {/* <div
                          style={{
                            'margin-top': '5px',
                            'text-align': 'left'
                          }}
                        >
                          <span
                            className="d-head3"
                          >
                            数据上传失败:
                          </span>
                          <span className="d-body2">
                            0
                          </span>
                        </div> */}
                        <div
                          style={{
                            'margin-top': '5px',
                            'text-align': 'left'
                          }}
                        >
                          <span
                            className="d-head3"
                          >
                            7天上传成功率:
                          </span>
                          <span className="d-body2">
                            <Success_rate selectedGroup ={this.state.selectedGroup} />
                          </span>
                        </div>
                      </Col>
                    </>
                  )}
                </Row>
                {(this.state.meterCount > 0) && (
                  <>
                    <Row style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                      <Col>
                        {(this.state.selectedGroup) && (
                          <PieChart data={this.state.selectedGroupAlarmData} title={alarmDataTitle} />
                        )}
                      </Col>
                      <Col>
                        {(this.state.selectedGroup) && (
                          <BarChart data={this.state.selectedGroupWaterUsage} title={waterUseTitle} />
                        )}
                      </Col>
                      <Col>
                        {(this.state.selectedGroup) && (
                          <BarChart data={this.state.selectedGroupWaterDiff} title={waterDiffTitle} />
                        )}
                      </Col>
                    </Row>
                  </>
                )}
                <QueryClientProvider client={queryClient}>                  
                {localStorage.getItem("userrole").includes("debug") ?
                    <MeterTable
                      columns={listHeaderDebug}
                      selectedGroup={this.state.selectedGroup}
                      lastTargetFilter={this.state.lastTargetFilter}
                      lastTargetFilterValue={this.state.lastTargetFilterValue}
                      parentCallback={this.resetFilterCallback}
                      aboutCallback={this.handleDeviceCallback}
                    />
                : 
                    <MeterTable
                      columns={basicHeader}
                      selectedGroup={this.state.selectedGroup}
                      lastTargetFilter={this.state.lastTargetFilter}
                      lastTargetFilterValue={this.state.lastTargetFilterValue}
                      parentCallback={this.resetFilterCallback}
                      aboutCallback={this.handleDeviceCallback}
                    />
                }
                </QueryClientProvider>                    
              </Container>
            </div>
          </div>
        </div>
      </>
    )
  }

}

export default withRouter(IndexDashboard);
