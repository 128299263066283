import {useState, useEffect} from 'react';
//import { useHistory } from 'react-router-dom';
//import axios from 'axios';
import myAxiosInstance from '../utils/axios-intercept';
import * as myConstClass from '../utils/fileWithConstants';

const useAsyncRequest = (amount) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {

            try {
                setLoading(true);
                
                const user_token = localStorage.getItem("token")
                const api_host = process.env.REACT_APP_API_URL || myConstClass.REACT_APP_API_URL;
                const uri = `${api_host}/hierarchy/entities/users`;
                const encodedURI = encodeURI(uri);
                
                console.log("encoded URI:", encodedURI)
                console.log("decoded URI:", decodeURI(encodedURI))                
                
                await myAxiosInstance.get(
                    encodedURI, {
                        headers: {
                            'Authorization': `ApiKey ${user_token}`
                        },
                    }
                )
                .then ((res) => {
                    console.log("response from get:", res)
                    setData(res.data, setLoading(false));
                }
                )
                .catch ((e) => {
                    setData(null, setLoading(false));
                });
            } catch (err) {
                console.warn("Something went wrong fetching the data...", err);
                setLoading(false);
            }
        }

        if (amount) {
            fetchData(amount);
        }

    },[amount]);

    console.log("return:", data, ' ', loading)
    return [data, loading];
}

export default useAsyncRequest;