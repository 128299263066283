import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Redirect, Switch, Route } from "react-router-dom";
import { withRouter } from "react-router";
import "./Dashboard.css";
import IndexUser from "./IndexUser";
import IndexDashboard from "./IndexDashboard";
import FactoryDashboard from "./FactoryDashboard";
import WaterBill from "./WaterBill";
import WaterBill_day_Month from "./WaterBill_day_Month"
import ModifyGroup from "./GroupComponent/ModifyGroup";
import GroupTree from "./GroupComponent/GroupTree";
import Updata from "./GroupComponent/Updata"
import FaultDevices from "./GroupComponent/FaultDevices";
import UpdUserPwd from "./UserComponent/UpdUserPwd";
import UpdateLog from "./GroupComponent/UpdateLog";
import NotFound from "./404";
import axios from 'axios';
import FactoryDashboard_reg from './FactoryDashboard_reg';
import FactoryDashboard_ctcreg from './FactoryDashboard_ctcreg';
import Meteralarm from "./AlarmComponent/Alarmselect";
import * as myConstClass from './utils/fileWithConstants';

// import Web_Serial_API_test from "./Web_Serial_API_test";

import {
  Collapse,
  NavbarToggler,
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem
} from "reactstrap";

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.toggleGroupDropDown = this.toggleGroupDropDown.bind(this);

    this.state = {
      isOpen: false,
      islogout: false,
      dropdownOpen: false,
      toplevel: false,
      loginMsg: "Please log in with a valid username and password."
    };
  }

  async componentDidMount() {
    var rootpath = localStorage.getItem("rootpath")
    var watersub = localStorage.getItem("watersub")
    var entities = rootpath.split("/")
    if (entities.length === 2 ) {
      this.setState({
        toplevel: true
      })
    }else if (watersub === "watersub"){
      this.setState({
        toplevel: true
      })
    }
  }

  signOut = () => {
    const api_host = process.env.REACT_APP_API_URL || myConstClass.REACT_APP_API_URL;
    const logout_URL = `${api_host}/logout/` + localStorage.getItem("username")
    const user_token = localStorage.getItem("token")

    try {
      axios.post(logout_URL, {}, {
        headers: {
          'Authorization': `ApiKey ${user_token}`
        },
      })
        .then(
          response => {
            console.log("Logout successful")
            // empty all items stored in localStorage
            localStorage.clear();
            this.setState({
              ...this.state,
              islogout: true,
              loginMsg: "您已成功登出."
            });
          }
        ).catch(error => {
          const errorObject = JSON.parse(JSON.stringify(error));
          console.log("Invalid logout credentials:", errorObject);
          this.setState({
            ...this.state,
            islogout: true,
            loginMsg: "您当前的会话已结束。请重新登录."
          });
          localStorage.clear();
        })
    } catch (e) {
      console.log(`Axios logout request failed: ${e}`)
      //alert('登出错误. 请重新登录.')
      this.setState({
        ...this.state,
        islogout: true,
        loginMsg: "登出错误. 请重新登录."
      });
      localStorage.clear();
    }

  };

  toggleGroupDropDown() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render() {
    if (this.state.islogout) {
      console.log("setting state as : ", this.state.loginMsg);
      return <Redirect to={{
        pathname: "/login",
        state: {
          infoMsg: this.state.loginMsg
        }
      }}
      />;
    }
    const { match } = this.props;
    console.log("Dashboard match:", match);


    return (
      <div>
        <Navbar color="faded" dark expand="lg" style={{ backgroundColor: '#676767' }}>
          <NavbarBrand href="/">
            {localStorage.getItem('companyname')}
          </NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse
            isOpen={this.state.isOpen}
            className="justify-content-between"
            navbar>
            <Nav navbar className="mr-right">
              <NavItem>
                <NavLink href={`${match.path}`} active>设备概况</NavLink>
              </NavItem>
              <UncontrolledDropdown nav inNavbar active>
                <DropdownToggle nav caret>
                  计量分析
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem href={`${match.path}/bill`} active>
                    收费资料导出
                  </DropdownItem>
                  <DropdownItem href={`${match.path}/billDayMonth`} active>
                    日月报表导出
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav inNavbar active>
                <DropdownToggle nav caret>
                  报警管理
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem href={`${match.path}/Alarm`} active>报警管理  </DropdownItem>
                  <DropdownItem href={`${match.path}/GroupComponent/FaultDevices`} active> 故障设备  </DropdownItem>

                  {/* <NavLink href={`${match.path}/Alarm`} active>报警管理</NavLink> */}
                </DropdownMenu>
              </UncontrolledDropdown>
              <NavItem>

              </NavItem>
              {!this.state.toplevel &&
                (<UncontrolledDropdown nav inNavbar active>
                  <DropdownToggle nav caret>
                    设备管理
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem href={`${match.path}/GroupComponent/ModifyGroup`} active>
                      设备管理
                    </DropdownItem>
                    <DropdownItem href={`${match.path}/GroupComponent/GroupTree`} active>
                      群组更新
                    </DropdownItem>
                    <DropdownItem href={`${match.path}/GroupComponent/Updata`} active>
                      数据更新
                    </DropdownItem>
                    <DropdownItem href={`${match.path}/GroupComponent/UpdateLog`} active>
                      设备管理记录
                    </DropdownItem>
                    {/* <DropdownItem href={`${match.path}/GroupComponent/FaultDevices`} active>
                    故障设备
                  </DropdownItem> */}
                  </DropdownMenu>
                </UncontrolledDropdown>)}
              {/* <UncontrolledDropdown nav inNavbar active>
                  <DropdownToggle nav caret>
                    test
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem href={`${match.path}/Web_Serial_API_test`} active>
                      Web_Serial_API_test
                    </DropdownItem> 
                  </DropdownMenu>
                </UncontrolledDropdown> */}
              {!this.state.toplevel && localStorage.getItem('userrole').includes('admin') && (
                <NavItem>
                  <NavLink href={`${match.path}/user`} active>用户管理</NavLink>
                </NavItem>
              )}
              {!this.state.toplevel && localStorage.getItem('userrole').includes('debug') && (
                <UncontrolledDropdown nav inNavbar active>
                  <DropdownToggle nav caret>
                    工厂工具
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem href={`${match.path}/FactoryDashboard`} active>
                      水表查索
                    </DropdownItem> <DropdownItem href={`${match.path}/FactoryDashboard_reg`} active>
                      CMHK水表导入
                    </DropdownItem>
                    <DropdownItem href={`${match.path}/FactoryDashboard_ctcreg`} active>
                      CTC水表导入
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
            </Nav>
            <Nav navbar style={{ paddingTop: '0.25rem', paddingRight: '1.6rem', backgroundColor: '#676767' }}>
              <UncontrolledDropdown nav inNavbar active>
                <DropdownToggle nav caret>
                  {`您好! ${localStorage.getItem("displayname")}`}
                </DropdownToggle>
                <DropdownMenu style={{ marginTop: '0' }}>
                  <DropdownItem
                    href={`${match.path}/UserComponent/UpdUserPwd`}
                    active
                  >
                    修改密码
                  </DropdownItem>
                  <DropdownItem
                    onClick={this.signOut}
                    active
                  >
                    登出
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Navbar>
        <Switch>
          <Route exact path={`${match.path}/Alarm`}>
            <Meteralarm />
          </Route>
          <Route exact path={`${match.path}/FactoryDashboard`}>
            <FactoryDashboard />
          </Route>
          {/* <Route exact path={`${match.path}/Web_Serial_API_test`}>
            <Web_Serial_API_test />
          </Route> */}
          <Route exact path={`${match.path}/FactoryDashboard_reg`}>
            <FactoryDashboard_reg />
          </Route>
          <Route exact path={`${match.path}/FactoryDashboard_ctcreg`}>
            <FactoryDashboard_ctcreg />
          </Route>

          <Route path={`${match.path}/user`}>
            <IndexUser />
          </Route>
          <Route path={`${match.path}/GroupComponent/ModifyGroup`}>
            <ModifyGroup />
          </Route>
          <Route path={`${match.path}/GroupComponent/GroupTree`}>
            <GroupTree />
          </Route>
          <Route path={`${match.path}/GroupComponent/Updata`}>
            <Updata />
          </Route>
          <Route path={`${match.path}/GroupComponent/UpdateLog`}>
            <UpdateLog />
          </Route>
          <Route path={`${match.path}/GroupComponent/FaultDevices`}>
            <FaultDevices />
          </Route>
          <Route exact path={`${match.path}/bill`}>
            <WaterBill />
          </Route>
          <Route exact path={`${match.path}/billDayMonth`}>
            <WaterBill_day_Month />
          </Route>
          <Route exact path={`${match.path}/UserComponent/UpdUserPwd`}>
            <UpdUserPwd currentUser={localStorage.getItem("username")} />
          </Route>
          <Route exact path={`${match.path}`}>
            <IndexDashboard />
          </Route>
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </div>
    );
  }
}

export default withRouter(Dashboard);