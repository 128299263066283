import React, { useEffect, useState } from 'react';
import { useTable, usePagination } from 'react-table';
import * as myConstClass from '../utils/fileWithConstants';
import classNames from "classnames";
import loaderimage from "./assets/loader-table.gif";
import "../TableComponent/TableDevice.css";
import myAxiosInstance from '../utils/axios-intercept';
import {
    Table,
    Pagination,
    PaginationItem,
    PaginationLink,
} from "reactstrap";


const initialState = {
    queryPageIndex: 0,
    queryPageSize: myConstClass.PAGESIZE,
    totalCount: null,
};

const PAGE_CHANGED = 'PAGE_CHANGED';
const PAGE_SIZE_CHANGED = 'PAGE_SIZE_CHANGED';
const TOTAL_COUNT_CHANGED = 'TOTAL_COUNT_CHANGED';

const reducer = (state, { type, payload }) => {
    switch (type) {
        case PAGE_CHANGED:
            return {
                ...state,
                queryPageIndex: payload,
            };
        case PAGE_SIZE_CHANGED:
            return {
                ...state,
                queryPageSize: payload,
            };
        case TOTAL_COUNT_CHANGED:
            return {
                ...state,
                totalCount: payload,
            };
        default:
            throw new Error(`Unhandled action type: ${type}`);
    }
};

const FaultDevicesTable = ({ columns, path, timestamp, outdatetime }) => {

    const [{ queryPageIndex, queryPageSize }, dispatch] =
        React.useReducer(reducer, initialState);

    const [token] = useState(localStorage.getItem("token"));
    const [api_host] = useState(process.env.REACT_APP_API_URL || myConstClass.REACT_APP_API_URL);

    const [isLoading] = useState(false)
    const [isSuccess, setisSuccess] = useState(false)
    const [data, setdata] = useState([])
    const [count, setcount] = useState()

    const [canPreviousPage, setcanPreviousPage] = useState(false)
    const [canNextPage, setcanNextPage] = useState(true)

    const [dataCount, setdataCount] = useState(0)
    const [fristsortValue, setfristsortValue] = useState()

    useEffect(() => {
        var uri = `${api_host}/factory/devices/count?path=${path}&outdatatime=${outdatetime}`;
        var encodedURI = encodeURI(uri);
        console.log("uri:", uri)
        console.log("encodeURI:", encodedURI)

        myAxiosInstance(encodedURI, {
            method: 'GET',
            headers: {
                'Authorization': `ApiKey ${token}`,
                'Content-Type': 'application/json'
            },
        })
            .then(res => {
                console.log("response: ",res.data);
                setcount(res.data)
            })
            .catch(err => {
                console.log("error： ", err.statustext);
            })
    },[path, api_host, token, timestamp])

    useEffect(() => {
        var uri = `${api_host}/devices/searchafter?path=${path}&limit=${queryPageSize}&range_field=timestamp&range_end=${timestamp}`;
        var encodedURI = encodeURI(uri);
        console.log("uri:", uri)
        console.log("encodeURI:", encodedURI)

        myAxiosInstance(encodedURI, {
            method: 'GET',
            headers: {
                'Authorization': `ApiKey ${token}`,
                'Content-Type': 'application/json'
            },
        })
            .then(res => {
                // console.log("response: ",res);
                setdata(res.data);
                setdataCount(res.data.length);
                setfristsortValue(res.data[0]["sort"][0])
                setisSuccess(true);
                setcanPreviousPage(false)
                if (res.data.length < queryPageSize) {
                    setcanNextPage(false)
                } else {
                    setcanNextPage(true)
                }
            })
            .catch(err => {
                console.log("error： ", err.statustext);
            })
    }, [path, queryPageSize, api_host, token, timestamp])

    function gotoPage() {
        var uri = `${api_host}/devices/searchafter?path=${path}&limit=${queryPageSize}&range_field=timestamp&range_end=${timestamp}`;
        var encodedURI = encodeURI(uri);
        console.log("uri:", uri)
        console.log("encodeURI:", encodedURI)

        myAxiosInstance(encodedURI, {
            method: 'GET',
            headers: {
                'Authorization': `ApiKey ${token}`,
                'Content-Type': 'application/json'
            },
        })
            .then(res => {
                // console.log("response: ",res);
                setdata(res.data);
                setdataCount(res.data.length);
                setisSuccess(true);
                setcanNextPage(true)
                setcanPreviousPage(false)
            })
            .catch(err => {
                console.log("error： ", err.statustext);
            })
    }

    function previousPage() {
        var uri = `${api_host}/devices/searchafter?path=${path}&searchafterValue=${data[0]["sort"][0]}&limit=${queryPageSize}&range_field=timestamp&range_end=${timestamp}&order=desc`;
        var encodedURI = encodeURI(uri);
        console.log("uri:", uri)
        console.log("encodeURI:", encodedURI)

        myAxiosInstance(encodedURI, {
            method: 'GET',
            headers: {
                'Authorization': `ApiKey ${token}`,
                'Content-Type': 'application/json'
            },
        })
            .then(res => {
                //console.log("response: ",res);
                setdata(res.data.reverse());
                setdataCount(res.data.length);
                setcanNextPage(true)
                setisSuccess(true);
                setcanNextPage(true)
                if (res.data[0]["sort"][0] === fristsortValue) {
                    setcanPreviousPage(false)
                }
            })
            .catch(err => {
                console.log("error： ", err.statustext);
            })
    }

    function nextPage() {
        var uri = `${api_host}/devices/searchafter?path=${path}&searchafterValue=${data[dataCount - 1]["sort"][0]}&limit=${queryPageSize}&range_field=timestamp&range_end=${timestamp}&order=asc`;
        var encodedURI = encodeURI(uri);
        console.log("uri:", uri)
        console.log("encodeURI:", encodedURI)

        myAxiosInstance(encodedURI, {
            method: 'GET',
            headers: {
                'Authorization': `ApiKey ${token}`,
                'Content-Type': 'application/json'
            },
        })
            .then(res => {
                //console.log("response: ",res);
                setdata(res.data);
                setdataCount(res.data.length);
                setcanPreviousPage(true)
                setisSuccess(true);
                if (res.data.length < queryPageSize) {
                    setcanNextPage(false)
                }
            })
            .catch(err => {
                console.log("error： ", err.statustext);
            })
    }

    useEffect(() => {
        console.log('data from axios:', data);
        console.log('data count: ', count)
    }, [data,count])



    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        // canPreviousPage,
        // canNextPage,
        // pageOptions,
        // pageCount,
        // gotoPage,
        // nextPage,
        // previousPage,
        setPageSize,
        // Get the state from the instance
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            //data: isSuccess ? trimData(data.results) : [],
            data: isSuccess ? data : [],
            initialState: {
                pageIndex: queryPageIndex,
                pageSize: queryPageSize,
            },
            manualPagination: true, // Tell the usePagination
            // hook that we'll handle our own data fetching
            // This means we'll also have to provide our own
            // pageCount.
        },
        usePagination
    );

    useEffect(() => {
        dispatch({ type: PAGE_CHANGED, payload: pageIndex });
    }, [pageIndex]);

    useEffect(() => {
        dispatch({ type: PAGE_SIZE_CHANGED, payload: pageSize });
    }, [pageSize]);

    useEffect(() => {
        console.log('selected path updated', path);
    }, [path])

    if (isLoading) {
        return <img src={loaderimage} alt="载入中..." />;
    }

    return (
        <>
            {isSuccess ? (
                <>
                    <h4>总数: {count}</h4>
                    <Table {...getTableProps()} hover bordered responsive>
                        <thead>
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th {...column.getHeaderProps()}>
                                            {column.render('Header')}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        {page.length === 0 ?
                            <tbody>
                                <tr>
                                    <td colSpan="10000" className="text-left">
                                        <p style={{
                                            "textAlign": "left"
                                        }}
                                        >
                                            没有数据显示
                                        </p>
                                    </td>
                                </tr>
                            </tbody>
                            :
                            <tbody {...getTableBodyProps()}>
                                {page.map((row) => {
                                    prepareRow(row);
                                    return (
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map((cell) => (
                                                <td {...cell.getCellProps({
                                                    className: cell.column.className
                                                }
                                                )}>
                                                    {cell.render('Cell')}
                                                </td>
                                            ))}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        }
                    </Table>
                    {page.length > 0 && (
                        <div className={classNames("div-pagination", { "d-none": isLoading })}>
                            <div className="div-pagination-2">
                                <div className="div-pagination-2-2">
                                    每页显示{" "}
                                    <select
                                        className="selectan"
                                        value={pageSize}
                                        onChange={(e) => {
                                            setPageSize(Number(e.target.value));
                                        }}
                                    >
                                        {[10, 20, 30, 50, 100].map((pageSize) => (
                                            <option key={pageSize} value={pageSize}>
                                                {pageSize}
                                            </option>
                                        ))}
                                    </select>{" "}
                                    记录
                                </div>
                            </div>

                            <div className="div-pagination-1">
                                <Pagination className="pagina">
                                    <PaginationItem disabled={!canPreviousPage}>
                                        <PaginationLink onClick={() => gotoPage()}>
                                            {"<<"}
                                        </PaginationLink>
                                    </PaginationItem>
                                    <PaginationItem disabled={!canPreviousPage}>
                                        <PaginationLink onClick={() => previousPage()}>
                                            {"<"}
                                        </PaginationLink>
                                    </PaginationItem>
                                    <PaginationItem disabled={!canNextPage}>
                                        <PaginationLink onClick={() => nextPage()}>
                                            {">"}
                                        </PaginationLink>
                                    </PaginationItem>
                                </Pagination>
                            </div>
                        </div>
                    )}
                </>
            ) : <p style={
                {
                    "textAlign": "left"
                }
            }>没有数据显示</p>}
        </>
    );
}

export default FaultDevicesTable;