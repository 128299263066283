import React, { PureComponent } from "react";
import Creatable from 'react-select/creatable';
import { withRouter } from "react-router";
import {
  Container,
  Row,
  Col,
  Alert,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Button,
  Label
} from "reactstrap";
import './styles.css';
import './TableComponent/Table.css';
import myAxiosInstance from "./utils/axios-intercept";
import * as myConstClass from './utils/fileWithConstants';
import { nthIndex } from './utils/commonFunc';
import { QueryClientProvider, QueryClient } from 'react-query';
import AddRemoveFactory from './PagingComponent/AddRemoveFactory';

const listHeaderDebug = [
  {
    Header: "水表SN",
    className: "t-cell-1 text-left",
    accessor: "deviceName",
    disableFilters: true
  },
  {
    Header: "IMEI",
    accessor: "imei",
    className: "t-cell-1 text-left"
  },
  {
    Header: "水表号码",
    className: "t-cell-1 text-left",
    accessor: "deviceId"
  },
  {
    Header: "最后更新",
    accessor: "lastRecord.timestampstring",
    className: "t-cell-6 text-left",
    Cell: ({ cell: { value } }) => {
      if (typeof value !== 'undefined') {
        const timeStampIndex = nthIndex(value, ":", 2);
        const displayTimeStamp = value.substring(0, timeStampIndex);
        return (
          `${displayTimeStamp}`
        )
      } else {
        return "";
      }
    }
  },
  {
    Header: "总用水 m³",
    accessor: "lastRecord.totalVol",
    className: "t-cell-1 text-right"
  },
  {
    Header: "中国移动号码",
    accessor: "oneNetDevieId",
    className: "t-cell-1 text-left"
  },
  {
    Header: "客户地址",
    accessor: "MeterLocation",
    className: "t-cell-5a text-left",
    disableFilters: true
  },
  {
    Header: "水表口径 (DN)",
    accessor: "MeterBodyDiameter",
    className: "t-cell-2 text-left"
  },
  {
    Header: "基表号",
    accessor: "MeterBodyId",
    className: "t-cell-1 text-left"
  },
  {
    Header: "数据包序列号",
    accessor: "lastRecord.seqNum",
    className: "t-cell-1 text-right"
  },
  {
    Header: "成功上报计数",
    accessor: "lastRecord.okCount",
    className: "t-cell-1 text-right"
  },
  {
    Header: "正转计数 m³",
    accessor: "lastRecord.fwdVol",
    className: "t-cell-4a text-right"
  },
  {
    Header: "反转计数 m³",
    accessor: "lastRecord.revVol",
    className: "t-cell-4a text-right"
  },
  {
    Header: "连续用水时间",
    accessor: "lastRecord.usageCt",
    className: "t-cell-4a text-right"
  },
  {
    Header: "外部干扰累计时间",
    accessor: "lastRecord.tamperCt",
    className: "t-cell-1 text-right"
  },
  {
    Header: "移除累计时间",
    accessor: "lastRecord.removeCt",
    className: "t-cell-1 text-right"
  },
  {
    Header: "爆管累计时间",
    accessor: "lastRecord.burstCt",
    className: "t-cell-1 text-right"
  },
  {
    Header: "温度",
    accessor: "lastRecord.temp",
    className: "t-cell-1 text-right"
  },
  {
    Header: "上报前电池电压",
    accessor: "lastRecord.preBatt",
    className: "t-cell-1 text-right"
  },
  {
    Header: "上报后电池电压",
    accessor: "lastRecord.postBatt",
    className: "t-cell-1 text-right"
  },
  {
    Header: "上一次上报结束",
    accessor: "lastRecord.lastIndex",
    className: "t-cell-1 text-right"
  },
  {
    Header: "上一次上报所用时间",
    accessor: "lastRecord.lastUpload",
    className: "t-cell-1 text-right"
  },
  {
    Header: "上一次上报后NB电源状态",
    accessor: "lastRecord.lastPwr",
    className: "t-cell-1 text-right"
  },
  {
    Header: "本次NB上报STEPS",
    accessor: "lastRecord.lastSteps",
    className: "t-cell-1 text-right"
  },
  {
    Header: "信号强度",
    accessor: "lastRecord.CQS",
    className: "t-cell-1 text-right"
  },
  {
    Header: "信号功率",
    accessor: "lastRecord.sigPwr",
    className: "t-cell-1 text-right"
  },
  {
    Header: "总功率",
    accessor: "lastRecord.totalPwr",
    className: "t-cell-1 text-right"
  },
  {
    Header: "终端发射功率",
    accessor: "lastRecord.txPwr",
    className: "t-cell-1 text-right"
  },
  {
    Header: "基站小区标识",
    accessor: "lastRecord.cellID",
    className: "t-cell-1 text-right"
  },
  {
    Header: "信噪比",
    accessor: "lastRecord.SNR",
    className: "t-cell-1 text-right"
  },
  {
    Header: "中心频点",
    accessor: "lastRecord.EARFCN",
    className: "t-cell-1 text-right"
  },
  {
    Header: "物理小区标识",
    accessor: "lastRecord.PCI",
    className: "t-cell-1 text-right"
  },
  {
    Header: "参考信号接收质量",
    accessor: "lastRecord.RSRQ",
    className: "t-cell-1 text-right"
  },
  {
    Header: "参考信号接收功率",
    accessor: "lastRecord.RSRP",
    className: "t-cell-1 text-right"
  },
  {
    Header: "线圈L1电感动态数值",
    accessor: "lastRecord.lc1Delta",
    className: "t-cell-1 text-right"
  },
  {
    Header: "线圈L2电感动态数值率",
    accessor: "lastRecord.lc2Delta",
    className: "t-cell-1 text-right"
  },
  {
    Header: "线圈L3电感动态数值",
    accessor: "lastRecord.lc3Delta",
    className: "t-cell-1 text-right"
  }
];

const queryClient = new QueryClient();

class FactoryDashboard_reg extends PureComponent {
  

  constructor(props) {
    super(props);

    console.log("props in constructor:", props);

    this.state = {
      errorMessage: "",
      batchList: [],
      imeiValue: "",
      startValue: "",
      endValue: "",
      teleProvider: "",
      searchField: "deviceName",
      searchBatch: "",
      function: "search",
      search: true,
      add: false,
      remove: false,
      isLoggedIn: false,
      islogout: false,
      isSearchOn: false,
      visible: false,
      feedback: "",
      alertType: "info"
    };
  }

  onDismiss = () => {
    this.setState({ visible: false });
  }

  handleAxiosError = (e) => {
    this.setState({ alertType: "danger" });
    this.setState(
      {
        feedback: `Axios user request failed: ${e}`
      }
    );
    this.setState(
      {
        visible: true
      }
    );
  }

  getJsonField = (data, field) => {
    var ret = []
    console.log("getting field %s ...", field)
    for (var i = 0; i < data.length; i++) {
      //console.log("getting field %s %d %s", field, i, data[i])
      ret[i] = data[i][field]
    }
    return ret;
  }

  handleAxiosError = (e) => {
    this.setState({ alertType: "danger" });
    this.setState({ feedback: `Axios user request failed: ${e}` });
    this.setState(
      {
        visible: true
      }
    );
  }

  callbackFunction = (childData) => {
    console.log("childData ", childData);
    //TODO: clear the input field for next input
    if (childData === 'imei') {
      document.getElementById("imeiValue").value = '';
    } else if (childData === 'deviceName') {
      document.getElementById("startValue").value = '';
      document.getElementById("endValue").value = '';
    } else if (childData === 'deviceId') {
      document.getElementById("startValue").value = '';
      document.getElementById("endValue").value = '';
    }
    /*this.setState({
      searchValue: "",
      feedback: "",
      visible: false,
      isSearchOn: false
    });*/
  }

  handleKeyPressed = (e) => {
    //console.log("handleKeyPressed ",e.key);
    if (e.key === "Enter") {
      console.log("handleKeyPressed ENTER");
      this.handleSearchClick(e);
    }
  }
  
  handleBatchChange = (e)=>{
    console.log(e)
    if (e !== null) {
      console.log("you entered:", 'batch', "into", e.value);
      this.setState({
        searchBatch: e,
        visible: false,
        isSearchOn: false,
        errorMessage: ""
      })
    }
    else {
      console.log("you entered:", 'batch', "into", "");
      this.setState({
        searchBatch: "",
        visible: false,
        isSearchOn: false,
        errorMessage: ""
      })
    }
  }
  handleSearchValue = (e) => {
    console.log("you entered:", e.target.value, "into", e.target.name);
    if (e.target.name === "startValue") {
      this.setState({
        startValue: Number(e.target.value.trim()),
        imeiValue: "",
        feedback: "",
        visible: false,
        isSearchOn: false,
        errorMessage: ""
      })
    } else if (e.target.name === "endValue") {
      this.setState({
        endValue: Number(e.target.value.trim()),
        imeiValue: "",
        feedback: "",
        visible: false,
        isSearchOn: false,
        errorMessage: ""
      })
    } else if (e.target.name === "searchBatch") {
      this.setState({
        searchBatch: e.target.value.trim(),
        feedback: "",
        visible: false,
        isSearchOn: false,
        errorMessage: ""
      })
    } else if (e.target.name === "imeiValue") {
      this.setState({
        imeiValue: Number(e.target.value.trim()),
        startValue: "",
        endValue: "",
        feedback: "",
        visible: false,
        isSearchOn: false,
        errorMessage: ""
      })
    }
  }

  handleSearchSelect = (e) => {
    console.log("Switching", e.target.name, "to option:", e.target.value);
    console.log("here is the event:", e);
    if (e.target.name === "searchField") {
      this.setState({
        searchField: e.target.value,
        feedback: "",
        visible: false,
        isSearchOn: false,
        errorMessage: ""
      })
    } else if (e.target.name === "function") {
      this.setState({
        function: e.target.value,
        feedback: "",
        visible: false,
        isSearchOn: false,
        errorMessage: ""
      })
    } else if (e.target.name === "teleProvider") {
      this.setState({
        teleProvider: e.target.value,
        feedback: "",
        visible: false,
        isSearchOn: false,
        errorMessage: ""
      })
    }
  }

  handleSearchClick = (e) => {
    console.log("Clicked search with criteria:", this.state.startValue, this.state.endValue, this.state.searchField, this.state.searchBatch);
    if (this.state.function === "add") {
      if (this.state.startValue !== "" && this.state.endValue !== "" && this.state.searchBatch !== "" && this.state.searchField === "deviceName") {
        if (this.state.endValue >= this.state.startValue && Number.isInteger(this.state.startValue) && Number.isInteger(this.state.endValue)) {
          this.setState({
            isSearchOn: true
          })
        }
        else if (!Number.isInteger(this.state.startValue) || !Number.isInteger(this.state.endValue)) {
          this.setState({ alertType: "danger" });
          this.setState({ feedback: `SN值必須是數字` });
          this.setState({
            visible: true,
            isSearchOn: false
          })
        }
        else if (this.state.endValue < this.state.startValue) {
          this.setState({ alertType: "danger" });
          this.setState({ feedback: `開始SN值大於結束SN值` });
          this.setState({
            visible: true,
            isSearchOn: false
          })
        }
      }
      //device ID
      else if (this.state.startValue !== "" && this.state.endValue !== "" && this.state.searchBatch !== "" && this.state.searchField === "deviceId") {
        if (this.state.endValue >= this.state.startValue && Number.isInteger(this.state.startValue) && Number.isInteger(this.state.endValue)) {
          this.setState({
            isSearchOn: true
          })
        }
        else if (!Number.isInteger(this.state.startValue) || !Number.isInteger(this.state.endValue)) {
          this.setState({ alertType: "danger" });
          this.setState({ feedback: `Id 值必須是數字` });
          this.setState({
            visible: true,
            isSearchOn: false
          })
        }
        else if (this.state.endValue < this.state.startValue) {
          this.setState({ alertType: "danger" });
          this.setState({ feedback: `開始Id值大於結束Id值` });
          this.setState({
            visible: true,
            isSearchOn: false
          })
        }
      }
      else if (this.state.imeiValue !== "" && this.state.searchBatch !== "" && this.state.searchField === "imei") {
        if (Number.isInteger(this.state.imeiValue)) {
          this.setState({
            isSearchOn: true
          })
        }
        else if (!Number.isInteger(this.state.imeiValue)) {
          this.setState({ alertType: "danger" });
          this.setState({ feedback: `imei值必須是數字` });
          this.setState({
            visible: true,
            isSearchOn: false
          })
        }
      }
      else {
        this.setState({ alertType: "danger" });
        this.setState({ feedback: `必须选择导入值或批次和功能` });
        this.setState(
          {
            visible: true
          }
        );
      }
    }
    else if (this.state.function === "remove") {
      if (this.state.startValue !== "" && this.state.endValue !==""  && this.state.searchField === "deviceName") {
        if (this.state.endValue >= this.state.startValue && Number.isInteger(this.state.startValue) && Number.isInteger(this.state.endValue)) {
          this.setState({
           isSearchOn: true
          })
        }
        else if (!Number.isInteger(this.state.startValue) || !Number.isInteger(this.state.endValue)) {
          this.setState({ alertType: "danger" });
          this.setState({ feedback: `SN值必須是數字` });
          this.setState({
            visible: true,
            isSearchOn: false
          })
        }
        else if(this.state.endValue < this.state.startValue){
          this.setState({ alertType: "danger" });
          this.setState({ feedback: `開始SN值大於結束SN值` });
          this.setState({
            visible: true,
            isSearchOn: false
          })
        }
      } 
      //device ID
      else if (this.state.startValue !== "" && this.state.endValue !=="" && this.state.searchField === "deviceId") {
        if (this.state.endValue >= this.state.startValue && Number.isInteger(this.state.startValue) && Number.isInteger(this.state.endValue)) {
          this.setState({
           isSearchOn: true
          })
        }
        else if (!Number.isInteger(this.state.startValue) || !Number.isInteger(this.state.endValue)) {
          this.setState({ alertType: "danger" });
          this.setState({ feedback: `Id 值必須是數字` });
          this.setState({
            visible: true,
            isSearchOn: false
          })
        }
        else if(this.state.endValue < this.state.startValue){
          this.setState({ alertType: "danger" });
          this.setState({ feedback: `開始Id值大於結束Id值` });
          this.setState({
            visible: true,
            isSearchOn: false
          })
        }
      } 
      else if (this.state.imeiValue!== ""  && this.state.searchField === "imei") {
        if (Number.isInteger(this.state.imeiValue)) {
          this.setState({
           isSearchOn: true
          })
        }
        else if (!Number.isInteger(this.state.imeiValue)) {
          this.setState({ alertType: "danger" });
          this.setState({ feedback: `imei值必須是數字` });
          this.setState({
            visible: true,
            isSearchOn: false
          })
        }
      }
      else {
        this.setState({ alertType: "danger" });
        this.setState({ feedback: `必须选择导入值和功能` });
        this.setState(
          {
            visible: true
          }
        );      
      }
    }
    else {
      this.setState({ alertType: "danger" });
      this.setState({ feedback: `必须选择导入值和功能` });
      this.setState(
        {
          visible: true
        }
      );      
    }
  }

  async componentDidMount() {

    const token = localStorage.getItem("token");
    const api_host = process.env.REACT_APP_API_URL || myConstClass.REACT_APP_API_URL;

    var uri = `${api_host}/factory/batches`;
    var encodedURI = encodeURI(uri);

    await myAxiosInstance.get(encodedURI, {
      headers: {
        'Authorization': `ApiKey ${token}`,
        'Content-Type': 'application/json'
      }
    })
    .then(res => {
      const batchList = Object.keys(res.data).map((key, index) => {
        return {label : key, value : key}                     
      })
      console.log('uri to get batchList:', uri);
      console.log("Here is the batchList:", batchList);
      this.setState({batchList: batchList});
    })
    .catch ( error => {
      const errorObject=JSON.parse(JSON.stringify(error));
      console.log(errorObject);
    })

  }

  render() {

    let input_Field
    let input_function

    if (this.state.searchField === "deviceName") {
      input_Field =
        [
          <Input autoFocus
            type="text"
            name="startValue"
            id="startValue"
            onChange={this.handleSearchValue}
            onKeyPress={this.handleKeyPressed}
            placeholder="输入開始SN值"
            style={{ margin: '0' }}
          />,
          <Label sm={1}>to</Label>,
          <Input autoFocus
            type="text"
            name="endValue"
            id="endValue"
            onChange={this.handleSearchValue}
            onKeyPress={this.handleKeyPressed}
            placeholder="输入結束SN值"
            style={{ margin: '0' }}
          />
        ]
      input_function = [
        <option value="">点击选择功能</option>,
        <option value="add">建立</option>]
    }
    //device Id
    else if (this.state.searchField === "deviceId") {
      input_Field =
        [
          <Input autoFocus
            type="text"
            name="startValue"
            id="startValue"
            onChange={this.handleSearchValue}
            onKeyPress={this.handleKeyPressed}
            placeholder="输入開始Id值"
            style={{ margin: '0' }}
          />,
          <Label sm={1}>to</Label>,
          <Input autoFocus
            type="text"
            name="endValue"
            id="endValue"
            onChange={this.handleSearchValue}
            onKeyPress={this.handleKeyPressed}
            placeholder="输入結束Id值"
            style={{ margin: '0' }}
          />
        ]
      input_function = [
        <option value="">点击选择功能</option>,
        <option value="add">建立</option>]
    }
    else if (this.state.searchField === "imei") {
      input_Field = <Input autoFocus
        type="text"
        name="imeiValue"
        id="imeiValue"
        onChange={this.handleSearchValue}
        onKeyPress={this.handleKeyPressed}
        placeholder="输入imei值"
        style={{ margin: '0' }}
      />
      input_function = [
        <option value="">点击选择功能</option>,
        <option value="add">建立</option>,
        <option value="remove">刪除</option>]
    }

    return (
      <>
        <h2>工厂水表导入</h2>
        <div className="App">
          <Alert color={this.state.alertType} isOpen={this.state.visible} toggle={() => this.onDismiss()}>
            {this.state.feedback}
          </Alert>
          <Container
            className="themed-container" fluid={true}
            style={{
              "background-color": "#f5f5f5",
              "height": "100%"
            }}
          >
            <>
              <Row>
                <Col xs="6">
                  <InputGroup>
                    <InputGroupAddon
                      addonType="prepend"
                    >
                      <Input
                        type="select"
                        name="searchField"
                        id="searchField"
                        value={this.state.searchField}
                        onChange={this.handleSearchSelect}
                      >
                        <option value="imei">IMEI</option>
                        <option value="deviceName">SN </option>
                        <option value="deviceId">DeviceId </option>
                      </Input>
                      <Label sm={2}>:</Label>
                    </InputGroupAddon>
                    {input_Field}
                  </InputGroup>
                </Col>
                {/* <Col xs="2">
                  <InputGroup>
                    <InputGroupAddon
                      addonType="prepend"
                    >
                      <InputGroupText>
                        批次:
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input autoFocus
                      type="text"
                      name="searchBatch"
                      id="searchBatch"
                      onChange={this.handleSearchValue}
                      onKeyPress={this.handleKeyPressed}
                      placeholder="输入批次"
                      style={{ margin: '0' }}
                    />
                  </InputGroup>
                </Col> */}
                <Col xs="2">
                  <InputGroup>
                    <InputGroupAddon
                      addonType="prepend"
                    >
                      <InputGroupText>
                        批次:
                      </InputGroupText>
                    </InputGroupAddon>
                    <div className="react-select form-control p-0">
                      <Creatable                      
                        name="searchBatch"
                        id="searchBatch"
                        placeholder="输入批次"
                        options = {this.state.batchList}
                        value = {this.state.searchBatch}
                        onChange = {(value) => this.handleBatchChange(value)}
                        isClearable
                        />    
                      </div>                
                  </InputGroup>
                </Col>
                <Col xs="2">
                  <InputGroup>
                    <InputGroupAddon
                      addonType="prepend"
                    >
                      <InputGroupText>
                        功能:
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="select"
                      name="function"
                      id="function"
                      value={this.state.function}
                      onChange={this.handleSearchSelect}
                    >
                      {input_function}
                    </Input>
                  </InputGroup>
                </Col>
                <Col xs="2">
                  <Button
                    color="primary"
                    size="sm"
                    style={
                      {
                        "padding": "7px",
                        "margin": "0"
                      }
                    }
                    onClick={this.handleSearchClick}
                  >
                    確定
                  </Button>{' '}
                </Col>
              </Row>
              <Row>
                <Col>&nbsp;</Col>
              </Row>
              {
                this.state.isSearchOn && this.state.function === 'remove' &&
                <QueryClientProvider client={queryClient}>
                  <AddRemoveFactory
                    field={this.state.searchField}
                    apifunction={this.state.function}
                    batch={this.state.searchBatch.value}
                    imei={this.state.imeiValue}
                    Value_start={this.state.startValue}
                    Value_end={this.state.endValue}
                    callbackParent={this.callbackFunction}
                    TeleProvider={this.state.teleProvider}
                  />
                </QueryClientProvider>
              }
              {
                this.state.isSearchOn && this.state.searchBatch && this.state.function === 'add' &&
                <QueryClientProvider client={queryClient}>
                  <AddRemoveFactory
                    field={this.state.searchField}
                    apifunction={this.state.function}
                    batch={this.state.searchBatch.value}
                    imei={this.state.imeiValue}
                    Value_start={this.state.startValue}
                    Value_end={this.state.endValue}
                    callbackParent={this.callbackFunction}
                    TeleProvider={this.state.teleProvider}
                  />
                </QueryClientProvider>
              }
              {this.state.errorMessage}
            </>
          </Container>
        </div>
      </>
    )
  }

}

export default withRouter(FactoryDashboard_reg);
