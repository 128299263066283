import React, { PureComponent } from "react";
import { withRouter } from "react-router";
import {
  Container,
  Row,
  Col,
  Alert,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Button
} from "reactstrap";
import './styles.css';
import './TableComponent/Table.css';
import myAxiosInstance from "./utils/axios-intercept";
import * as myConstClass from './utils/fileWithConstants';
import { nthIndex } from './utils/commonFunc';
import { QueryClientProvider, QueryClient } from 'react-query';
import MeterTableFactory from './PagingComponent/MeterTableFactory';


const queryClient = new QueryClient();

class FactoryDashboard extends PureComponent {

  constructor(props) {
    super(props);

    console.log("props in constructor:", props);

    this.state = {
      batchList: [],
      searchValue: "",
      searchField: "deviceName",
      searchBatch: "",
      meterDiamList: {},
      lastTargetFilter: "",
      lastTargetFilterValue: "",
      isLoggedIn: false,
      islogout: false,
      isSearchOn: false,
      visible: false,
      feedback: "",
      alertType: "info",
      outdatatime: ""
    };
  }

  onDismiss = () => {
    this.setState({ visible: false });
  }

  handleAxiosError = (e) => {
    this.setState({ alertType: "danger" });
    this.setState(
      {
        feedback: `Axios user request failed: ${e}`
      }
    );
    this.setState(
      {
        visible: true
      }
    );
  }

  getJsonField = (data, field) => {
    var ret = []
    console.log("getting field %s ...", field)
    for (var i = 0; i < data.length; i++) {
      //console.log("getting field %s %d %s", field, i, data[i])
      ret[i] = data[i][field]
    }
    return ret;
  }

  handleAxiosError = (e) => {
    this.setState({ alertType: "danger" });
    this.setState({ feedback: `Axios user request failed: ${e}` });
    this.setState(
      {
        visible: true
      }
    );
  }

  callbackFunction = (childData) => {
    console.log("childData ", childData);
    //TODO: clear the input field for next input
    document.getElementById("searchValue").value = '';
    /*this.setState({
      searchValue: "",
      feedback: "",
      visible: false,
      isSearchOn: false
    });*/
  }

  handleCheckbox = (e) => {
    console.log("handleCheckbox ", e);
    this.setState({
      outdatatime: !this.state.outdatatime,
      feedback: "",
      visible: false,
      isSearchOn: false
    })
  }

  handleKeyPressed = (e) => {
    //console.log("handleKeyPressed ",e.key);
    if (e.key === "Enter") {
      console.log("handleKeyPressed ENTER");
      this.handleSearchClick(e);
    }
  }

  handleSearchValue = (e) => {
    console.log("you entered:", e.target.value, "into", e.target.name);
    this.setState({
      searchValue: e.target.value.trim(),
      feedback: "",
      visible: false,
      isSearchOn: false
    })
  }

  handleSearchSelect = (e) => {
    console.log("Switching", e.target.name, "to option:", e.target.value);
    console.log("here is the event:", e);
    if (e.target.name === "searchField") {
      this.setState({
        searchField: e.target.value,
        feedback: "",
        visible: false,
        isSearchOn: false
      })
    } 
    if(e.target.name === "outdatatime") {
      this.setState({
        outdatatime: e.target.value,
        feedback: "",
        visible: false,
        isSearchOn: false
      })
    }
    if(e.target.name === "searchBatch") {
      this.setState({
        searchBatch: e.target.value,
        feedback: "",
        visible: false,
        isSearchOn: false
      })
    }
  }

  handleSearchClick = (e) => {
    console.log("Clicked search with criteria:", this.state.searchValue, this.state.searchField, this.state.searchBatch, this.state.outdatatime);

    if (this.state.searchValue !== "" || this.state.searchBatch !== "") {
      const token = localStorage.getItem("token");
      const api_host = process.env.REACT_APP_API_URL || myConstClass.REACT_APP_API_URL;
      var uri = `${api_host}/devices/meterdiameters?`;
      if (this.state.searchBatch !== "") {
        var batchInfo = this.state.searchBatch.split(":");
        var batch = batchInfo[0];
        uri = `${uri}batch=${batch}`
      }
      else if (this.state.searchField !== "" && this.state.searchValue !== "") {
        uri = `${uri}&search_field=${this.state.searchField}&search_value=${this.state.searchValue}`
      }
      var encodedURI = encodeURI(uri);
      console.log("uri",uri)
    
      myAxiosInstance.get(encodedURI, {
        headers: {
          'Authorization': `ApiKey ${token}`,
          'Content-Type': 'application/json'
        }
      })
      .then(res => {
        const meterDiamList = res.data;
        console.log("Here are your meter diameter types:", meterDiamList);
        this.setState({meterDiamList: meterDiamList});
      })
      .catch ( error => {
        const errorObject=JSON.parse(JSON.stringify(error));
        console.log(errorObject);
      })
      this.setState(
        { isSearchOn: true }
      )
    } else {
      this.setState({ alertType: "danger" });
      this.setState({ feedback: `必须选择搜索值或批次` });
      this.setState(
        {
          visible: true
        }
      );
    }

  }

  handleDeviceCallback = (imei, imsi, deviceName, deviceid, timestamp, hdralarmStatus, pageIndex) => {
    console.log("Parent received:", imei, imsi, deviceName, deviceid, timestamp, hdralarmStatus, pageIndex);
    this.props.history.push({
      pathname: `/Devices/${imei}`,
      state: {
        deviceimsi: imsi,
        deviceName: deviceName,
        deviceNo: deviceid,
        timestamp: timestamp,
        alarmStatus: hdralarmStatus,
        currPage: pageIndex,
        searchBatch: this.state.searchBatch,
        searchField: this.state.searchField,
        searchValue: this.state.searchValue,
        isSearchOn: this.state.isSearchOn,
        referredBy: `FactoryDashboard`
      }
    });
  }

  async componentDidMount() {

    console.log('factory dashboard props.history', this.props.history);

    if (this.props.location.state) {
      //retrieve the saved state of FactoryDashboard after user is referred back to it from AboutDevice
      console.log("redirect props:", this.props.location.state.searchBatch, ", ", this.props.location.state.searchField,
        ", ", this.props.location.state.searchValue, ", ", this.props.location.state.isSearchOn);
      this.setState({
        searchBatch: this.props.location.state.searchBatch,
        searchField: this.props.location.state.searchField,
        searchValue: this.props.location.state.searchValue,
        isSearchOn: this.props.location.state.isSearchOn
      })
    }

    const token = localStorage.getItem("token");
    const api_host = process.env.REACT_APP_API_URL || myConstClass.REACT_APP_API_URL;

    var uri = `${api_host}/factory/batches`;
    var encodedURI = encodeURI(uri);

    await myAxiosInstance.get(encodedURI, {
      headers: {
        'Authorization': `ApiKey ${token}`,
        'Content-Type': 'application/json'
      }
    })
      .then(res => {
        const batchList = res.data;
        console.log('uri to get batchList:', uri);
        console.log("Here is the batchList:", batchList);
        this.setState({ batchList: batchList });
      })
      .catch(error => {
        const errorObject = JSON.parse(JSON.stringify(error));
        console.log(errorObject);
      })

  }

  SelectBillingTypeFilter = ({column: { filterValue, setFilter, preFilteredRows, id }}) => {

    // Render a multi-select box
    return (
      <select
        value={filterValue || ""}
        style={
          {
            padding: '6px',
            marginTop: '5px',
            marginBotton: '5px',
            border: '1px solid #ced4da'
          }
        }
        onChange={e => {
          setFilter(e.target.value || undefined);
          this.setState({
            lastTargetFilter: id,
            lastTargetFilterValue: e.target.value
          });
        }}
      >

        <option key="" value="">全选</option>
        {Object.entries(this.state.billingTypeList).map(([key, value]) =>
          <option key={key} value={key}>
            {key}
          </option>
        )}        

      </select>
    )
  }

  // This is a custom filter UI for selecting a unique billing type from a list
  SelectMeterDiamFilter = ({column: { filterValue, setFilter, preFilteredRows, id }}) => {

    // Render a multi-select box
    return (
      <select
        value={filterValue || ""}
        style={
          {
            padding: '6px',
            marginTop: '5px',
            marginBotton: '5px',
            border: '1px solid #ced4da'
          }
        }
        onChange={e => {
          setFilter(e.target.value || undefined);
          this.setState({
            lastTargetFilter: id,
            lastTargetFilterValue: e.target.value
          });
        }}
      >
        <option key="" value="">全选</option>
        {Object.entries(this.state.meterDiamList).map(([key, value]) =>
          <option key={key} value={key}>
            {key}
          </option>
        )}        

      </select>
    )
  }

  resetFilterCallback = (newTargetFilter) => {
    console.log("text filter entered:", newTargetFilter);
    //text filter has been entered so clear any dropdown filter
    this.setState({
      lastTargetFilter: "",
      lastTargetFilterValue: ""
    })
  }

  render() {

    const listHeaderDebug = [
      {
        Header: "水表SN",
        className: "t-cell-1 text-left",
        accessor: "deviceName",
        disableFilters: true
      },
      {
        Header: "IMEI",
        accessor: "imei",
        className: "t-cell-1 text-left",
        filter: 'equals'
      },
      {
        Header: "水表号码",
        className: "t-cell-1 text-left",
        accessor: "deviceId",
        filter: 'equals'
      },
      {
        Header: "批次",
        className: "t-cell-1 text-left",
        accessor: "batch",
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: "最后更新",
        accessor: "lastRecord.timestampstring",
        className: "t-cell-6 text-left",
        disableFilters: true,
        Cell: ({ cell: { value } }) => {
          if (typeof value !== 'undefined') {
            const timeStampIndex = nthIndex(value, ":", 2);
            const displayTimeStamp = value.substring(0, timeStampIndex);
            return (
              `${displayTimeStamp}`
            )
          } else {
            return "";
          }
        }
      },
      {
        Header: "总用水 m³",
        accessor: "lastRecord.totalVol",
        className: "t-cell-1 text-right",
        disableFilters: true
      },
      {
        Header: "中国移动号码",
        accessor: "oneNetDevieId",
        className: "t-cell-1 text-left",
        filter: 'equals'
      },
      {
        Header: "客户地址",
        accessor: "MeterLocation",
        className: "t-cell-5a text-left",
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: "水表口径 (DN)",
        accessor: "MeterBodyDiameterString",
        className: "t-cell-2 text-left",        
        Filter: this.SelectMeterDiamFilter,
        filter: 'equals',
        disableSortBy: true
      },
      {
        Header: "基表号",
        accessor: "MeterBodyId",
        className: "t-cell-1 text-left",
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: "数据包序列号",
        accessor: "lastRecord.seqNum",
        className: "t-cell-1 text-right",
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: "成功上报计数",
        accessor: "lastRecord.okCount",
        className: "t-cell-1 text-right",
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: "正转计数 m³",
        accessor: "lastRecord.fwdVol",
        className: "t-cell-4a text-right",
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: "反转计数 m³",
        accessor: "lastRecord.revVol",
        className: "t-cell-4a text-right",
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: "连续用水时间",
        accessor: "lastRecord.usageCt",
        className: "t-cell-4a text-right",
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: "外部干扰累计时间",
        accessor: "lastRecord.tamperCt",
        className: "t-cell-1 text-right",
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: "移除累计时间",
        accessor: "lastRecord.removeCt",
        className: "t-cell-1 text-right",
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: "爆管累计时间",
        accessor: "lastRecord.burstCt",
        className: "t-cell-1 text-right",
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: "温度",
        accessor: "lastRecord.temp",
        className: "t-cell-1 text-right",
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: "上报前电池电压",
        accessor: "lastRecord.preBatt",
        className: "t-cell-1 text-right",
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: "上报后电池电压",
        accessor: "lastRecord.postBatt",
        className: "t-cell-1 text-right",
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: "上一次上报结束",
        accessor: "lastRecord.lastIndex",
        className: "t-cell-1 text-right",
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: "上一次上报所用时间",
        accessor: "lastRecord.lastUpload",
        className: "t-cell-1 text-right",
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: "上一次上报后NB电源状态",
        accessor: "lastRecord.lastPwr",
        className: "t-cell-1 text-right",
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: "本次NB上报STEPS",
        accessor: "lastRecord.lastSteps",
        className: "t-cell-1 text-right",
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: "信号强度",
        accessor: "lastRecord.CQS",
        className: "t-cell-1 text-right",
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: "信号功率",
        accessor: "lastRecord.sigPwr",
        className: "t-cell-1 text-right",
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: "总功率",
        accessor: "lastRecord.totalPwr",
        className: "t-cell-1 text-right",
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: "终端发射功率",
        accessor: "lastRecord.txPwr",
        className: "t-cell-1 text-right",
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: "覆盖增强等级",
        accessor: "lastRecord.cellID",
        className: "t-cell-1 text-right",
        disableFilters: true
      },
      {
        Header: "信噪比",
        accessor: "lastRecord.SNR",
        className: "t-cell-1 text-right",
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: "中心频点",
        accessor: "lastRecord.EARFCN",
        className: "t-cell-1 text-right",
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: "物理小区标识",
        accessor: "lastRecord.PCI",
        className: "t-cell-1 text-right",
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: "参考信号接收质量",
        accessor: "lastRecord.RSRQ",
        className: "t-cell-1 text-right",
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: "参考信号接收功率",
        accessor: "lastRecord.RSRP",
        className: "t-cell-1 text-right",
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: "线圈L1电感动态数值",
        accessor: "lastRecord.lc1Delta",
        className: "t-cell-1 text-right",
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: "线圈L2电感动态数值率",
        accessor: "lastRecord.lc2Delta",
        className: "t-cell-1 text-right",
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: "线圈L3电感动态数值",
        accessor: "lastRecord.lc3Delta",
        className: "t-cell-1 text-right",
        disableFilters: true,
        disableSortBy: true
      }
    ];

    return (
      <>
        <h2>工厂水表查索</h2>
        <div className="App">
          <Alert color={this.state.alertType} isOpen={this.state.visible} toggle={() => this.onDismiss()}>
            {this.state.feedback}
          </Alert>
          <Container
            className="themed-container" fluid={true}
            style={{
              "backgroundColor": "#f5f5f5",
              "height": "100%"
            }}
          >
            <>
              <Row>
                <Col>
                  <InputGroup>
                    <InputGroupAddon
                      addonType="prepend"
                    >
                      <InputGroupText>
                        搜索值:
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input autoFocus
                      type="text"
                      name="searchValue"
                      id="searchValue"
                      onChange={this.handleSearchValue}
                      onKeyPress={this.handleKeyPressed}
                      placeholder="输入搜索值"
                      style={{ margin: '0' }}
                    />
                  </InputGroup>
                </Col>
                <Col>
                  <InputGroup>
                    <InputGroupAddon
                      addonType="prepend"
                    >
                      <InputGroupText>
                        搜索栏位:
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="select"
                      name="searchField"
                      id="searchField"
                      value={this.state.searchField}
                      onChange={this.handleSearchSelect}
                    >
                      <option value="">点击选择搜索栏位</option>
                      <option value="deviceId">模块号码</option>
                      <option value="imei">IMEI</option>
                      <option value="deviceName">SN</option>
                    </Input>
                  </InputGroup>
                </Col>
                <Col>
                  <InputGroup>
                    <InputGroupAddon
                      addonType="prepend"
                    >
                      <InputGroupText>
                        批次:
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="select"
                      name="searchBatch"
                      id="searchBatch"
                      value={this.state.searchBatch}
                      onChange={this.handleSearchSelect}
                    >
                      <option value="">点击选择一批</option>

                      {Object.keys(this.state.batchList).map((key, index) => {

                        return <option value={`${key}:${this.state.batchList[key]}`}>{key}</option>;
                      })}

                    </Input>
                  </InputGroup>
                </Col>
                <Col>

                  <InputGroup>
                    <InputGroupAddon
                      addonType="prepend"
                    >
                      <InputGroupText>
                        離線設備:
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="select"
                      name="outdatatime"
                      id="outdatatime"
                      value={this.state.outdatatime}
                      onChange={this.handleSearchSelect}
                    >
                      <option value="">点击选择搜索栏位</option>
                      <option value="3">3日前</option>
                      <option value="7">7日前</option>
                      <option value="30">30日前</option>
                      <option value="nodata">nodata</option>
                    </Input>
                  </InputGroup>
                </Col>
                <Col>
                  <Button
                    color="primary"
                    size="sm"
                    style={
                      {
                        "padding": "7px",
                        "margin": "0"
                      }
                    }
                    onClick={this.handleSearchClick}
                  >
                    搜索
                  </Button>{' '}
                </Col>
              </Row>
              <Row>
                <Col>&nbsp;</Col>
              </Row>
              {
                this.state.isSearchOn &&
                <QueryClientProvider client={queryClient}>
                  <MeterTableFactory
                    columns={listHeaderDebug}
                    batch={this.state.searchBatch}
                    field={this.state.searchField}
                    query={this.state.searchValue}
                    lastTargetFilter={this.state.lastTargetFilter}
                    lastTargetFilterValue={this.state.lastTargetFilterValue}
                    callbackParent={this.callbackFunction}
                    aboutCallback={this.handleDeviceCallback}
                    FilterCallback = {this.resetFilterCallback}
                    outdatatime={this.state.outdatatime}
                  />
                </QueryClientProvider>
              }
              <Row>
                <Col>&nbsp;</Col>
              </Row>


            </>
          </Container>
        </div>
      </>
    )
  }

}

export default withRouter(FactoryDashboard);
