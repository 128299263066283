import React from "react";
import { Line } from "react-chartjs-2";

const DeviceChart = ({ data, title }) => {

    console.log(data);

    const options = {
        responsive: true,
        height: '600px',
        width: '600px',
        title: {
            display: true,
            text: title,
            fontSize: 16
        },
        legend: {
            display: false
        },
        scales: {
            xAxes: [{
                display: false,
                ticks: {
                    display: false //this will remove only the label
                }
            }]
        }
    }

    return <Line data={data} options={options} />;
};

export default DeviceChart;