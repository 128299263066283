import React, { PureComponent, Fragment } from "react";
import { withRouter } from "react-router";
import { Container, Row, Col, Alert } from "reactstrap";
//import BillTable from "./TableComponent/TableBill";
//import DynamicSelect from "./TableComponent/DynamicSelect";
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import MomentLocaleUtils from 'react-day-picker/moment';
import 'moment/locale/zh-cn';
//import { CSVDownloader } from "react-papaparse";
import './styles.css';
import './TableComponent/Table.css';
import myAxiosInstance from './utils/axios-intercept.jsx';
import GroupMenuNew from "./GroupMenuNew";
import './GroupComponent/GroupTree.css';
import * as myConstClass from './utils/fileWithConstants';
import moment from 'moment';
import Loader from 'react-loader-spinner';

//headers of the data to be extracted
const extractHeader = [
  {
    //Header: "Billing Type",
    Header: "客户类别",
    accessor: "CustomerBillingType"
  },
  {
    Header: "客户编号",
    //Header: "Billing ID",
    accessor: "CustomerBillingID"
  },
  {
    //Header: "Device ID",
    Header: "水表ID",
    accessor: "did"
  },
  {
    //Header: "Start Date",
    Header: "客户账单开始日期",
    accessor: "start"
  },
  {
    //Header: "Start Volume",
    Header: "客户账单开始计数",
    accessor: "start_volume",
  },
  {
    //Header: "End Date",
    Header: "客户账单终止日期",
    accessor: "end"
  },
  {
    //Header: "End Volume",
    Header: "客户帐单终止计数",
    accessor: "end_volume"
  },
  {
    //Header: "Bill Amount",
    Header: "总计数",
    accessor: "bill"
  },
  {
    //Header: "Billing Name",
    Header: "客户名称",
    accessor: "CustomerBillingName"
  },
  {
    //Header: "Billing Address",
    Header: "客户地址",
    accessor: "CustomerBillingAddress"
  },
  {
    //Header: "Billing Contact",
    Header: "客户联络方式",
    accessor: "CustomerBillingContact"
  },
  {
    //Header: "Billing Cut-off Date",
    Header: "客户账单截数日",
    accessor: "CustomerBillingCutoffDate"
  },
  {
    //Header: "Billing Cycle",
    Header: "客户账单结算周期",
    accessor: "CustomerBillingCycle"
  },
  {
    //Header: "Billing Email",
    Header: "客户电子邮箱",
    accessor: "CustomerBillingEmail"
  },
  {
    //Header: "Billing Gross Floor Area",
    Header: "客户单位建筑面积 (㎡)",
    accessor: "CustomerBillingGrossFloorArea"
  },
  {
    //Header: "Billing Mobile",
    Header: "客户手机号码",
    accessor: "CustomerBillingMobile"
  },
  {
    //Header: "Billing NetFloorArea",
    Header: "客户单位使用面积 (㎡)",
    accessor: "CustomerBillingNetFloorArea"
  },
  {
    //Header: "Billing Population",
    Header: "客户单位人口数",
    accessor: "CustomerBillingPopulation"
  },
  {
    //Header: "Billing Postal Code",
    Header: "客户地址邮编",
    accessor: "CustomerBillingPostalCode"
  }
]

const templates = [
  {
    key: 1,
    name: "新洲水务"
  },
  {
    key: 2,
    name: "指尖水务"
  },
  {
    key: 3,
    name: "吉安水务"
  },
  {
    key: 4,
    name: "周口银龙"
  },
  {
    key: 5,
    name: "增城导出"
  },
  {
    key: 6,
    name: "彭泽银龙"
  },
  {
    key: 7,
    name: "新余水务"
  },
  {
    key: 8,
    name: "河源水业"
  },
  {
    key: 9,
    name: "和平水务"
  },
  {
    key: 10,
    name: "奥纳售后"
  },{
    key: 11,
    name: "荆州水务"
  }


]

class WaterBill extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      billList: [],
      groups: [],
      loadingGroups: false,
      startDate: "",
      endDate: "",
      isLoggedIn: false,
      selectedGroup: "ALL",
      selectedNodeName: "",
      selectedTemplate: "",
      dataToDownload: [],
      loading: false,
      visible: false,
      feedback: "",
      alertType: "info"
    };
    this.onChangeTemplate = this.onChangeTemplate.bind(this);
  }

  handleCallback = (childData, childKey) => {
    if(!this.state.loading)
    {
      console.log("Parent received:", childData);
      this.setState({
        selectedGroup: childData,
        selectedNodeName: childKey
      },
        this.handleSelect);

      this.setState({ billList: [] });
      this.setState({ feedback: "" });
      this.setState(
        {
          visible: false
        }
      );
    }   
  }
  handleStartDate = (startDate) => {
    startDate.setHours(0, 0, 0, 0);
    console.log("Start Date:", startDate);
    this.setState({ startDate: startDate });
    this.setState({ billList: [] });
    this.setState({ feedback: "" });
    this.setState(
      {
        visible: false
      }
    );
  }

  handleEndDate = (endDate) => {
    endDate.setHours(0, 0, 0, 0);
    console.log("End Date:", endDate);
    this.setState({ endDate: endDate });
    this.setState({ billList: [] });
    this.setState({ feedback: "" });
    this.setState(
      {
        visible: false
      }
    );
  }

  handleSelect = () => {
    console.log("Updated group:", this.state.selectedGroup);
    console.log("Updated node name:", this.state.selectedNodeName)
  }

  onDismiss = () => {
    this.setState({ visible: false });
  }

  onChangeTemplate(event) {
    console.log("Switching to template:", event.target.value);
    this.setState({ selectedTemplate: event.target.value });
  }

  handleAxiosError = (e) => {
    this.setState({ alertType: "danger" });
    this.setState(
      {
        feedback: `Axios user request failed: ${e}`
      }
    );
    this.setState(
      {
        visible: true
      }
    );
  }

  generateGroups = () => {

    const token = localStorage.getItem("token");
    const api_host = process.env.REACT_APP_API_URL || myConstClass.REACT_APP_API_URL;

    var uri = `${api_host}/hierarchy/entities/navigate`;
    var encodedURI = encodeURI(uri);
    console.log("encoded URI:", encodedURI)
    console.log("decoded URI:", decodeURI(encodedURI))

    this.setState({ loadingGroups: true });

    myAxiosInstance.get(encodedURI, {
      headers: {
        'Authorization': `ApiKey ${token}`
      }
    })
      .then(res => {
        const groupList = res.data;
        console.log('This is your data', groupList);
        if (groupList.length > 0) {
          this.setState({ groups: groupList });
          console.log("length of groupMenu:", groupList.length);
          console.log("groupMenu:", groupList);
        } else {
          console.log("Empty menu!");
        }
        this.setState({ loadingGroups: false });
      })
      .catch(error => {
        const errorObject = JSON.parse(JSON.stringify(error));
        console.log(errorObject.message);
        this.setState({
          islogout: true
        }, () => {
          this.handleAxiosError(error);
        });
        this.setState({ loadingGroups: false });
      })

  }

  async componentDidMount() {

    this.generateGroups();

  }

  generateDownload = (billData) => {

    console.log("Generating data for download....");
    var data_to_download = [];

    for (var index = 0; index < billData.length; index++) {
      let record_to_download = {};
      for (var colIndex = 0; colIndex < extractHeader.length; colIndex++) {
        record_to_download[extractHeader[colIndex].Header] = billData[index][extractHeader[colIndex].accessor];
      }
      data_to_download.push(record_to_download);
    }

    console.log("Data to download:");
    console.log(data_to_download);
    this.setState({ dataToDownload: data_to_download })

  }

  saveFile = async (blob, filename) => {
    const a = document.createElement('a');
    a.download = filename;
    a.href = URL.createObjectURL(blob);
    a.addEventListener('click', (e) => {
      setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
    });
    a.click();
  }

  getWaterBill = (event, dbf_format) => {

    console.log('selectedGroup:', this.state.selectedGroup);
    console.log('start date:', this.state.startDate);
    console.log('end date:', this.state.endDate);
    console.log('template:', this.state.selectedTemplate);

    if (this.state.selectedGroup === "ALL") {
      this.setState({
        alertType: "danger"
      });
      this.setState({ feedback: "必须选择群组" });
      this.setState({
        visible: true
      });
      return;
    }

    if ((this.state.startDate === "") || (this.state.endDate === "")) {
      this.setState({
        alertType: "danger"
      });
      this.setState({ feedback: "必须输入开始和终止日期" });
      this.setState({
        visible: true
      });
      return;
    }

    //convert dates to epoch
    var startDate = new Date(this.state.startDate.toString());
    var endDate = new Date(this.state.endDate.toString());
    var startEpoch = startDate.getTime();
    var endEpoch = endDate.getTime();

    console.log("start epoch:", startEpoch);
    console.log("end epoch:", endEpoch);

    if (startEpoch > endEpoch) {
      this.setState({
        alertType: "danger"
      });
      this.setState({ feedback: "终止日期不能少于开始日期" });
      this.setState({
        visible: true
      });
      return;
    }

    if (this.state.selectedTemplate === "") {
      this.setState({
        alertType: "danger"
      });
      this.setState({ feedback: "必须选择模板" });
      this.setState({
        visible: true
      });
      return;
    }

    const token = localStorage.getItem("token");
    const api_host = process.env.REACT_APP_API_URL || myConstClass.REACT_APP_API_URL;

    this.setState(
      {
        visible: false
      }
    );
    this.setState({ loading: true });
    var uri =`${api_host}/hierarchy/bill?path=${this.state.selectedGroup}&start=${startEpoch}&end=${endEpoch}&template=${this.state.selectedTemplate}`
    if (dbf_format === "true") {
      uri = `${uri}&dbf=true`;
      console.log("uri: ", uri)
    }
    const encodedURI = encodeURI(uri);
    console.log("encoded URI:", encodedURI)
    console.log("decoded URI:", decodeURI(encodedURI))

    myAxiosInstance.get(encodedURI, {
      headers: {
        'Authorization': `ApiKey ${token}`
      },
      responseType: "blob"
    })
      .then(res => {
        console.log('excel file size', res.data.size)
        if (res.data instanceof Blob) {
          let blob
          let fileName
          if (dbf_format === "true") {
            blob = new Blob([res.data], { type: 'application/x-dbf', encoding: 'cp936' })
            fileName = this.state.selectedGroup + "_" + moment(startDate).format('YYYYMMDD') + "_" + moment(endDate).format('YYYYMMDD') + "_" + this.state.selectedTemplate + ".dbf"
          }
          else{
            blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            fileName = this.state.selectedGroup + "_" + moment(startDate).format('YYYYMMDD') + "_" + moment(endDate).format('YYYYMMDD') + "_" + this.state.selectedTemplate + ".xlsx"
          }
          var x, i, y
          for (i = 0, x = 0; x !== -1 && i < 3; i++) {
            y = x
            x = fileName.indexOf('/', y + 1)
            //console.log("i", i, "x", x)
          }
          console.log("substr ", y)
          fileName = fileName.substr(y + 1)
          console.log("writing to excel file ", fileName)
          this.saveFile(blob, fileName)
          this.setState({
            loading: false
          });
          this.setState({
            alertType: "success"
          });
          this.setState({ feedback: "水费单下载成功" });
          this.setState({
            visible: true
          });
        }
        /*
        //this.setState({billList: res.data});
        if (res.data.length === 0) {
          const errorMsg = "找不到给定条件的数据";
          this.setState({
            alertType: "info"
          });
          this.setState({ feedback: errorMsg });
          this.setState({
            visible: true
          });
        } else {
          
          let billList = []
       
          //remove timestamp from start and end 
          for (var j = 0; j < res.data.length; j++) {
       
            let meter_record = res.data[j];
       
            var startDate = new Date(res.data[j]['start'].toString())
            var endDate = new Date(res.data[j]['end'].toString())
       
            meter_record['start'] = startDate.getFullYear() + '-' + (startDate.getMonth() + 1) + '-' + startDate.getDate();
            meter_record['end'] = endDate.getFullYear() + '-' + (endDate.getMonth() + 1) + '-' + endDate.getDate();
       
            billList.push(meter_record);
       
          }
       
          this.setState({ billList: billList });
          this.setState({ loading: false });
       
          this.generateDownload(billList);
        }*/
      })
      .catch(e => {
        console.log(`Axios user request failed: ${e}`);
        this.handleAxiosError(e);
        this.setState({
          loading: false
        });
      });

    event.preventDefault();

  }

  render() {
    let export_button
    if (this.state.selectedTemplate === "增城导出") {
      export_button =[
      <Col>
        <button
          className="btn btn-primary"
          style={{
            margin: '15px',
            backgroundColor: '#8DB6FA'
          }}
          onClick={(event) => this.getWaterBill(event, "true")}>
          生成dbf账单
        </button>
      </Col>,
      <Col>
        <button
          className="btn btn-primary"
          style={{
            margin: '15px',
            backgroundColor: '#8DB6FA'
          }}
          onClick={(event) => this.getWaterBill(event)}>
          生成账单
        </button>
      </Col>
      ]
    }else{
      export_button = [
        <Col>
          <button
            className="btn btn-primary"
            style={{
              margin: '15px',
              backgroundColor: '#8DB6FA'
            }}
            onClick={(event) => this.getWaterBill(event)}>
            生成账单
          </button>
        </Col>
      ]
    }

    return (
      <Fragment>
        <h2>收费管理</h2>
        <div className="App">
          <div className="demo-container">
            <div className="column-left">
              <GroupMenuNew
                groups={this.state.groups}
                loading={this.state.loadingGroups}
                parentCallback={this.handleCallback}
                initialGroup={
                  this.state.selectedGroup
                }
              />
            </div>
            <div className="column-right">
              <Alert color={this.state.alertType} isOpen={this.state.visible} toggle={() => this.onDismiss()}>
                {this.state.feedback}
              </Alert>
              <Container className="themed-container" fluid={true}>
                <Row>
                  {(this.state.groups.length > 0) && (
                    <Col>
                      <>
                        <p>群组</p>
                        <div className="daypicker-control">
                          {
                            this.state.selectedNodeName !== "" ?
                              <>
                                {this.state.selectedNodeName}
                              </>
                              :
                              `请选择群组`
                          }
                        </div>
                      </>
                    </Col>
                  )}
                  <Col>
                    <p>账单开始日期:</p>
                    <DayPickerInput
                      onDayChange={startDate => this.handleStartDate(startDate)}
                      inputProps={{ className: 'daypicker-control' }}
                      dayPickerProps={{
                        locale: 'zh-cn',
                        localeUtils: MomentLocaleUtils,
                      }}
                    />
                  </Col>
                  <Col>
                    <p>账单终止日期(不包括该日):</p>
                    <DayPickerInput
                      onDayChange={endDate => this.handleEndDate(endDate)}
                      inputProps={{ className: 'daypicker-control' }}
                      dayPickerProps={{
                        locale: 'zh-cn',
                        localeUtils: MomentLocaleUtils,
                      }}
                    />
                  </Col>
                  <Col>
                    <p>模板</p>
                    <div>
                      <select
                        value={this.state.selectedTemplate}
                        onChange={this.onChangeTemplate}
                      >
                        <option value="">
                          点击选择模板
                        </option>
                        {templates.map((template, index) => {
                          return <option value={template.name}>{template.name}</option>;
                        })}
                      </select>
                    </div>
                  </Col>
                  {export_button}
                </Row>
                <Row>
                  <Col>
                    {this.state.loading && (
                      <>
                        <p>. . . 进行中 . . .</p>
                        <div
                          style={{
                            width: "100%",
                            height: "100",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                          }}
                        >
                          <Loader type="ThreeDots" color="#2BAD60" height="100" width="100" />
                        </div>
                      </>
                    )}
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }

}

export default withRouter(WaterBill);
