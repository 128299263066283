import React from "react";
import { Pie } from "react-chartjs-2";

const PieChart  = ({ data, title }) => {

    console.log("In the PieChart:", data);
    const options={
    responsive: true,
    /*height: '600 px',
    width: '600px',*/
    title:
    {
        display: true,
        text: title,
        fontSize: 16
    },
    legend:
    {
        display:true,
        position:'left'
    },
    tooltips: 
    {
        callbacks: 
        {
            label: function(tooltipItem, data) {
                var dataset = data.datasets[tooltipItem.datasetIndex];
                var currentValue = dataset.data[tooltipItem.index];
                return currentValue;
            }
        }
    }
    
    }
            
    return (
        <Pie 
            data={data}
            options={options}
        />
    )

}

export default PieChart;