import React from "react";
import "./styles.css";
import Login from "./Login";
import Dashboard from "./Dashboard";
import FactoryDashboard from "./FactoryDashboard";
import {
  //BrowserRouter as Router,
  Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import AboutDevice from "./AboutDevice";
import history from "./utils/history";
 
export default function App() {
  return (
    <Router history={history}>
      <Switch>
        <Route path="/login" render={(props) => <Login {...props}/>}>
        </Route>
        <ProtectedRoute path="/dashboard">
          <Dashboard />
        </ProtectedRoute>
        <ProtectedRoute path="/dashboard/FactoryDashboard">
          <Dashboard />
        </ProtectedRoute>
        <Route exact path="/">
          <Redirect exact from="/" to="dashboard" />
        </Route>
        <ProtectedRoute path="/Devices/:deviceId"  component={AboutDevice} />
        <Route path="*">
          <Redirect from="/" to="dashboard" />
        </Route>
      </Switch>
    </Router>
  );
}