import React, { useState, useEffect } from "react";
import Tree from "react-d3-tree";
import { useCenteredTree } from "./helpers";
import { Alert } from "reactstrap";
import "./GroupTreeStyles.css";
import "./GroupTree.css";
import useAsyncRequest from './components/useAsyncRequest';
import myAxiosInstance from '../utils/axios-intercept';
import * as myConstClass from '../utils/fileWithConstants';
import { useHistory } from "react-router-dom";

const containerStyles = {
  width: "100vw",
  height: "100vh"
};

// Here we're using `renderCustomNodeElement` to bind event handlers
// to the DOM nodes of our choice.
// In this case, we only want the node to toggle if the *label* is clicked.
// Additionally we've replaced the circle's `onClick` with a custom event,
// which differentiates between branch and leaf nodes.
const renderNodeWithCustomEvents = ({
  nodeDatum,
  toggleNode,
  handleNodeClick
}) => (
  <g>
    <circle r="15" className="demo-node" onClick={() => handleNodeClick(nodeDatum)} />
    {nodeDatum.children.length > 0 ? (
      <>
      <text fill="black" strokeWidth="1" x="20" dy="-10" onClick={toggleNode}>
        {nodeDatum.name} 
      </text>
      <text fill="black" strokeWidth="1" x="20" dy="10" onClick={toggleNode}>
        +/-
      </text>
      </> 
    ) : (
      <text className="leaftext" strokeWidth="1" x="-30" dy="30">
        {nodeDatum.name}
      </text>      
    )}

    {nodeDatum.children.length > 0 && (
      <text fill="black" strokeWidth="1" x="20" dy="10" onClick={toggleNode}>
        +/-
      </text>      
    )}
  </g>
);

const countNodes = (count = 0, n) => {
  // Count the current node
  count += 1;

  // Base case: reached a leaf node.
  if (!n.children) {
    return count;
  }

  // Keep traversing children while updating `count` until we reach the base case.
  return n.children.reduce((sum, child) => countNodes(sum, child), count);
};

export default function GroupTree () {

  const [currNode, setCurrNode] = useState(null);
  const [origData, origStatus, loading] = useAsyncRequest(5);

  const [data, setData] = useState(null);
  const [nodeCount, setNodeCount] = useState(0);
  
  const [adding, setAdding] = useState(false);
  const [removing, setRemoving] = useState(false);
  const [modifing, setModifing] = useState(false);
  const [changing, setChanging] = useState(false);
  const [newNodeName, setNodeName] = useState("");
  const [changepath, setchangepath] = useState("");
  
  const [visible, setVisible] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [alertType, setAlertType] = useState("info");
  const [showProgress, setProgressMsg] = useState(false);

  const token = localStorage.getItem("token");
  const api_host = process.env.REACT_APP_API_URL || myConstClass.REACT_APP_API_URL;
  const history = useHistory();

  useEffect(() => {

    if (origStatus === "401") {
      console.log("Uh-oh, origData is null, redirecting back to login");
    
      localStorage.clear();

      //session has expired and API key is no longer recognized
      history.push({
        pathname: "/login", 
        state: {
          infoMsg: "您当前的会话已结束。请重新登录."
        }
      });

    }

    if (origData) {
        const totalNodeCount = countNodes(0, Array.isArray(origData) ? origData[0] : origData);
        origData.name = localStorage.getItem("companyname")      
        console.log("just loaded:", origData);
        console.log("total nodes:", totalNodeCount);
        console.log("parent name:", origData.name);
        setNodeCount(totalNodeCount);
        setData(origData);
    }          

  }, [origData, origStatus]);

  
  const handleNodeClick = (nodeDatum) => {

    if (changing){
      setchangepath(nodeDatum)
      console.log("clicked on: ", nodeDatum.__rd3t.id, "node:", nodeDatum);
      setVisible(false);
      setAdding(false);
      setRemoving(false);
    }
    else{
      setCurrNode(nodeDatum)
      console.log("clicked on: ", nodeDatum.__rd3t.id, "node:", nodeDatum);
      setVisible(false);
      setAdding(false);
      setRemoving(false);
      setChanging(false);}

  };

  const handleChange = (e) => {

    const target = e.target;
    const value = target.value.trim();
    const name = target.name;

    console.log('name:', name);
    console.log('value:', value);

    if (name === 'newNodeName') {
        setNodeName(value);
    }

  }
 
  const refreshTreeData = async () => {

    try {
        //const user_token = localStorage.getItem("token")
        //const api_host = myConstClass.REACT_APP_API_URL || process.env.REACT_APP_API_URL;
        const uri = `${api_host}/hierarchy/entities`;

        const encodedURI = encodeURI(uri);
        console.log("encoded URI:", encodedURI)
        console.log("decoded URI:", decodeURI(encodedURI))
                        
        await myAxiosInstance.get(
            encodedURI, {
                headers: {
                    'Authorization': `ApiKey ${token}`
                },

            }
        )
        .then ((res) => {
            console.log("response from get:", res)
            res.data.name = localStorage.getItem("companyname");

            setData(res.data);
            setTimeout (
                () => {
                  setProgressMsg(false);
                  const totalNodeCount = countNodes(0, Array.isArray(res.data) ? res.data[0] : res.data);
                  setNodeCount(totalNodeCount);
                }, 1000
            );
        })
        .catch ((e) => {
            setData(null, setProgressMsg(false));
        });
    } catch (err) {
        console.warn("Something went wrong fetching the data...", err);
        setProgressMsg(false);
    }
  }

  const insertNewChild = e => {
    
    e.preventDefault();

    if (newNodeName === "") {
      setFeedback("群组名称不能为空");
      setVisible(true);
      setAlertType("danger");
      return;
    }

    setProgressMsg(true);

    const uri = `${api_host}/hierarchy/entities?path=${currNode.attributes._path}&entity=${newNodeName}`;
    const encodedURI = encodeURI(uri);

    myAxiosInstance.post(encodedURI, null, {
        headers: {
            'Authorization': `ApiKey ${token}`
        }
    })
    .then(res => {
        console.log('Status from adding '+newNodeName, res.status)
        console.log('Response:', res.data)
        
        // retrieve the updated tree and refresh the display
        refreshTreeData();
        setFeedback(`${newNodeName}已成功添加`);
        setVisible(true);
        setAlertType("success");
        setAdding(false);
        setNodeName("");
        setCurrNode(null);        
                                
    })
    .catch(e => {
        console.log(`Axios user request failed: ${e}`);
        setProgressMsg(false);
        setFeedback(`添加小区失败: ${e}`);
        setVisible(true);
        setAlertType("danger");
    });

  }

  const insertModifyChild = e =>{
    e.preventDefault();

    if (newNodeName === "") {
      setFeedback("群组名称不能为空");
      setVisible(true);
      setAlertType("danger");
      return;
    }

    setProgressMsg(true);

    const uri = `${api_host}/hierarchy/entities/modify?path=${currNode.attributes._path}&entity=${newNodeName}`;
    const encodedURI = encodeURI(uri);

    myAxiosInstance.post(encodedURI, null, {
        headers: {
            'Authorization': `ApiKey ${token}`
        }
    })
    .then(res => {
        console.log('Status from adding '+newNodeName, res.status)
        console.log('Response:', res.data)
        
        // retrieve the updated tree and refresh the display
        refreshTreeData();
        setFeedback(`${newNodeName}已成功修改`);
        setVisible(true);
        setAlertType("success");
        setModifing(false);
        setNodeName("");
        setCurrNode(null);        
                                
    })
    .catch(e => {
        console.log(e.response.data["error"]);
        console.log(`Axios user request failed: ${e.response.data["error"]}`);
        setProgressMsg(false);
        setFeedback(`修改小区失败: ${e.response.data["error"]}`);
        setVisible(true);
        setAlertType("danger");
    });
  }

  const insertchangeChild = e =>{
    e.preventDefault();

    if (changepath === "") {
      setFeedback("请选择变更群组位置");
      setVisible(true);
      setAlertType("danger");
      return;
    }

    if (changepath === currNode ) {
      setFeedback("不可选择相同的位置");
      setVisible(true);
      setAlertType("danger");
      return;
    }

    let endParentPath = currNode.attributes._path.lastIndexOf("/");
    let parentPath = currNode.attributes._path.substring(0, endParentPath);

    if (changepath.attributes._path === parentPath){
      setFeedback("不可选择母群组");
      setVisible(true);
      setAlertType("danger");
      return;
    }

    setProgressMsg(true);

    const uri = `${api_host}/hierarchy/entities/change?path=${currNode.attributes._path}&changePath=${changepath.attributes._path}`;
    const encodedURI = encodeURI(uri);
    console.log(uri)

    myAxiosInstance.post(encodedURI, null, {
        headers: {
            'Authorization': `ApiKey ${token}`
        }
    })
    .then(res => {
        console.log('Status from adding '+newNodeName, res.status)
        console.log('Response:', res.data)
        
        // retrieve the updated tree and refresh the display
        refreshTreeData();
        setFeedback(`${currNode.attributes._path}已成功变更群组 ${changepath.attributes._path}`);
        setVisible(true);
        setAlertType("success");
        setChanging(false);
        setchangepath("");
        setCurrNode(null);        
                                
    })
    .catch(e => {
        console.log(`Axios user request failed: ${e}`);
        setProgressMsg(false);
        setFeedback(`添加小区失败: ${e}`);
        setVisible(true);
        setAlertType("danger");
    });
  }

  const cancelNewChild = () => {
    setAdding(false);
    setNodeName("");
    setCurrNode(null);    
  }

  const addChildNode = () => {
    
    if (!currNode) {
      setFeedback("请选择群组");
      setVisible(true);
      setAlertType("info");
      setAdding(false);
      return;
    }

    setAdding(true);

  }

  const confirmModify = () =>{
    if (!currNode) {
      setFeedback("请选择群组");
      setVisible(true);
      setAlertType("info");
      setModifing(false);
      return;
    }

    setModifing(true)
  }

  const cancelModifyChild = () =>{
    setModifing(false);
    setchangepath("");
    setCurrNode(null);    
  }

  const confirmChange= () =>{
    if (!currNode) {
      setFeedback("请选择群组");
      setVisible(true);
      setAlertType("info");
      setChanging(false);
      return;
    }
    if (currNode.children.length !== 0){
      setFeedback("只能变更子群组");
      setVisible(true);
      setAlertType("info");
      setChanging(false);
      return;
    }

    setChanging(true)
  }

  const cancelChange= () =>{
    setChanging(false);
    setNodeName("");
    setCurrNode(null);  
  }

  const confirmRemove = () => {

    if (!currNode) {
      setFeedback("请选择群组");
      setVisible(true);
      setAlertType("info");
      setRemoving(false);
      return;
    }

    if (currNode.__rd3t.depth === 0) {
      setFeedback('不能删除顶端');
      setVisible(true);
      setAlertType("danger");
      setRemoving(false);
      return;
    }

    setRemoving(true);

  }

  const cancelRemove = () => {
    setRemoving(false);
  }

  const removeChildNode = async group => {

    let endParentPath = currNode.attributes._path.lastIndexOf("/");
    let parentPath = currNode.attributes._path.substring(0, endParentPath);
    console.log("parent path is: ", parentPath);

    const uri = `${api_host}/hierarchy/entities?path=${parentPath}&entity=${currNode.name}`;
    const encodedURI = encodeURI(uri);

    await myAxiosInstance.delete(
      encodedURI, {
      headers: {
        'Authorization': `ApiKey ${token}`
      }
    })
    .then(res => {
      console.log('Status from removing '+currNode.name, res.status)

      if (res.status === 200) {
        // retrieve the updated tree and refresh the display
        refreshTreeData();
        setFeedback(`小区 ${currNode.name} 已成功刪除`);
        setVisible(true);
        setAlertType("success");
        setRemoving(false);
        setNodeName("");
        setCurrNode(null);        
      } else {
        console.log(`刪除群组失败 ${res.status} ${res.statusText}`)
      }

    })
    .catch(e => {
      console.log(`刪除群组失败: ${e}`);
      setProgressMsg(false);
      if (String(e).includes("428")) {
        setFeedback("不能删除未清空的群组");
      } else {
        setFeedback("刪除群组失败");
      }      
      setVisible(true);
      setAlertType("danger");
    });

  }

  const onDismiss = () => {
    setVisible(false);
  }

  const [translate, containerRef] = useCenteredTree();

  console.log("data:", data);

  console.log("orig data:", origData);

  return (
      <div className="App">
        <div className="demo-container">
          <div className="column-left">
            {loading ? (
              <p>. . . . .载入中. . . . .</p>
            ) : (
              <>
              <div className="controls-container">
              <div className="prop-container">
                {currNode ? (
                  <>
                  <h4 className="prop-large">选定群组: </h4>
                  <p>
                    {currNode.name}
                  </p>
                  </>
                ) :
                  <h3 className="title">请选择群组</h3>
                }
                {adding && !modifing && !removing && !changing &&(
                  <>
                  <span className="prop prop-large">输入群组名称:</span>
                  <label className="sub-prop">
                    Name:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="newNodeName"
                    onChange={handleChange}
                  />
                  <button
                    type="button"
                    className="btn btn-controls btn-block"
                    onClick={insertNewChild}
                  >
                    确认新增在 {currNode.name} 下面
                  </button>
                  <button
                    type="button" 
                    className="btn btn-controls btn-block" 
                    onClick={cancelNewChild} 
                  >
                    取消
                  </button>
                  </> 
                )}
                {modifing && !removing && !adding && !changing && (
                  <>
                  <span className="prop prop-large">输入群组名称:</span>
                  <label className="sub-prop">
                    Name:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="newNodeName"
                    onChange={handleChange}
                  />
                  <button
                    type="button"
                    className="btn btn-controls btn-block"
                    onClick={insertModifyChild}
                  >
                    确认修改群组名称
                  </button>
                  <button
                    type="button" 
                    className="btn btn-controls btn-block" 
                    onClick={cancelModifyChild} 
                  >
                    取消
                  </button>
                  </> 
                )}
                {changing && !adding && !modifing && !removing && (
                  <>
                    <p>变更群组{currNode.name}位置到 {changepath.name} 下面</p>
                  <button
                    type="button"
                    className="btn btn-controls btn-block"
                    onClick={insertchangeChild}
                  >
                    确认修改群组位置
                  </button>
                  <button
                    type="button"
                    className="btn btn-controls btn-block"
                    onClick={cancelChange}
                  >
                    取消
                  </button>
                  </>  
                )}
                {removing && !adding && !modifing && !changing && (
                  <>
                  <button
                    type="button"
                    className="btn btn-controls btn-block"
                    onClick={removeChildNode}
                  >
                    确认删除群组
                  </button>
                  <button
                    type="button"
                    className="btn btn-controls btn-block"
                    onClick={cancelRemove}
                  >
                    取消
                  </button>
                  </>  
                )}
                {!removing && !adding && !modifing && !changing && (
                  <>
                  <button
                  type="button"
                  className="btn btn-controls btn-block"
                  onClick={addChildNode}
                  >
                    新增群组
                  </button>
                  <button
                    type="button"
                    className="btn btn-controls btn-block"
                    onClick={confirmModify}
                  >
                    修改群组名称
                  </button>
                  <button
                    type="button"
                    className="btn btn-controls btn-block"
                    onClick={confirmChange}
                  >
                    修改群组位置
                  </button>
                  <button
                    type="button"
                    className="btn btn-controls btn-block"
                    onClick={confirmRemove}
                  >
                    删除群组
                  </button>
                  </> 
                )}
              </div>
            </div>
            </>
            )}
          </div>

          <div className="column-right">
            <Alert color={alertType} isOpen={visible} toggle={onDismiss}>
              {feedback}
            </Alert>
            { loading ? (
              <p>载入中. . . . .</p>
            ) : (
              <>
              <div className="tree-stats-container">
                {currNode ? (
                  <p>选定群组: {currNode.name}</p>
                ) : (
                  <p>请选择群组</p>
                )}
                { 
                  showProgress && <h3> 处理中. . . . .</h3>
                }
              </div>
              <div style={containerStyles} ref={containerRef}>
              <Tree
                data={data}
                translate={translate}
                collapsible={true}
                onNodeClick={handleNodeClick}
                renderCustomNodeElement={(rd3tProps) =>
                renderNodeWithCustomEvents({ ...rd3tProps, handleNodeClick })
                }
                separation={{siblings: 1, nonSiblings: 2}}
                nodeSize={{x: 100, y:70}}
                orientation="vertical"
                rootNodeClassName="node__root"
                zoom={1}
                zoomable={true}
                scaleExtent={{min: 1, max: 3}}
                pathFunc={"step"}
              />
              </div>
              </>
            )}

          </div>
        </div>
      </div>
  );
}