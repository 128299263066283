import React, {useState} from 'react';
import '../Login.css';
import { Label, Input } from "reactstrap";
//import { Redirect } from "react-router-dom";

const AddUserForm = (props) => {

    const initUser = {username: "", displayname: "", password: "", confirmPassword: ""};
    const [user, setUser] = useState(initUser);
    const [passwordShown, setPasswordShown] = useState(false);

    const initErrors = [];
    const [errors, setError] = useState(initErrors);

    const handleChange = e => {
    //    const {name, value} = e.target;
    //    setUser({...user, [name]: value});
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setUser({...user, [name]: value});
        setError([]);
    }

    const handleSubmit = e => {
        e.preventDefault();

        let formIsValid = true;
        setError([]);

        let users = props.users;

        if (!user.username.trim() || typeof user.username === "undefined") {
            setError(errors => errors.concat("用户名不能为空"));
            formIsValid = false;
        }
        if (user.username.trim().indexOf(' ') >= 0) {
            setError(errors => errors.concat("用户名不能包含空格"));
            formIsValid = false;
        }
        if (user.username.trim() === localStorage.getItem("username")) {
            setError(errors => errors.concat("此用户名已存在"));
            formIsValid = false;
        }
        if (users !== null && users.some(item => user.username.trim() === item)) {
            setError(errors => errors.concat("此用户名已存在"));
            formIsValid = false;            
        }
        if (!user.displayname.trim() || typeof user.displayname === "undefined") {
            setError(errors => errors.concat("用户姓名不能为空"));
            formIsValid = false;
        }
        if (!user.password.trim() || typeof user.password === "undefined") {
            setError(errors => errors.concat("密码不能为空"));
            formIsValid = false;
        }
        if (user.password.trim().indexOf(' ') >= 0) {
            setError(errors => errors.concat("密码不能包含空格"));
            formIsValid = false;
        }
        if (!user.confirmPassword.trim() || typeof user.confirmPassword === "undefined") {
            setError(errors => errors.concat("确认密码不能为空"));
            formIsValid = false;
        } 
        
        if (user.password.trim() && user.confirmPassword.trim()) {
            if (user.password.trim() !== user.confirmPassword.trim()) {
                setError(errors => errors.concat("密码不匹配"));
                formIsValid = false;
            }
        }

        if (formIsValid) {
            //try to add the new user to the backend storage
            props.addUser(user);
            setUser(initUser);
            console.log(initUser);
        }

    }

    const togglePasswordVisibility = event => {
        if (event.target.checked) {
            setPasswordShown(true);   
        } else {
            setPasswordShown(false);
        }
    };

    return (
        <div className="container">
            <form onSubmit={handleSubmit} className="form-signin">
                <div className="row">
                    <div className="col">
                        <input
                            type="text"
                            name="username"
                            value={user.username}
                            onChange={handleChange}
                            placeholder="输入用户名"
                        />
                        <input
                            type="text"
                            name="displayname"
                            value={user.displayname}
                            onChange={handleChange}
                            placeholder="输入用户姓名"
                        />
                        <input
                            type={passwordShown ? "text" : "password"}
                            name="password"
                            value={user.password}
                            onChange={handleChange}
                            placeholder="输入密码"
                        />
                        <input
                            type={passwordShown ? "text" : "password"}
                            name="confirmPassword"
                            value={user.confirmPassword}
                            onChange={handleChange}
                            placeholder="确认密码"
                        />
                        <div className="form-group">
                            <Label check>
                                <Input 
                                    type="checkbox"
                                    style={{width: 'auto'}}
                                    onChange={togglePasswordVisibility}/>{' '} 
                                    显示密码
                            </Label>
                        </div>
                        <input type="submit" value="添加用户" />
                    </div>
                </div>
            </form>
            {errors && errors.length > 0 && (
                errors.map((msg, id) =>
                    <h3 key={id} className="text-danger">{msg}</h3>
                )
            )}
        </div>
    )
}

export default AddUserForm;