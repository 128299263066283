import React, { useEffect ,useState} from 'react';
import { useTable, usePagination } from 'react-table';
import { useQuery } from 'react-query';
import { fetchFactoryMeterData } from './fetchFactoryMeterData';
import * as myConstClass from '../utils/fileWithConstants';
import classNames from "classnames";
import loaderimage from "./assets/loader-table.gif";
import "../TableComponent/TableDevice.css";
import {
    Table,
    Pagination,
    PaginationItem,
    PaginationLink
} from "reactstrap";
import myAxiosInstance from '../utils/axios-intercept';

const initialState = {
    queryPageIndex: 0,
    queryPageSize: myConstClass.PAGESIZE,
    totalCount: null,
};

const PAGE_CHANGED = 'PAGE_CHANGED';
const PAGE_SIZE_CHANGED = 'PAGE_SIZE_CHANGED';
const TOTAL_COUNT_CHANGED = 'TOTAL_COUNT_CHANGED';

const reducer = (state, { type, payload }) => {
    switch (type) {
        case PAGE_CHANGED:
            return {
                ...state,
                queryPageIndex: payload,
            };
        case PAGE_SIZE_CHANGED:
            return {
                ...state,
                queryPageSize: payload,
            };
        case TOTAL_COUNT_CHANGED:
            return {
                ...state,
                totalCount: payload,
            };
        default:
            throw new Error(`Unhandled action type: ${type}`);
    }
};

const WaterBillDayMonthTable = ({ columns, startDate, endDate, path }) => {

    const [{ queryPageIndex, queryPageSize }, dispatch] =
        React.useReducer(reducer, initialState);

    const [token] = useState(localStorage.getItem("token"));
    const [api_host] = useState(process.env.REACT_APP_API_URL || myConstClass.REACT_APP_API_URL);

    const [isLoading, setisLoading] = useState(false)
    const [isSuccess, setisSuccess] = useState(false)
    const [data, setdata] = useState([])

    var startDate = new Date(startDate.toString());
    var endDate = new Date(endDate.toString());
    var startEpoch = startDate.getTime();
    var endEpoch = endDate.getTime();

    useEffect(() => {

        const uri = `${api_host}/hierarchy/bill_data?path=${path}&start=${startEpoch}&end=${endEpoch}`;
        const encodedURI = encodeURI(uri);
        console.log("encoded URI:", encodedURI)
        console.log("decoded URI:", decodeURI(encodedURI))
        setisLoading(true)

        myAxiosInstance(encodedURI,{
            method: `GET`,
            headers: {
                'Authorization': `ApiKey ${token}`,
                'Content-Type': 'application/json'
            }
        })
        .then(res => {
            console.log('data: ', res.data);
            setdata(res.data.response);
            setisSuccess(true)
            setisLoading(false)
        })
        .catch(e => {
            console.log('error: ',e)
            setisLoading(false)
        })
    },[path, startEpoch, endEpoch, token, api_host])

    useEffect(() => {
        console.log('data from fetch:', data);
    }, [data])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        // Get the state from the instance
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            // data: isSuccess ? trimData(data.results) : [],
            // data: isSuccess ? data : [],
            data,
            initialState: {
                pageIndex: queryPageIndex,
                // pageSize: queryPageSize,
            },
            // manualPagination: true, // Tell the usePagination
            // // hook that we'll handle our own data fetching
            // // This means we'll also have to provide our own
            // // pageCount.
            // pageCount: isSuccess ? Math.ceil(data.length / queryPageSize) : null,
        },
        usePagination
    );

    if (isLoading) {
        return <img src={loaderimage} alt="载入中..." />;
    }

    return (
        <>
            {isSuccess ? (
                <>
                    <h4>总数: {data.length}</h4>
                    <Table {...getTableProps()} hover bordered responsive>
                        <thead>
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th {...column.getHeaderProps()}>
                                            {column.render('Header')}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        {page.length === 0 ?
                            <tbody>
                                <tr>
                                    <td colSpan="10000" className="text-left">
                                        <p style={{
                                            "text-align": "left"
                                        }}
                                        >
                                            没有数据显示
                                        </p>
                                    </td>
                                </tr>
                            </tbody>
                            :
                            <tbody {...getTableBodyProps()}>
                                {page.map((row) => {
                                    prepareRow(row);
                                    return (
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map((cell) => (
                                                <td {...cell.getCellProps({
                                                    className: cell.column.className
                                                }
                                                )}>
                                                    {cell.render('Cell')}
                                                </td>
                                            ))}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        }
                    </Table>
                    {page.length > 0 && (
                        <div className={classNames("div-pagination", { "d-none": isLoading })}>
                            <div className="div-pagination-2">
                                <div className="div-pagination-2-2">
                                    每页显示{" "}
                                    <select
                                        className="selectan"
                                        value={pageSize}
                                        onChange={(e) => {
                                            setPageSize(Number(e.target.value));
                                        }}
                                    >
                                        {[10, 20, 30, 50, 100].map((pageSize) => (
                                            <option key={pageSize} value={pageSize}>
                                                {pageSize}
                                            </option>
                                        ))}
                                    </select>{" "}
                                    记录
                                </div>
                            </div>

                            <div className="div-pagination-1">
                                <Pagination className="pagina">
                                    <PaginationItem disabled={!canPreviousPage}>
                                        <PaginationLink onClick={() => gotoPage(0)}>
                                            {"<<"}
                                        </PaginationLink>
                                    </PaginationItem>
                                    <PaginationItem disabled={!canPreviousPage}>
                                        <PaginationLink onClick={() => previousPage()}>
                                            {"<"}
                                        </PaginationLink>
                                    </PaginationItem>
                                    <PaginationItem disabled={!canNextPage}>
                                        <PaginationLink onClick={() => nextPage()}>
                                            {">"}
                                        </PaginationLink>
                                    </PaginationItem>
                                </Pagination>
                            </div>
                        </div>
                    )}
                </>
            ) : <p style={
                {
                    "text-align": "left"
                }
            }>没有数据显示</p>}
        </>
    );
}

export default WaterBillDayMonthTable;
