import React, { useEffect, useState, Fragment } from 'react';
import { useTable, useSortBy, usePagination, useFilters } from 'react-table';
import myAxiosInstance from "../utils/axios-intercept";
import * as myConstClass from '../utils/fileWithConstants';
import classNames from "classnames";
import loaderimage from "./assets/loader-table.gif";
import "../TableComponent/TableDevice.css";
import { nthIndex } from '../utils/commonFunc';
import {
    Input,
    FormGroup,
    Table,
    Pagination,
    PaginationItem,
    PaginationLink,
    Button,
    Alert,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter
} from "reactstrap";


const addLogColumns = [
    {
        Header: "模块号码",
        accessor: "deviceId",
        className: "t-cell-6a text-left",
        disableFilters: false,
        disableSortBy: true,
        Cell: ({ cell: { value } }) =>{
            return String(value)
        }
    },
    {
        Header: "添加时间",
        accessor: "timestampstring",
        className: "t-cell-5a text-left",
        disableFilters: true,
        canFilter: false,
        disableSortBy: false,
        Cell: ({ cell: { value } }) => {
            if (typeof value !== 'undefined') {
                const timeStampIndex = nthIndex(value, ":", 2);
                const displayTimeStamp = value.substring(0, timeStampIndex);
                return (
                    `${displayTimeStamp}`
                )
            } else {
                return "";
            }
        }
    },
    {
        Header: "最新群组",
        accessor: "path",
        className: "t-cell-5a text-left",
        disableFilters: true,
        disableSortBy: true
    },
    {
        Header: "用户",
        accessor: "user",
        className: "t-cell-5a text-left",
        disableFilters: true,
        disableSortBy: true
    }
];

const changeLogColumns = [
    {
        Header: "变更前模块号码",
        accessor: "change_from",
        className: "t-cell-5a text-left",
        disableFilters: false,
        disableSortBy: true
    },
    {
        Header: "变更后模块号码",
        accessor: "change_to",
        className: "t-cell-5a text-left",
        disableFilters: false,
        disableSortBy: true
    },
    {
        Header: "更新时间",
        accessor: "timestampstring",
        className: "t-cell-5a text-left",
        disableFilters: true,
        canFilter: false,
        disableSortBy: false,
        Cell: ({ cell: { value } }) => {
            if (typeof value !== 'undefined') {
                const timeStampIndex = nthIndex(value, ":", 2);
                const displayTimeStamp = value.substring(0, timeStampIndex);
                return (
                    `${displayTimeStamp}`
                )
            } else {
                return "";
            }
        }
    },
    {
        Header: "群组",
        accessor: "path",
        className: "t-cell-5a text-left",
        disableFilters: true,
        disableSortBy: true
    },
    {
        Header: "用户",
        accessor: "user",
        className: "t-cell-5a text-left",
        disableFilters: true,
        disableSortBy: true
    }
];

const deleteLogColumns = [
    {
        Header: "模块号码",
        accessor: "deviceId",
        className: "t-cell-5a text-left",
        disableFilters: false,
        disableSortBy: true
    },
    {
        Header: "删除时间",
        accessor: "timestampstring",
        className: "t-cell-5a text-left",
        disableFilters: true,
        canFilter: false,
        disableSortBy: false,
        Cell: ({ cell: { value } }) => {
            if (typeof value !== 'undefined') {
                const timeStampIndex = nthIndex(value, ":", 2);
                const displayTimeStamp = value.substring(0, timeStampIndex);
                return (
                    `${displayTimeStamp}`
                )
            } else {
                return "";
            }
        }
    },
    {
        Header: "删除前群组",
        accessor: "path",
        className: "t-cell-5a text-left",
        disableFilters: true,
        disableSortBy: true
    },
    {
        Header: "用户",
        accessor: "user",
        className: "t-cell-5a text-left",
        disableFilters: true,
        disableSortBy: true
    }
];

const UpdataTable = () => {
    const [token] = useState(localStorage.getItem("token"));
    const [rootpath] = useState(localStorage.getItem("rootpath"));
    const [api_host] = useState(process.env.REACT_APP_API_URL || myConstClass.REACT_APP_API_URL);

    const [columns, setcolumns] = useState(addLogColumns);
    const [option, setoption] = useState("add");

    const [isLoading] = useState(false);
    const [isSuccess, setisSuccess] = useState(false);
    const [data, setdata] = useState([]);

    const [visible, setVisible] = useState(false);
    const [feedback, setFeedback] = useState("");
    const [alertType, setAlertType] = useState("info");

    const [canPreviousPage, setcanPreviousPage] = useState(false);
    const [canNextPage, setcanNextPage] = useState(true);

    const [fristsortValue, setfristsortValue] = useState();

    const [sortField, setsortField] = useState("");
    const [sortOrder, setsortOrder] = useState("desc");
    const [filterField, setfilterField] = useState("");
    const [filterValue, setfilterValue] = useState("");

    const onDismiss = () => setVisible(false);

    function gotoPage() {
        if (!check_filter(filterField,filterValue)){
            return
        }
        var uri = `${api_host}/Log/editPath`;
        var encodedURI = encodeURI(uri);
        console.log("uri:", uri)
        console.log("encodeURI:", encodedURI)

        let params = get_params(rootpath, pageSize, option, filterField, filterValue, sortField, sortOrder)

        myAxiosInstance(encodedURI, {
            method: 'GET',
            headers: {
                'Authorization': `ApiKey ${token}`,
                'Content-Type': 'application/json'
            },
            params: params
        })
            .then(res => {
                // console.log("response: ",res);
                setdata(res.data);
                setisSuccess(true);
                setcanNextPage(true)
                setcanPreviousPage(false)
            })
            .catch(err => {
                console.log("error： ", err.statustext);
            })

    }

    function previousPage() {
        if (!check_filter(filterField,filterValue)){
            return
        }
        var uri = `${api_host}/Log/editPath`;
        var encodedURI = encodeURI(uri);
        console.log("uri:", uri)
        console.log("encodeURI:", encodedURI)

        let order = 'desc'
        if (sortOrder === "desc" ){
            order = 'asc'
        }

        let searchafterValue = data[0]["sort"][0]

        let params = get_params(rootpath, pageSize, option, filterField, filterValue, sortField, order , searchafterValue)

        myAxiosInstance.get(encodedURI, {
            headers: {
                'Authorization': `ApiKey ${token}`,
                'Content-Type': 'application/json'
            },
            params: params
        })
            .then(res => {
                //console.log("response: ",res);
                setdata(res.data.reverse());
                setcanNextPage(true)
                setisSuccess(true);
                setcanNextPage(true)
                if (res.data[0]["sort"][0] === fristsortValue) {
                    setcanPreviousPage(false)
                }
            })
            .catch(err => {
                console.log("error： ", err.statustext);
            })
    }

    function nextPage() {
        if (!check_filter(filterField,filterValue)){
            return
        }
        var uri = `${api_host}/Log/editPath`;
        var encodedURI = encodeURI(uri);
        console.log("uri:", uri)
        console.log("encodeURI:", encodedURI)

        let searchafterValue = data[9]["sort"][0]

        let params = get_params(rootpath, pageSize, option, filterField, filterValue, sortField, sortOrder, searchafterValue)

        myAxiosInstance.get(encodedURI, {
            headers: {
                'Authorization': `ApiKey ${token}`,
                'Content-Type': 'application/json'
            },
            params: params
        })
            .then(res => {
                //console.log("response: ",res);
                setdata(res.data);
                setcanPreviousPage(true)
                setisSuccess(true);
                if (res.data.length < pageSize) {
                    setcanNextPage(false)
                }
            })
            .catch(err => {
                console.log("error： ", err.statustext);
            })
    }

    function DefaultColumnFilter({column: { filterValue, preFilteredRows, setFilter, id }
    }) {
        return (
            <Input
                type="text"
                value={filterValue || ""}
                onChange={(e) => {
                    setFilter(e.target.value || undefined);
                    setfilterField(id);
                    setfilterValue(e.target.value);
                }}
                placeholder="查询..."
                style={{
                    fontSize: "12px"
                }}
            />
        );
    }

    const defaultColumn = React.useMemo(() => ({
            Filter: DefaultColumnFilter
        }),
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        // canPreviousPage,
        // canNextPage,
        // pageOptions,
        // pageCount,
        // gotoPage,
        // nextPage,
        // previousPage,
        setPageSize,
        setAllFilters,
        setSortBy,
        // Get the state from the instance
        state: { pageIndex, pageSize, sortBy, filters },
    } = useTable(
        {
            columns,
            //data: isSuccess ? trimData(data.results) : [],
            data: isSuccess ? data : [],
            initialState: {
                pageIndex: 0,
                pageSize: 10,
            },
            defaultColumn, // Be sure to pass the defaultColumn option
            manualFilters: true,
            manualPagination: true, // Tell the usePagination
            // hook that we'll handle our own data fetching
            // This means we'll also have to provide our own
            // pageCount.
            manualSortBy: true,
            // pageCount: isSuccess ? Math.ceil(totalCount / queryPageSize) : null,
        },
        useFilters,
        useSortBy,
        usePagination,
    );

    function get_params(path, pageSize, option , filterField, filterValue, sortField, sortOrder, searchafter){
        let params = {
            "path": path,
            "limit": pageSize,
            "option": option
        }
        if (filterField !== "" && filterValue !== ""){
            params["filterField"] = filterField
            params["filterValue"] = filterValue
        }
        if (sortField !== ""){
            params["sortField"] = sortField
        }
        if (sortOrder !== ""){
            params["sortOrder"] = sortOrder
        }
        if (searchafter !== undefined){
            params["searchafterValue"] = searchafter
        }
        console.log(params)

        return params
    }

    function check_filter(filterField, filterValue){
        if (filterValue.length != 0){
            if (filterField === "deviceId" && filterValue.length !== 8){
                console.log()
                return false
            }
            else{
                return true
            }
        }
        else{
            return true
        }
    }

    useEffect(() => {
        if (!check_filter(filterField,filterValue)){
            return
        }

        var uri = `${api_host}/Log/editPath`;
        var encodedURI = encodeURI(uri);
        console.log("uri:", uri)
        console.log("encodeURI:", encodedURI)

        let params = get_params(rootpath, pageSize, option, filterField, filterValue, sortField, sortOrder)

        myAxiosInstance(encodedURI, {
            method: 'GET',
            headers: {
                'Authorization': `ApiKey ${token}`,
                'Content-Type': 'application/json'
            },
            params: params
        })
            .then(res => {
                // console.log("response: ",res);
                setdata(res.data);
                setfristsortValue(res.data[0]["sort"][0])
                setisSuccess(true);
                setcanPreviousPage(false)
                if (res.data.length < pageSize) {
                    setcanNextPage(false)
                } else {
                    setcanNextPage(true)
                }
            })
            .catch(err => {
                console.log("error： ", err.statustext);
            })
    }, [pageSize, option, api_host, token, filterField, filterValue, sortField, sortOrder])

    function handle_option(e){
        if (e.target.value === "add"){
            setoption(e.target.value)
            setcolumns(addLogColumns)
        }
        else if (e.target.value === "change"){
            setoption(e.target.value)
            setcolumns(changeLogColumns)
        }
        else if (e.target.value === "delete"){
            setoption(e.target.value)
            setcolumns(deleteLogColumns)
        }

    }

    useEffect(() => {
        if (sortBy !== null && sortBy.length > 0) {
            setsortField(sortBy[0].id)
            if (sortBy[0].desc){
                setsortOrder('desc')
            }
            else{
                setsortOrder('asc')
            }
        }
    },[sortBy])

    useEffect(() => {
        console.log("data from fetch:", data);
        console.log("data from filters:", filters);
        console.log("data from sortBy:", sortBy);
    }, [data,sortBy,filters])

    if (isLoading) {
        return <img src={loaderimage} alt="载入中..." />;
    }

    return (
        <>
            {isSuccess ? (
                <>
                    <Alert color={alertType} isOpen={visible} toggle={onDismiss}>
                        {feedback}
                    </Alert>
                    <Input
                      type="select"
                      name="searchField"
                      id="searchField"
                      value={option}
                      onChange={handle_option}
                    >
                      <option value="add">导入记录</option>
                      <option value="change">更改记录</option>
                      <option value="delete">删除记录</option>
                    </Input>
                    <Table {...getTableProps()} hover bordered responsive>
                        <thead>
                            {headerGroups.map((headerGroup) => (
                                <>
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map((column) => (
                                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                {column.render('Header')}
                                                {/* Add a sort direction indicator */}
                                                <span>
                                                    {column.isSorted
                                                        ? column.isSortedDesc
                                                            ? " 🔽"
                                                            : " 🔼"
                                                        : ""
                                                    }
                                                </span>
                                            </th>
                                        ))}
                                    </tr>
                                    <tr style={{ backgroundColor: "aliceBlue" }}>
                                        {headerGroup.headers.map((column, index) => (
                                            <th className="tfilter">
                                                {column.canFilter ? (
                                                    <FormGroup className="mb-1">
                                                        {column.render("Filter")}
                                                    </FormGroup>
                                                ) : null}
                                            </th>
                                        ))}
                                    </tr>
                                </>
                            ))}
                        </thead>
                        {page.length === 0 ?
                            <tbody>
                                <tr>
                                    <td colSpan="10000" className="text-left">
                                        <p>没有数据显示</p>
                                    </td>
                                </tr>
                            </tbody>
                            :
                            <tbody {...getTableBodyProps()}>
                                {page.map((row) => {
                                    prepareRow(row);
                                    return (
                                        <tr {...row.getRowProps()} >
                                            {row.cells.map((cell) => (
                                                <td {...cell.getCellProps({
                                                    className: cell.column.className
                                                })}
                                                >
                                                    {cell.render('Cell')}
                                                </td>
                                            )
                                            )}
                                        </tr>
                                    );
                                })}
                            </tbody>

                        }
                    </Table>

                    {page.length > 0 && (
                        <div className={classNames("div-pagination", { "d-none": isLoading })}>
                            <div className="div-pagination-2">
                                <div className="div-pagination-2-2">
                                    每页显示{" "}
                                    <select
                                        className="selectan"
                                        value={pageSize}
                                        onChange={(e) => {
                                            setPageSize(Number(e.target.value));
                                        }}
                                    >
                                        {[10, 20, 30, 50, 100].map((pageSize) => (
                                            <option key={pageSize} value={pageSize}>
                                                {pageSize}
                                            </option>
                                        ))}
                                    </select>{" "}
                                    记录
                                </div>
                            </div>

                            <div className="div-pagination-1">
                                <Pagination className="pagina">
                                    <PaginationItem disabled={!canPreviousPage}>
                                        <PaginationLink onClick={() => gotoPage()}>
                                            {"<<"}
                                        </PaginationLink>
                                    </PaginationItem>
                                    <PaginationItem disabled={!canPreviousPage}>
                                        <PaginationLink onClick={() => previousPage()}>
                                            {"<"}
                                        </PaginationLink>
                                    </PaginationItem>
                                    <PaginationItem disabled={!canNextPage}>
                                        <PaginationLink onClick={() => nextPage()}>
                                            {">"}
                                        </PaginationLink>
                                    </PaginationItem>
                                </Pagination>
                            </div>
                        </div>
                    )}
                </>
            ) : <p>没有数据显示</p>}
        </>
    )
}

export default UpdataTable;
