import React from 'react'
import axios from 'axios';
import { useState, useEffect } from 'react'
import * as myConstClass from '../utils/fileWithConstants';
import myAxiosInstance from '../utils/axios-intercept';

const CancelToken = axios.CancelToken;
let cancel

async function getRate(selectedGroup) {
    const token = localStorage.getItem("token");
    const api_host = process.env.REACT_APP_API_URL || myConstClass.REACT_APP_API_URL;
    const uri = `${api_host}/devices/success_rate`
    var encodedURI = encodeURI(uri);
    const response = await myAxiosInstance.get(encodedURI,{
        headers: {
                'Authorization': `ApiKey ${token}`,
                'Content-Type': 'application/json'
            },
        cancelToken: new CancelToken(function executor(c) {
            cancel = c;
          }),
        params: {path: selectedGroup}
    })
    .then(res => {
        console.log("success_rate Component response: ",res.data); //res catch the while response
        return res.data;
    })
    .catch(err => {
        if (axios.isCancel(err)) {
            console.log("cancelled");
            return "loading"
        } else {
            const errorObject=JSON.parse(JSON.stringify(err));
            console.log("err",errorObject);
            console.log("error： ", err.statustext);
        }
    })
    return response
}

const Success_rate = React.memo(({selectedGroup}) =>{
    const [Success_rate, setSuccess_rate] = useState("")
    //Rewrite the useeffect 
    
    useEffect(() => {
            setSuccess_rate("loading...")
            if (cancel) cancel();
            const fetchdata = async () =>{
            const data = await getRate(selectedGroup);
            setSuccess_rate(data);        
        }
        fetchdata();
    }, [selectedGroup])  
    console.log("Success_rate",Success_rate)
    // console.log("datacount length",datacount.length)    
    return (
        <b>
            {Success_rate}
        </b>            
        )
    }
)


export default Success_rate;