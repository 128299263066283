import React, { useEffect ,useState} from 'react';
import * as myConstClass from '../utils/fileWithConstants';
import Axios from 'axios';
import {
  Alert, 
} from "reactstrap";
import loaderimage from "./assets/loader-table.gif";
import "../TableComponent/TableDevice.css";


const AddRemoveFactory = ({field, imei, batch,callbackParent,apifunction, Value_start, Value_end, TeleProvider}) => {

  const token = localStorage.getItem("token");
  const api_host = process.env.REACT_APP_API_URL || myConstClass.REACT_APP_API_URL;
  var uri;
  var encodedURI;

  const[isLoading,setisLoading]=useState(false)
  const[data,setdata]=useState([])
  const[feedback,setfeedback]=useState("")
  const[alertType,setalertType]=useState("info")
  const[visible,setvisible]=useState(false)

  function onDismiss(){
    setvisible(false)
  }

  var uriString = "";

  //?=CMHK ?=CTC
  useEffect(()=>{    
    // if (TeleProvider !== "") {
    //   uriString = `=${TeleProvider}&`
    // }
    if (imei !== "") {
      uriString = `${uriString}field=${field}&value_start=${imei}`
    }else if (Value_start !== "" && Value_end !== "") {
      uriString = `${uriString}field=${field}&value_start=${Value_start}&value_end=${Value_end}`
    }
    if (apifunction === 'add') {
      uri = `${api_host}/factory/adddevices?${uriString}`+`&batch=`+batch
    }else if (apifunction ==='remove') {
      uri = `${api_host}/factory/removedevices?field=${field}&value=${imei}`
    }
    
    // if (imei !== "") {
    //   uriString = uriString+`&value_start=`+imei
    // }else if(Value_start !== "" && Value_end !== ""){
    //   uriString = uriString+`&value_start=`+Value_start+`&value_end=`+Value_end
    // }
    // if (apifunction === 'add') {     
    //   uri = `${api_host}/factory/adddevices?field=`+uriString+`&batch=`+batch
    // } 
    // else if (apifunction ==='remove') {
    //   uri = `${api_host}/factory/removedevices?field=`+field+`&value=`+imei
    // }
    
  },[field, imei, Value_start,Value_end, batch, apifunction])

  useEffect(()=>{
    encodedURI = encodeURI(uri);
    console.log("uri:", uri)
    console.log("encodeURI:", encodedURI)

    setisLoading(true)
    Axios(encodedURI, {
      method: 'POST', 
      headers: {
        'Authorization': `ApiKey ${token}`,
        'Content-Type': 'application/json'
      },
    })
    .then(res => {
      // console.log("response: ",res);
      setdata(res);
      setisLoading(false);
      setvisible(true);
      setfeedback("成功");
    })
    .catch(err=> {
      console.log ("error： ",err.response.data.error);
      // console.log ("error123： ",err);

      setisLoading(false);
      setvisible(true);
      setalertType("danger")
      setfeedback("錯誤："+err.response.data.error+" ");

      // console.log ("error： ",err);
      // setisLoading(false);
      // setvisible(true);
      // setalertType("danger")
      // setfeedback("錯誤："+err);
    })
  },[])

  useEffect(()=>{
    console.log('data from fetch:', data);
  },[data]) 

  useEffect(()=>{
    callbackParent(field);
  },[])

  if (isLoading) {
    return <img src={loaderimage} alt="载入中..." />;
  }

  return(
    <Alert color={alertType} isOpen={visible} toggle={() => onDismiss()}>
      {feedback}
    </Alert>
  )
}

export default AddRemoveFactory;