import React from 'react'
import { useState, useEffect } from 'react'
import * as myConstClass from '../utils/fileWithConstants';
import myAxiosInstance from '../utils/axios-intercept';

async function getCount(onchoiceChange,selectedGroup) {
    const token = localStorage.getItem("token");
    const api_host = process.env.REACT_APP_API_URL || myConstClass.REACT_APP_API_URL;
    const uri = `${api_host}/devices/alarm`
    var encodedURI = encodeURI(uri);
    const data_tosend = JSON.stringify({ "choice": onchoiceChange, "grp":selectedGroup, "count":"true"})
    const response = await myAxiosInstance.post(encodedURI,data_tosend,{
    headers: {
                'Authorization': `ApiKey ${token}`,
                'Content-Type': 'application/json'
            }
    })
    .then(res => {
        // setDatacount(res.data)
        console.log("in Data COunt Component response: ",res.data); //res catch the while response
        return res.data;
    })
    .catch(err => {
            const errorObject=JSON.parse(JSON.stringify(err));
            console.log("err",errorObject);
            console.log("error： ", err.statustext);
    })
    return response
}
const CountData = React.memo(({onchoiceChange,selectedGroup}) => {
    console.log("you grp tocountapi" ,selectedGroup )   
    console.log("you choice tocountapi" ,onchoiceChange )
    const [datacount,setDatacount] = useState("")
    //Rewrite the useeffect 
    useEffect(() => {
            const fetchdata = async () =>{
            const data = await getCount(onchoiceChange,selectedGroup);
            setDatacount(data);        
        }
        fetchdata();
    }, [onchoiceChange,selectedGroup])   
    console.log("datacount",datacount)
    // console.log("datacount length",datacount.length)    
    return (
        <b>
            {datacount}
        </b>            
        )
    }
)
// export default React.memo(CountData)
export default CountData

