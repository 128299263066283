import React, { useEffect, useState, Fragment } from 'react';
import { useTable, useSortBy, usePagination, useFilters } from 'react-table';
import myAxiosInstance from "../utils/axios-intercept";
import * as myConstClass from '../utils/fileWithConstants';
import classNames from "classnames";
import loaderimage from "./assets/loader-table.gif";
import "../TableComponent/TableDevice.css";
import {
    Input,
    FormGroup,
    Table,
    Pagination,
    PaginationItem,
    PaginationLink,
    Button,
    Alert,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter
} from "reactstrap";
import * as matchSorter from "match-sorter";
import Updata from '../GroupComponent/Updata';

const initialState = {
    queryPageIndex: 0,
    queryPageSize: myConstClass.PAGESIZE,
    totalCount: null,
    querySortBy: null,
    queryFilters: null
    //  currentFilter: null
};

const PAGE_CHANGED = 'PAGE_CHANGED';
const PAGE_SIZE_CHANGED = 'PAGE_SIZE_CHANGED';
const TOTAL_COUNT_CHANGED = 'TOTAL_COUNT_CHANGED';
const SORT_CHANGED = 'SORT_CHANGED';
const FILTER_CHANGED = 'FILTER_CHANGED';

//const reducer = (state, { type, payload, currentFilter }) => {
const reducer = (state, { type, payload }) => {
    switch (type) {
        case PAGE_CHANGED:
            return {
                ...state,
                queryPageIndex: payload,
                //currentFilter: currentFilter
            };
        case PAGE_SIZE_CHANGED:
            return {
                ...state,
                queryPageSize: payload,
                //currentFilter: currentFilter
            };
        case TOTAL_COUNT_CHANGED:
            return {
                ...state,
                totalCount: payload,
                //currentFilter: currentFilter
            };
        case SORT_CHANGED:
            return {
                ...state,
                querySortBy: payload,
                //currentFilter: currentFilter
            };
        case FILTER_CHANGED:
            return {
                ...state,
                queryFilters: payload,
                //currentFilter: currentFilter
            }
        default:
            throw new Error(`Unhandled action type: ${type}`);
    }
};

function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

fuzzyTextFilterFn.autoRemove = (val) => !val;

const UpdataTable = ({ columns, selectedGroup, lastTargetFilter, lastTargetFilterValue, parentCallback, meterDiamList, billingTypeList }) => {

    //const [{ queryPageIndex, queryPageSize, totalCount, querySortBy, queryFilters, currentFilter }, dispatch] =
    const [{ queryPageIndex, queryPageSize, totalCount, querySortBy, queryFilters }, dispatch] =
        React.useReducer(reducer, initialState);

    const [token] = useState(localStorage.getItem("token"));
    const [username] = useState(localStorage.getItem("username"));
    const [api_host] = useState(process.env.REACT_APP_API_URL || myConstClass.REACT_APP_API_URL);

    const [isLoading] = useState(false);
    const [isSuccess, setisSuccess] = useState(false);
    const [data, setdata] = useState([]);

    const [visible, setVisible] = useState(false);
    const [feedback, setFeedback] = useState();
    const [alertType, setAlertType] = useState("info");

    const [canPreviousPage, setcanPreviousPage] = useState(false);
    const [canNextPage, setcanNextPage] = useState(true);

    const [dataCount, setdataCount] = useState(0);
    const [fristsortValue, setfristsortValue] = useState();

    const [sortField, setsortField] = useState("");
    const [sortOrder, setsortOrder] = useState("");
    const [filterField, setfilterField] = useState("");
    const [filterValue, setfilterValue] = useState("");

    const [cellValue, setcellValue] = useState();
    const [column, setcolumn] = useState(-1);
    const [UpdataValue, setUpdataValue] = useState("");
    const [UpdataField, setUpdataField] = useState("")
    const [rowImei, setrowImei] = useState();
    const [RefreshUri, setRefreshUri] = useState();
    const [RefreshType, setRefreshType] = useState();

    const [isEdit, setisEdit] = useState(false);
    const [EditId, setEditId] = useState("");
    const [onClickRow, setonClickRow] = useState("");
    const [ErrMsg, setErrMsg] = useState("");

    const [isDelete, setisDelete] = useState(false);
    const [ischange, setisChange] = useState(false)
    const [Cellvalue, setCellvalue] = useState("")
    // const [onClickRow, setonClickRow] = useState("");
    // const [editErrMsg, seteditErrMsg] = useState("");

    const onDismiss = () => setVisible(false);

    function gotoPage() {
        var uri = `${api_host}/devices/searchafter?path=${selectedGroup}&limit=${pageSize}`;
        if (filterField !== "" && filterValue !== "") {
            uri = `${uri}&filterField=${filterField}&filterValue=${filterValue}`;
        }
        if (sortField !== "" && sortOrder !== "") {
            uri = `${uri}&sortField=${sortField}&sortOrder=${sortOrder}`;
        }
        var encodedURI = encodeURI(uri);
        console.log("uri:", uri)
        console.log("encodeURI:", encodedURI)

        myAxiosInstance(encodedURI, {
            method: 'GET',
            headers: {
                'Authorization': `ApiKey ${token}`,
                'Content-Type': 'application/json'
            },
        })
            .then(res => {
                // console.log("response: ",res);
                setdata(res.data);
                setdataCount(res.data.length);
                setisSuccess(true);
                setcanNextPage(true)
                setcanPreviousPage(false)
                setRefreshType("gotoPage")
                setRefreshUri(uri)
            })
            .catch(err => {
                console.log("error： ", err.statustext);
            })
    }

    function previousPage() {
        var uri = `${api_host}/devices/searchafter?path=${selectedGroup}&limit=${pageSize}`;
        if (filterField !== "" && filterValue !== "") {
            uri = `${uri}&filterField=${filterField}&filterValue=${filterValue}`;
        }
        if (sortField !== "" && sortOrder !== "") {
            if (sortOrder === "asc") {
                uri = `${uri}&sortField=${sortField}&searchafterValue=${data[0]["sort"][1]}&sortValue=${data[0]["sort"][0]}&sortOrder=desc`;
            }
            else if (sortOrder === "desc") {
                uri = `${uri}&sortField=${sortField}&searchafterValue=${data[0]["sort"][1]}&sortValue=${data[0]["sort"][0]}&sortOrder=asc`;
            }
        }

        else {
            uri = `${uri}&searchafterValue=${data[0]["sort"][0]}&sortOrder=desc`;
        }
        var encodedURI = encodeURI(uri);
        console.log("uri:", uri)
        console.log("encodeURI:", encodedURI)

        myAxiosInstance(encodedURI, {
            method: 'GET',
            headers: {
                'Authorization': `ApiKey ${token}`,
                'Content-Type': 'application/json'
            },
        })
            .then(res => {
                //console.log("response: ",res);
                setdata(res.data.reverse());
                setdataCount(res.data.length);
                setcanNextPage(true)
                setisSuccess(true);
                setcanNextPage(true)
                setRefreshType("previousPage")
                setRefreshUri(uri)
                if (res.data[0]["sort"][0] === fristsortValue) {
                    setcanPreviousPage(false)
                }
            })
            .catch(err => {
                console.log("error： ", err.statustext);
            })
    }

    function nextPage() {
        var uri = `${api_host}/devices/searchafter?path=${selectedGroup}&limit=${pageSize}`;
        if (filterField !== "" && filterValue !== "") {
            uri = `${uri}&filterField=${filterField}&filterValue=${filterValue}`;
        }
        if (sortField !== "" && sortOrder !== "") {
            uri = `${uri}&sortField=${sortField}&searchafterValue=${data[dataCount - 1]["sort"][1]}&sortValue=${data[dataCount - 1]["sort"][0]}&sortOrder=${sortOrder}`;
        }
        else {
            uri = `${uri}&searchafterValue=${data[dataCount - 1]["sort"][0]}&sortOrder=asc`;
        }
        var encodedURI = encodeURI(uri);
        console.log("uri:", uri)
        console.log("encodeURI:", encodedURI)

        myAxiosInstance(encodedURI, {
            method: 'GET',
            headers: {
                'Authorization': `ApiKey ${token}`,
                'Content-Type': 'application/json'
            },
        })
            .then(res => {
                //console.log("response: ",res);
                setdata(res.data);
                setdataCount(res.data.length);
                setcanPreviousPage(true)
                setisSuccess(true);
                setRefreshType("nextPage")
                setRefreshUri(uri)
                if (res.data.length < pageSize) {
                    setcanNextPage(false)
                }
            })
            .catch(err => {
                console.log("error： ", err.statustext);
            })
    }

    async function Updated() {
        var uri = `${api_host}/hierarchy/updata?UpdataField=${UpdataField}&UpdataValue=${UpdataValue}&imei=${rowImei}`;
        var encodedURI = encodeURI(uri);
        console.log("uri:", uri)
        console.log("encodeURI:", encodedURI)

        await myAxiosInstance(encodedURI, {
            method: 'POST',
            headers: {
                'Authorization': `ApiKey ${token}`,
                'Content-Type': 'application/json'
            },
        })
            .then(res => {
                console.log(res)
            })
            .catch(err => {
                console.log("error： ", err.statustext);
            })
        uri = RefreshUri
        encodedURI = encodeURI(uri);
        console.log("uri:", uri)
        console.log("encodeURI:", encodedURI)

        await myAxiosInstance(encodedURI, {
            method: 'GET',
            headers: {
                'Authorization': `ApiKey ${token}`,
                'Content-Type': 'application/json'
            },
        })
            .then(res => {
                //console.log("response: ",res);
                setdataCount(res.data.length);
                setisSuccess(true);
                if (RefreshType === "nextPage" || RefreshType === "gotoPage") {
                    setdata(res.data);
                }
                else if (RefreshType === "previousPage") {
                    setdata(res.data.reverse());
                }
                else {
                    setdata(res.data)
                }
                if (res.data.length < pageSize) {
                    setcanNextPage(false)
                    setcanPreviousPage(true)
                }
                if (res.data[0]["sort"][0] === fristsortValue) {
                    setcanPreviousPage(false)
                    setcanNextPage(true)
                }
                else {
                    setcanNextPage(true)
                    setcanPreviousPage(true)
                }
            })
            .catch(err => {
                console.log("error： ", err.statustext);
            })
    }

    const handleDeleteClick = (row) => {
        DeleteDevice()
        setisDelete(!isDelete)
    }

    async function DeleteDevice(){
        console.log(onClickRow.original.imei)
        const imei = onClickRow.original.imei
        var uri = `${api_host}/hierarchy/removeDevice?imei=${imei}`;
        var encodedURI = encodeURI(uri);
        console.log("uri:", uri)
        console.log("encodeURI:", encodedURI)

        await myAxiosInstance(encodedURI, {
            method: 'DELETE',
            headers: {
                'Authorization': `ApiKey ${token}`,
                'Content-Type': 'application/json'
            },
            params: {
                "imei": imei,
                "username": username
            }
        })
            .then(res => {
                console.log(res)
            })
            .catch(err => {
                console.log("error： ", err.statustext);
            })
        uri = RefreshUri
        encodedURI = encodeURI(uri);
        console.log("uri:", uri)
        console.log("encodeURI:", encodedURI)
    
        await myAxiosInstance(encodedURI, {
            method: 'GET',
            headers: {
                'Authorization': `ApiKey ${token}`,
                'Content-Type': 'application/json'
            },
        })
            .then(res => {
                //console.log("response: ",res);
                setdataCount(res.data.length);
                setisSuccess(true);
                if (RefreshType === "nextPage" || RefreshType === "gotoPage") {
                    setdata(res.data);
                }
                else if (RefreshType === "previousPage") {
                    setdata(res.data.reverse());
                }
                else {
                    setdata(res.data)
                }
                if (res.data.length < pageSize) {
                    setcanNextPage(false)
                    setcanPreviousPage(true)
                }
                if (res.data[0]["sort"][0] === fristsortValue) {
                    setcanPreviousPage(false)
                    setcanNextPage(true)
                }
                else {
                    setcanNextPage(true)
                    setcanPreviousPage(true)
                }
            })
            .catch(err => {
                console.log("error： ", err.statustext);
            })
    }

    function handleEditClick(){
        const regex = /^[0-9\b]+$/;
        if (!regex.test(EditId)){
            setErrMsg("水表号码非数字")
        }
        else if(EditId.toString().length !== 8){
            setErrMsg("水表号码必须是8位数字")
        }
        else if(EditId == onClickRow.original.deviceId){
            setErrMsg("水表号码相同")
        }
        else{
            editdeviceId()
            setisEdit(!isEdit)
        }
    }

    function handle_edit_err(err){
        if (err.exception === "PathExistException"){
            setFeedback(`变更id已存在群组 Id: ${err.error.deviceId} 群组: ${err.error.path}`)
        }
        else if (err.exception === "IdNotExistException"){
            setFeedback(`变更id不存在 ${err.error.deviceId}`)
        }
        else{
            setFeedback(err.error)
        }

    }

    async function editdeviceId(){
        var uri = `${api_host}/device/changeId`;
        var encodedURI = encodeURI(uri);
        console.log("uri:", uri)
        console.log("encodeURI:", encodedURI)


        await myAxiosInstance.post(encodedURI,{},{
                headers: {
                'Authorization': `ApiKey ${token}`,
                'Content-Type': 'application/json'
                },
                params: {
                    "deviceId" : onClickRow.original.deviceId,
                    "changeId": EditId,
                    "username": username
                }
            }
        )
            .then(res => {
                console.log(res)
                setFeedback("success change")
                setAlertType("info")
                setVisible(true)
            })
            .catch(err => {
                console.log("error： ", err.response.data);
                handle_edit_err(err.response.data)
                setAlertType("danger")
                setVisible(true)
            })

        uri = RefreshUri
        encodedURI = encodeURI(uri);
        console.log("uri:", uri)
        console.log("encodeURI:", encodedURI)

        await myAxiosInstance(encodedURI, {
            method: 'GET',
            headers: {
                'Authorization': `ApiKey ${token}`,
                'Content-Type': 'application/json'
            },
        })
            .then(res => {
                //console.log("response: ",res);
                setdataCount(res.data.length);
                setisSuccess(true);
                if (RefreshType === "nextPage" || RefreshType === "gotoPage") {
                    setdata(res.data);
                }
                else if (RefreshType === "previousPage") {
                    setdata(res.data.reverse());
                }
                else {
                    setdata(res.data)
                }
                if (res.data.length < pageSize) {
                    setcanNextPage(false)
                    setcanPreviousPage(true)
                }
                if (res.data[0]["sort"][0] === fristsortValue) {
                    setcanPreviousPage(false)
                    setcanNextPage(true)
                }
                else {
                    setcanNextPage(true)
                    setcanPreviousPage(true)
                }
            })
            .catch(err => {
                console.log("error： ", err.statustext);
            })

    }

    const [lastTarget, setLastTarget] = useState("");
    const [lastTargetValue, setLastTargetValue] = useState("");

    function DefaultColumnFilter({
        column: { filterValue, preFilteredRows, setFilter, id }
    }) {

        return (
            <Input
                type="text"
                value={filterValue || ""}
                onChange={(e) => {
                    setFilter(e.target.value || undefined);
                    setLastTarget(id);
                    setLastTargetValue(e.target.value);
                    parentCallback(id);
                }}
                placeholder="查询..."
                style={{
                    fontSize: "12px"
                }}
            />
        );
    }

    const defaultColumn = React.useMemo(
        () => ({
            Filter: DefaultColumnFilter
        }),
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        // canPreviousPage,
        // canNextPage,
        // pageOptions,
        // pageCount,
        // gotoPage,
        // nextPage,
        // previousPage,
        setPageSize,
        setAllFilters,
        setSortBy,
        // Get the state from the instance
        state: { pageIndex, pageSize, sortBy, filters },
    } = useTable(
        {
            columns,
            //data: isSuccess ? trimData(data.results) : [],
            data: isSuccess ? data : [],
            initialState: {
                pageIndex: queryPageIndex,
                pageSize: queryPageSize,
            },
            defaultColumn, // Be sure to pass the defaultColumn option
            manualFilters: true,
            manualPagination: true, // Tell the usePagination
            // hook that we'll handle our own data fetching
            // This means we'll also have to provide our own
            // pageCount.
            manualSortBy: true,
            // pageCount: isSuccess ? Math.ceil(totalCount / queryPageSize) : null,
        },
        useFilters,
        useSortBy,
        usePagination,
    );

    const handleOnChange = (e) => {
        console.log(e.target.value)
        setUpdataValue(e.target.value)
    }

    const handleKeyPressed = (e) => {
        //console.log("handleKeyPressed ",e.key);
        if (e.key === "Enter" && e.target.value !== Cellvalue) {
            console.log("handleKeyPressed ENTER");
            setisChange(true)
            setcolumn(-1);
        }else if(e.key === "Enter" && e.target.value === Cellvalue){
            console.log("Cencel")
            setUpdataField("")
            setUpdataValue("")
            setcolumn(-1);
        }
    }

    const handleCellClick = (cell, row, column_num) => {
        setcellValue(cell.row.id)
        setcolumn(column_num)
        if (cell.column.Header === "客户地址" || cell.column.Header === "用户地址") {
            setUpdataValue(cell.value)
            setCellvalue(cell.value)
            setUpdataField("MeterLocation")
            setrowImei(row.original.imei)
            console.log("MeterLocation: ", cell.value)
            console.log('Device ID:', row.original.imei);
        }
        if (cell.column.Header === "客户编号") {
            setUpdataValue(cell.value)
            setCellvalue(cell.value)
            setUpdataField("CustomerBillingID_str")
            setrowImei(row.original.imei)
            console.log("CustomerBillingID_str: ", cell.value)
            console.log('Device ID:', row.original.imei);
        }
        if (cell.column.Header === "用户名") {
            setUpdataValue(cell.value)
            setCellvalue(cell.value)
            setUpdataField("CustomerBillingName")
            setrowImei(row.original.imei)
            console.log("CustomerBillingName: ", cell.value)
            console.log('Device ID:', row.original.imei);
        }
        if (cell.column.Header === "基表号") {
            setUpdataValue(cell.value)
            setCellvalue(cell.value)
            setUpdataField("MeterBodyId")
            setrowImei(row.original.imei)
            console.log("MeterBodyId: ", cell.value)
            console.log('Device ID:', row.original.imei);
        }
        if (cell.column.Header === "水表口径 (DN)" || cell.column.Header === "口径") {
            setUpdataValue(cell.value)
            setCellvalue(cell.value)
            setUpdataField("MeterBodyDiameterString")
            setrowImei(row.original.imei)
            console.log("MeterBodyDiameterString: ", cell.value)
            console.log('Device ID:', row.original.imei);
        }
        if (cell.column.Header === "用户性质") {
            setUpdataValue(cell.value)
            setCellvalue(cell.value)
            setUpdataField("CustomerBillingType")
            setrowImei(row.original.imei)
            console.log("CustomerBillingType: ", cell.value)
            console.log('Device ID:', row.original.imei);
        }
    }

    useEffect(() => {
        dispatch({ type: PAGE_CHANGED, payload: pageIndex });
    }, [pageIndex]);

    useEffect(() => {
        if (data?.count) {
            dispatch({
                type: TOTAL_COUNT_CHANGED,
                payload: data.count,
            });
        }
    }, [data?.count]);

    useEffect(() => {
        console.log('selected group updated', selectedGroup);

        //when a new path is selected, reset any filters or sort ordering 
        setAllFilters([]);
        setSortBy([]);

        Object.entries(headerGroups).map((headerGroup) => {
            headerGroup[1].headers.forEach((column, index) => {
                if (column.canFilter) {
                    if (column.filterValue !== undefined && column.filterValue !== "") {
                        column.setFilter("");
                        column.filterValue = "";
                    }
                }
            });

        })

        //direct user back to the first page of the table

    }, [selectedGroup, setAllFilters, setSortBy])

    useEffect(() => {
        console.log('sortBy has changed in the UI:', sortBy);
        dispatch({ type: SORT_CHANGED, payload: sortBy });
    }, [sortBy])

    useEffect(() => {
        console.log('filter has changed in the UI:', filters);

        if (lastTargetFilter !== "" && lastTargetFilterValue !== "") {

            //filter has been selected from dropdown, override any filters previously entered in other columns
            setLastTarget("");

            Object.entries(headerGroups).map((headerGroup) => {
                headerGroup[1].headers.forEach((column, index) => {
                    if (column.canFilter) {
                        if (column.filterValue !== undefined && column.filterValue !== "" && column.id !== lastTargetFilter) {
                            column.setFilter("");
                            column.filterValue = "";
                        }
                    }
                });

            })
        } else if (lastTarget !== "" && lastTargetValue !== "") {
            //filter has been entered in textbox, override any filters previously entered in other columns

            Object.entries(headerGroups).map((headerGroup) => {
                headerGroup[1].headers.forEach((column, index) => {
                    if (column.canFilter) {
                        if (column.filterValue !== undefined && column.filterValue !== "" && column.id !== lastTarget) {
                            column.setFilter("");
                            column.filterValue = "";
                        }
                    }
                });

            })

        }

        //dispatch({type: FILTER_CHANGED, payload: filters, currentFilter: lastTargetFilter || lastTarget});
        dispatch({ type: FILTER_CHANGED, payload: filters });
    }, [filters])

    useEffect(() => {
        if (querySortBy !== null && querySortBy.length > 0) {
            console.log('sortBy', querySortBy);

            if (querySortBy[0].id === "lastRecord.timestampstring") {
                setsortField("timestampstring");
              }
              else{
                setsortField(querySortBy[0].id);
              }
            if (querySortBy[0].desc) {
                setsortOrder("desc")
            } else {
                setsortOrder("asc")
            }
        }
        else {
            setsortField("");
            setsortOrder("");
        }

        if (queryFilters !== null && queryFilters.length > 0) {

            var filterIndex = 0;

            for (let i = 0; i < queryFilters.length; i++) {
                if (queryFilters[i].value.trim() !== "") {
                    filterIndex = i;
                }
            }

            console.log("using this as the filter:", queryFilters[filterIndex].id);

            if (queryFilters[filterIndex].id === "deviceId") {

                //wait till minimum of 8 characters has been entered before setting deviceId filter in querystring

                if (queryFilters[filterIndex].value.trim().length >= 8) {
                    setfilterField(queryFilters[filterIndex].id);
                    setfilterValue(queryFilters[filterIndex].value.trim());
                }

            }

            if (queryFilters[filterIndex].id === "CustomerBillingID_str") {

                //wait till minimum of 6 characters has been entered before setting CustomerBillingId filter in querystring

                if (queryFilters[filterIndex].value.trim().length >= 1) {
                    setfilterField(queryFilters[filterIndex].id);
                    setfilterValue(queryFilters[filterIndex].value.trim());
                }

            }

            if (queryFilters[filterIndex].id === "oneNetDevieId") {

                //wait till minimum of 9 characters has been entered before setting oneNetDevieId filter in querystring

                if (queryFilters[filterIndex].value.trim().length >= 9) {
                    setfilterField(queryFilters[filterIndex].id);
                    setfilterValue(queryFilters[filterIndex].value.trim());
                }

            }

            if (queryFilters[filterIndex].id === "imei") {

                //wait till minimum of 15 characters has been entered before setting imei filter in querystring

                if (queryFilters[filterIndex].value.trim().length >= 15) {
                    setfilterField(queryFilters[filterIndex].id);
                    setfilterValue(queryFilters[filterIndex].value.trim());
                }

            }


            if (queryFilters[filterIndex].id === "CustomerBillingType" || queryFilters[filterIndex].id === "MeterBodyDiameterString") {
                setfilterField(queryFilters[filterIndex].id);
                setfilterValue(queryFilters[filterIndex].value.trim());
            }

            if (queryFilters[filterIndex].id === "lastRecord.hdralarmStatus") {
                setfilterField(queryFilters[filterIndex].id);
                setfilterValue(queryFilters[filterIndex].value.trim());
            }

        }
        else {
            setfilterField("");
            setfilterValue("");
        }

    }, [queryFilters, querySortBy, selectedGroup])

    useEffect(() => {
        var uri = `${api_host}/devices/searchafter?path=${selectedGroup}&limit=${pageSize}`;
        if (filterField !== "" && filterValue !== "") {
            uri = `${uri}&filterField=${filterField}&filterValue=${filterValue}`;
        }
        if (sortField !== "" && sortOrder !== "") {
            uri = `${uri}&sortField=${sortField}&sortOrder=${sortOrder}`;
        }
        var encodedURI = encodeURI(uri);
        console.log("uri:", uri)
        console.log("encodeURI:", encodedURI)

        myAxiosInstance(encodedURI, {
            method: 'GET',
            headers: {
                'Authorization': `ApiKey ${token}`,
                'Content-Type': 'application/json'
            },
        })
            .then(res => {
                // console.log("response: ",res);
                setdata(res.data);
                setdataCount(res.data.length);
                setfristsortValue(res.data[0]["sort"][0])
                setisSuccess(true);
                setcanPreviousPage(false)
                setRefreshUri(uri)
                if (res.data.length < pageSize) {
                    setcanNextPage(false)
                } else {
                    setcanNextPage(true)
                }
            })
            .catch(err => {
                console.log("error： ", err.statustext);
            })
    }, [selectedGroup, pageSize, api_host, token, filterField, filterValue, sortField, sortOrder])

    useEffect(() => {
        console.log("data from fetch:", data);
    }, [data])

    if (isLoading) {
        return <img src={loaderimage} alt="载入中..." />;
    }

    return (
        <>
            {isSuccess ? (
                <>  
                    <Alert color={alertType} isOpen={visible} toggle={onDismiss}>
                        {feedback}
                    </Alert>
                    <Table {...getTableProps()} hover bordered responsive>
                        <thead>
                            {headerGroups.map((headerGroup) => (
                                <>
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        <th className="t-cell-1 text-mid">{"删除"}</th>
                                        <th className="t-cell-1 text-mid">{"ID变更"}</th>
                                        {headerGroup.headers.map((column) => (
                                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                {column.render('Header')}
                                                {/* Add a sort direction indicator */}
                                                <span>
                                                    {column.isSorted
                                                        ? column.isSortedDesc
                                                            ? " 🔽"
                                                            : " 🔼"
                                                        : ""
                                                    }
                                                </span>
                                            </th>
                                        ))}
                                    </tr>
                                    <tr style={{ backgroundColor: "aliceBlue" }}>
                                        <th>{" "}</th>
                                        <th>{" "}</th>
                                        {headerGroup.headers.map((column, index) => (
                                            <th className="tfilter">
                                                {column.canFilter ? (
                                                    <FormGroup className="mb-1">
                                                        {column.render("Filter")}
                                                    </FormGroup>
                                                ) : null}
                                            </th>
                                        ))}
                                    </tr>
                                </>
                            ))}
                        </thead>
                        {page.length === 0 ?
                            <tbody>
                                <tr>
                                    <td colSpan="10000" className="text-left">
                                        <p>没有数据显示</p>
                                    </td>
                                </tr>
                            </tbody>
                            :
                            <tbody {...getTableBodyProps()}>
                                {page.map((row) => {
                                    prepareRow(row);
                                    return (
                                        <tr {...row.getRowProps()} >
                                            <td className="t-cell-1 text-left">
                                                <Button
                                                    type='delete'
                                                    onClick={() => {
                                                        setisDelete(true)
                                                        setonClickRow(row)
                                                    }}
                                                    >
                                                        删除
                                                </Button>
                                            </td>
                                            <td className="t-cell-1 text-left">
                                                <Button
                                                    type='edit'
                                                    onClick={() => {
                                                        setisEdit(true)
                                                        setonClickRow(row)
                                                    }}
                                                    >
                                                        ID变更
                                                </Button>
                                            </td>
                                            {row.cells.map((cell, j) => (
                                                <Fragment>
                                                    {(cell.column.Header === "客户地址" ||
                                                        cell.column.Header === "用户地址" ||
                                                        cell.column.Header === "客户编号" ||
                                                        cell.column.Header === "用户名" ||
                                                        cell.column.Header === "基表号") && cellValue === cell.row.id && column === j ? (
                                                        <td {...cell.getCellProps({
                                                            className: cell.column.className
                                                        })}
                                                            onClick={() => handleCellClick(cell, row, j)}
                                                        >
                                                            {cellValue === cell.row.id && (
                                                                <input
                                                                    type="text"
                                                                    name="Updata_text"
                                                                    id="Updata_text"
                                                                    value={UpdataValue}
                                                                    onKeyPress={(e) => handleKeyPressed(e)}
                                                                    onChange={(value) => handleOnChange(value)}
                                                                >
                                                                </input>
                                                            )}
                                                        </td>
                                                    ) : (
                                                        cell.column.Header === "水表口径 (DN)" ||
                                                        cell.column.Header === "口径" ||
                                                        cell.column.Header === "用户性质") && cellValue === cell.row.id && column === j ? (
                                                        <td {...cell.getCellProps({
                                                            className: cell.column.className
                                                        })}
                                                            onClick={() => handleCellClick(cell, row, j)}
                                                        >
                                                            {cell.column.Header === "水表口径 (DN)" ||
                                                                cell.column.Header === "口径" &&
                                                                cellValue === cell.row.id ? (
                                                                <select
                                                                    name="Updata_select"
                                                                    id="Updata_select"
                                                                    value={UpdataValue}
                                                                    onChange={(value) => handleOnChange(value)}
                                                                    onKeyPress={(e) => handleKeyPressed(e)}
                                                                >
                                                                    <option key="" value="">全选</option>
                                                                    {Object.entries(meterDiamList).map(([key, value]) =>
                                                                        <option key={key} value={key}>
                                                                            {key}
                                                                        </option>
                                                                    )}
                                                                </select>
                                                            ) : cell.column.Header === "用户性质" && cellValue === cell.row.id && column === j && (
                                                                <select
                                                                    name="Updata_select"
                                                                    id="Updata_select"
                                                                    value={UpdataValue}
                                                                    onChange={(value) => handleOnChange(value)}
                                                                    onKeyPress={(e) => handleKeyPressed(e)}
                                                                >
                                                                    <option key="" value="">全选</option>
                                                                    {Object.entries(billingTypeList).map(([key, value]) =>
                                                                        <option key={key} value={key}>
                                                                            {key}
                                                                        </option>
                                                                    )}
                                                                </select>
                                                            )}
                                                        </td>
                                                    ) : (
                                                        <td {...cell.getCellProps({
                                                            className: cell.column.className
                                                        })}
                                                            onClick={() => handleCellClick(cell, row, j)}
                                                        >
                                                            {cell.render('Cell')}
                                                        </td>
                                                    )}
                                                </Fragment>
                                            ))}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        }
                    </Table>
                    <Modal isOpen={ischange} toggle={() => setisChange(!ischange)} centered>
                        <ModalHeader toggle={() => {
                            setisChange(!ischange)
                            setErrMsg("")}}>
                            变更确认
                        </ModalHeader>
                        <ModalBody>
                            {UpdataValue === "" ? UpdataValue :  "是否变更  "+Cellvalue+"  为 "+UpdataValue+"?"} 
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={() => {
                                Updated()
                                setisChange(!ischange)
                             }}>
                            确认
                            </Button>{' '}
                            <Button color="secondary" onClick={() => {
                                setisChange(!ischange)
                                setUpdataField("")
                                setUpdataValue("")
                                setErrMsg("")
                                }}>
                            取消
                            </Button>
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={isDelete} toggle={() => setisDelete(!isDelete)} centered>
                        <ModalHeader toggle={() => {
                            setisDelete(!isDelete)
                            setErrMsg("")}}>
                            删除水表号码 
                        </ModalHeader>
                        <ModalBody>
                            {onClickRow === "" ? onClickRow : "是否删除水表号码 "+onClickRow.original.deviceId+"?"} 
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={() => {handleDeleteClick()}}>
                            确认
                            </Button>{' '}
                            <Button color="secondary" onClick={() => {
                                setisDelete(!isDelete)
                                setErrMsg("")
                                }}>
                            取消
                            </Button>
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={isEdit} toggle={() => setisEdit(!isEdit)} centered>
                        <ModalHeader toggle={() => {
                            setisEdit(!isEdit)
                            setEditId("")
                            setErrMsg("")}}>
                            更改水表号码
                        </ModalHeader>
                        <ModalBody>
                            {onClickRow === "" ? onClickRow : "更改水表号码 "+onClickRow.original.deviceId+" 为:"} 
                            <Input 
                                type='editId' 
                                value={EditId} 
                                onChange={(e) => setEditId(e.target.value)}
                            />
                            {ErrMsg !== ""  && <h3 className="text-danger">{ErrMsg}</h3>}
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={() => {handleEditClick()}}>
                            确认
                            </Button>{' '}
                            <Button color="secondary" onClick={() => {
                                setisEdit(!isEdit)
                                setEditId("")
                                setErrMsg("")
                                }}>
                            取消
                            </Button>
                        </ModalFooter>
                    </Modal>
                    
                    {page.length > 0 && (
                        <div className={classNames("div-pagination", { "d-none": isLoading })}>
                            <div className="div-pagination-2">
                                <div className="div-pagination-2-2">
                                    每页显示{" "}
                                    <select
                                        className="selectan"
                                        value={pageSize}
                                        onChange={(e) => {
                                            setPageSize(Number(e.target.value));
                                        }}
                                    >
                                        {[10, 20, 30, 50, 100].map((pageSize) => (
                                            <option key={pageSize} value={pageSize}>
                                                {pageSize}
                                            </option>
                                        ))}
                                    </select>{" "}
                                    记录
                                </div>
                            </div>

                            <div className="div-pagination-1">
                                <Pagination className="pagina">
                                    <PaginationItem disabled={!canPreviousPage}>
                                        <PaginationLink onClick={() => gotoPage()}>
                                            {"<<"}
                                        </PaginationLink>
                                    </PaginationItem>
                                    <PaginationItem disabled={!canPreviousPage}>
                                        <PaginationLink onClick={() => previousPage()}>
                                            {"<"}
                                        </PaginationLink>
                                    </PaginationItem>
                                    <PaginationItem disabled={!canNextPage}>
                                        <PaginationLink onClick={() => nextPage()}>
                                            {">"}
                                        </PaginationLink>
                                    </PaginationItem>
                                </Pagination>
                            </div>
                        </div>
                    )}
                </>
            ) : <p>没有数据显示</p>}
        </>
    )
}

export default UpdataTable;
