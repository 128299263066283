import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Alert, Button, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { withRouter } from "react-router";
import myAxiosInstance from '../utils/axios-intercept';
import GroupMenuNew from "../GroupMenuNew";
//import './ModifyGroup.css';
import UpdataTable from '../PagingComponent/UpdataTable';
import * as myConstClass from '../utils/fileWithConstants';
import { nthIndex } from '../utils/commonFunc';
import { QueryClientProvider, QueryClient } from 'react-query';

const queryClient = new QueryClient();

const uploadFileRef = React.createRef();

const Updata = (props) => {

    const [groups, setGroupMenu] = useState([]);
    const [selectedGroup, setUpdGroup] = useState(localStorage.getItem("rootpath"));
    const [visible, setVisible] = useState(false);
    const [feedback, setFeedback] = useState("");
    const [alertType, setAlertType] = useState("info");
    const [loadingGroups, setLoadingGroups] = useState(false);

    const [lastTargetFilter, setlastTargetFilter] = useState();
    const [lastTargetFilterValue, setlastTargetFilterValue] = useState();
    const [billingTypeList, setbillingTypeList] = useState();
    const [meterDiamList, setmeterDiamList] = useState();

    const token = localStorage.getItem("token");
    const api_host = process.env.REACT_APP_API_URL || myConstClass.REACT_APP_API_URL;

    useEffect(() => {

        console.log('updata dashboard props.history', props.history);

        // var entities = selectedGroup.split("/")
        // var index = entities.length - 1
        // var Node_name = entities[index]
        // setSelectedNodeName(Node_name)

        if (props.location.state) {
            //retrieve the saved state of IndexDashboard after user is referred back to it from AboutDevice
            console.log("redirect props:", props.location.state.selectedGroup, ", ", props.location.state.pageIndex);
            setUpdGroup(props.location.state.selectedGroup)
            // setSelectedNodeName(props.location.state.selectedNodeName)
            // setinitPageIndex(props.location.state.pageIndex)
        }

        if (groups.length === 0) {
            generateGroups();
        }

        const token = localStorage.getItem("token");
        const api_host = process.env.REACT_APP_API_URL || myConstClass.REACT_APP_API_URL;

        var uri = `${api_host}/devices/billingtypes?path=${selectedGroup}`;
        var encodedURI = encodeURI(uri);
    
        myAxiosInstance.get(encodedURI, {
          headers: {
            'Authorization': `ApiKey ${token}`,
            'Content-Type': 'application/json'
          }
        })
        .then(res => {
          const billingTypeList = res.data;
    
          console.log("Here are your billing types:", billingTypeList);
          setbillingTypeList(billingTypeList)
        })
        .catch ( error => {
          const errorObject=JSON.parse(JSON.stringify(error));
          console.log(errorObject);
        })

        uri = `${api_host}/devices/meterdiameters?path=${selectedGroup}`;
        encodedURI = encodeURI(uri);
    
        myAxiosInstance.get(encodedURI, {
        headers: {
            'Authorization': `ApiKey ${token}`,
            'Content-Type': 'application/json'
        }
        })
        .then(res => {
        const meterDiamList = res.data;
        console.log("Here are your meter diameter types:", meterDiamList);
        setmeterDiamList(meterDiamList)
        })
        .catch ( error => {
        const errorObject=JSON.parse(JSON.stringify(error));
        console.log(errorObject);
        })


    }, []);

    useEffect(() => {
        // action on update of groupMenu
        setLoadingGroups(false);

    }, [groups]);

    const onDismiss = () => setVisible(false);

    const generateGroups = () => {
        var uri = `${api_host}/hierarchy/entities/navigate`;
        var encodedURI = encodeURI(uri);
        console.log("encoded URI:", encodedURI)
        console.log("decoded URI:", decodeURI(encodedURI))

        setLoadingGroups(true);

        myAxiosInstance.get(encodedURI, {
            headers: {
                'Authorization': `ApiKey ${token}`
            }
        })
            .then(res => {
                const groupList = res.data;
                const groups = [];
                for (let index = 0; index < groupList.length; index++) {
                  if (groupList[index]._count > 1 ) {
                    groups.push(groupList[index]);
                  }
                }
                console.log('This is your data', groupList);
                if (groups.length > 0) {
                    setGroupMenu(groups);
                    console.log("length of groupMenu:", groups.length);
                    console.log("groupMenu:", groups);
                } else {
                    console.log("Empty menu!")
                }
                setLoadingGroups(false);
            })
            .catch(error => {
                const errorObject = JSON.parse(JSON.stringify(error));
                console.log(errorObject);
                setFeedback(`Axios request error (原因: ${errorObject}).`);
                setAlertType("danger");
                setVisible(true);
            })

    }

    const SelectBillingTypeFilter = ({ column: { filterValue, setFilter, preFilteredRows, id } }) => {

        // Render a multi-select box
        return (
            <select
                value={filterValue || ""}
                style={
                    {
                        padding: '6px',
                        marginTop: '5px',
                        marginBotton: '5px',
                        border: '1px solid #ced4da'
                    }
                }
                onChange={e => {
                    setFilter(e.target.value || undefined);
                    setlastTargetFilter(id)
                    setlastTargetFilterValue(e.target.value)
                }}
            >

                <option key="" value="">全选</option>
                {Object.entries(billingTypeList).map(([key, value]) =>
                    <option key={key} value={key}>
                        {key}
                    </option>
                )}

            </select>
        )
    }

    const SelectMeterDiamFilter = ({ column: { filterValue, setFilter, preFilteredRows, id } }) => {

        // Render a multi-select box
        return (
            <select
                value={filterValue || ""}
                style={
                    {
                        padding: '6px',
                        marginTop: '5px',
                        marginBotton: '5px',
                        border: '1px solid #ced4da'
                    }
                }
                onChange={e => {
                    setFilter(e.target.value || undefined);
                    setlastTargetFilter(id)
                    setlastTargetFilterValue(e.target.value)
                }}
            >
                <option key="" value="">全选</option>
                {Object.entries(meterDiamList).map(([key, value]) =>
                    <option key={key} value={key}>
                        {key}
                    </option>
                )}

            </select>
        )
    }

    const resetFilterCallback = (newTargetFilter) => {
        console.log("text filter entered:", newTargetFilter);
        //text filter has been entered so clear any dropdown filter
        setlastTargetFilter("")
        setlastTargetFilterValue("")
    }

    const handleCallback = (childData, childKey) => {
        console.log("Parent received:", childData);

        setUpdGroup(childData);
        // setSelectedNodeName(childKey);

        setVisible(false);

        console.log("Upload file ref:", uploadFileRef);

        if (uploadFileRef.current !== null) {
            uploadFileRef.current.value = "";
        }


    }

    const basicHeader = [
        {
            Header: "客户编号",
            className: "t-cell-1 text-left",
            accessor: "CustomerBillingID_str"
        },
        {
            Header: "用户性质",
            className: "t-cell-1 text-left",
            accessor: "CustomerBillingType",
            Filter: SelectBillingTypeFilter,
            filter: 'includes',
            disableSortBy: true
        },
        {
            Header: "用户名",
            accessor: "CustomerBillingName",
            className: "t-cell-2 text-left",
            disableFilters: true,
            canFilter: false,
            disableSortBy: true
        },
        {
            Header: "最后更新",
            accessor: "lastRecord.timestampstring",
            className: "t-cell-6 text-left",
            disableFilters: true,
            canFilter: false,
            disableSortBy: false,
            Cell: ({ cell: { value } }) => {
                if (typeof value !== 'undefined') {
                    const timeStampIndex = nthIndex(value, ":", 2);
                    const displayTimeStamp = value.substring(0, timeStampIndex);
                    return (
                        `${displayTimeStamp}`
                    )
                } else {
                    return "";
                }
            }
        },
        {
            Header: "用水量 m³",
            accessor: "lastRecord.totalVol",
            className: "t-cell-10 text-left",
            disableFilters: true,
            canFilter: false,
            disableSortBy: false,
            Cell: ({ cell: { value } }) => {
                if (typeof value !== 'undefined') {
                    const showVol = value / 10000
                    const displayVol = showVol.toFixed(2)
                    return (
                        `${displayVol}`
                    )
                } else {
                    return "";
                }
            }
        },
        {
            Header: "模块号码",
            accessor: "deviceId",
            className: "t-cell-1 text-left",
            filter: 'equals'
        },
        {
            Header: "口径",
            accessor: "MeterBodyDiameterString",
            className: "t-cell-2 text-left",
            disableFilters: true,
            disableSortBy: true
            // Filter: SelectMeterDiamFilter,
            // filter: 'equals',
            // disableSortBy: true
        },
        {
            Header: "用户地址",
            accessor: "MeterLocation",
            className: "t-cell-5a text-left",
            disableFilters: true,
            canFilter: false,
            disableSortBy: true
        },
        {
            Header: "水表安装时间",
            accessor: "MeterInstallDateString_v2",
            className: "t-cell-6 text-left",
            disableFilters: true,
            canFilter: false,
            disableSortBy: true,
            Cell: ({ cell: { value } }) => {
                if (typeof value !== 'undefined') {
                    try {
                        const showDate = value.split("T")[0]
                        return (
                            `${showDate}`
                        )
                    } catch {
                        return value;
                    }
                } else {
                    return "";
                }
            }
        }
    ];

    const listHeaderDebug = [
        {
            Header: "水表SN",
            className: "t-cell-1 text-left",
            accessor: "deviceName",
            disableFilters: true
        },
        {
            Header: "中国移动号码",
            accessor: "oneNetDevieId",
            className: "t-cell-1 text-left",
            filter: 'equals'
        },
        {
            Header: "水表号码",
            className: "t-cell-1 text-left",
            accessor: "deviceId",
            filter: 'equals'
        },
        {
            Header: "IMEI",
            accessor: "imei",
            className: "t-cell-1 text-left",
            filter: 'equals'
        },
        {
            Header: "警报",
            accessor: "lastRecord.hdralarmStatus",
            className: "t-cell-1 text-left",
            filter: 'equals'

        },
        {
            Header: "客户地址",
            accessor: "MeterLocation",
            className: "t-cell-5a text-left",
            disableFilters: true,
            disableSortBy: true
        },
        {
            Header: "水表口径 (DN)",
            accessor: "MeterBodyDiameterString",
            className: "t-cell-2 text-left",
            disableFilters: true,
            disableSortBy: true
            // Filter: SelectMeterDiamFilter,
            // filter: 'equals',
            // disableSortBy: true
        },
        {
            Header: "基表号",
            accessor: "MeterBodyId",
            className: "t-cell-1 text-left",
            disableFilters: true,
            disableSortBy: true
        },
        {
            Header: "数据包序列号",
            accessor: "lastRecord.seqNum",
            className: "t-cell-1 text-right",
            disableFilters: true,
            disableSortBy: true
        },
        {
            Header: "成功上报计数",
            accessor: "lastRecord.okCount",
            className: "t-cell-1 text-right",
            disableFilters: true,
            disableSortBy: true
        },
        {
            Header: "总用水 m³",
            accessor: "lastRecord.totalVol",
            className: "t-cell-1 text-right",
            disableFilters: true
        },
        {
            Header: "正转计数 m³",
            accessor: "lastRecord.fwdVol",
            className: "t-cell-4a text-right",
            disableFilters: true,
            disableSortBy: true
        },
        {
            Header: "反转计数 m³",
            accessor: "lastRecord.revVol",
            className: "t-cell-4a text-right",
            disableFilters: true,
            disableSortBy: true
        },
        {
            Header: "连续用水时间",
            accessor: "lastRecord.usageCt",
            className: "t-cell-4a text-right",
            disableFilters: true,
            disableSortBy: true
        },
        {
            Header: "外部干扰累计时间",
            accessor: "lastRecord.tamperCt",
            className: "t-cell-1 text-right",
            disableFilters: true,
            disableSortBy: true
        },
        {
            Header: "移除累计时间",
            accessor: "lastRecord.removeCt",
            className: "t-cell-1 text-right",
            disableFilters: true,
            disableSortBy: true
        },
        {
            Header: "爆管累计时间",
            accessor: "lastRecord.burstCt",
            className: "t-cell-1 text-right",
            disableFilters: true,
            disableSortBy: true
        },
        {
            Header: "温度",
            accessor: "lastRecord.temp",
            className: "t-cell-1 text-right",
            disableFilters: true,
            disableSortBy: true
        },
        {
            Header: "上报前电池电压",
            accessor: "lastRecord.preBatt",
            className: "t-cell-1 text-right",
            disableFilters: true,
            disableSortBy: true
        },
        {
            Header: "上报后电池电压",
            accessor: "lastRecord.postBatt",
            className: "t-cell-1 text-right",
            disableFilters: true,
            disableSortBy: true
        },
        {
            Header: "上一次上报结束",
            accessor: "lastRecord.lastIndex",
            className: "t-cell-1 text-right",
            disableFilters: true,
            disableSortBy: true
        },
        {
            Header: "上一次上报所用时间",
            accessor: "lastRecord.lastUpload",
            className: "t-cell-1 text-right",
            disableFilters: true,
            disableSortBy: true
        },
        {
            Header: "上一次上报后NB电源状态",
            accessor: "lastRecord.lastPwr",
            className: "t-cell-1 text-right",
            disableFilters: true,
            disableSortBy: true
        },
        {
            Header: "本次NB上报STEPS",
            accessor: "lastRecord.lastSteps",
            className: "t-cell-1 text-right",
            disableFilters: true,
            disableSortBy: true
        },
        {
            Header: "信号强度",
            accessor: "lastRecord.CQS",
            className: "t-cell-1 text-right",
            disableFilters: true,
            disableSortBy: true
        },
        {
            Header: "信号功率",
            accessor: "lastRecord.sigPwr",
            className: "t-cell-1 text-right",
            disableFilters: true,
            disableSortBy: true
        },
        {
            Header: "总功率",
            accessor: "lastRecord.totalPwr",
            className: "t-cell-1 text-right",
            disableFilters: true,
            disableSortBy: true
        },
        {
            Header: "终端发射功率",
            accessor: "lastRecord.txPwr",
            className: "t-cell-1 text-right",
            disableFilters: true,
            disableSortBy: true
        },
        {
            Header: "基站小区标识",
            accessor: "lastRecord.cellID",
            className: "t-cell-1 text-right",
            disableFilters: true,
            disableSortBy: true
        },
        {
            Header: "信噪比",
            accessor: "lastRecord.SNR",
            className: "t-cell-1 text-right",
            disableFilters: true,
            disableSortBy: true
        },
        {
            Header: "中心频点",
            accessor: "lastRecord.EARFCN",
            className: "t-cell-1 text-right",
            disableFilters: true,
            disableSortBy: true
        },
        {
            Header: "物理小区标识",
            accessor: "lastRecord.PCI",
            className: "t-cell-1 text-right",
            disableFilters: true,
            disableSortBy: true
        },
        {
            Header: "参考信号接收质量",
            accessor: "lastRecord.RSRQ",
            className: "t-cell-1 text-right",
            disableFilters: true,
            disableSortBy: true
        },
        {
            Header: "参考信号接收功率",
            accessor: "lastRecord.RSRP",
            className: "t-cell-1 text-right",
            disableFilters: true,
            disableSortBy: true
        },
        {
            Header: "线圈L1电感动态数值",
            accessor: "lastRecord.lc1Delta",
            className: "t-cell-1 text-right",
            disableFilters: true,
            disableSortBy: true
        },
        {
            Header: "线圈L2电感动态数值率",
            accessor: "lastRecord.lc2Delta",
            className: "t-cell-1 text-right",
            disableFilters: true,
            disableSortBy: true
        },
        {
            Header: "线圈L3电感动态数值",
            accessor: "lastRecord.lc3Delta",
            className: "t-cell-1 text-right",
            disableFilters: true,
            disableSortBy: true
        },
        {
            Header: "最后更新",
            accessor: "lastRecord.timestampstring",
            className: "t-cell-6 text-left",
            disableFilters: true,
            Cell: ({ cell: { value } }) => {
                if (typeof value !== 'undefined') {
                    const timeStampIndex = nthIndex(value, ":", 2);
                    const displayTimeStamp = value.substring(0, timeStampIndex);
                    return (
                        `${displayTimeStamp}`
                    )
                } else {
                    return "";
                }
            }
        }
    ];

    return (
        <>
            <h2>更新数据</h2>
            <div className="App">
                <div className="demo-container">
                    <div className="column-left">
                        <GroupMenuNew
                            groups={groups}
                            loading={loadingGroups}
                            parentCallback={handleCallback}
                            initialGroup={
                                selectedGroup
                            }
                        />
                    </div>
                    <div className="column-right">
                        <Alert color={alertType} isOpen={visible} toggle={onDismiss}>
                            {feedback}
                        </Alert>

                        <Container
                            className="themed-container" fluid={true}
                        >
                            <>
                                <QueryClientProvider client={queryClient}>
                                    {localStorage.getItem("userrole").includes("debug") ?
                                        <UpdataTable
                                            columns={listHeaderDebug}
                                            selectedGroup={selectedGroup}
                                            lastTargetFilter={lastTargetFilter}
                                            lastTargetFilterValue={lastTargetFilterValue}
                                            parentCallback={resetFilterCallback}
                                            meterDiamList={meterDiamList}
                                            billingTypeList={billingTypeList}
                                        // aboutCallback={handleDeviceCallback}
                                        />
                                        :
                                        <UpdataTable
                                            columns={basicHeader}
                                            selectedGroup={selectedGroup}
                                            lastTargetFilter={lastTargetFilter}
                                            lastTargetFilterValue={lastTargetFilterValue}
                                            parentCallback={resetFilterCallback}
                                            meterDiamList={meterDiamList}
                                            billingTypeList={billingTypeList}
                                        // aboutCallback={handleDeviceCallback}
                                        />
                                    }
                                </QueryClientProvider>
                            </>                       
                        </Container>
                    </div>
                </div>
            </div>
        </>
    )
}

export default withRouter(Updata);