import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Alert, Form, FormGroup } from 'reactstrap';
import myAxiosInstance from '../utils/axios-intercept';
import GroupMenuNew from "../GroupMenuNew";
import Loader from 'react-loader-spinner';
import * as myConstClass from '../utils/fileWithConstants';
import Select from 'react-select'
import { QueryClientProvider, QueryClient } from 'react-query';
import { useLocation, useHistory } from 'react-router-dom';
import ShowAlarmTablev2 from './Alarmtable';
import CountData from './CountData';

const queryClient = new QueryClient()
const options = [
  // { value: "", label: "" },
  { value: 1, label: "过流" },
  { value: 2, label: "移除" },
  { value: 4, label: "干扰" },
  { value: 8, label: "泄漏" },
  { value: 100, label: "低電" }
];

const Meteralarm = (props) => {
    let history = useHistory();
    console.log("history",history)
    const [loading, setLoading] = useState(false);
    const [choice, setChoice] = useState("");
    const [groups, setGroupMenu] = useState([]);
    const [selectedGroup, setUpdGroup] = useState("");
    const [selectedNodeName, setSelectedNodeName] = useState("");
    const [showProgress, setProgressMsg] = useState(false);
    const [visible, setVisible] = useState(false);
    const [feedback, setFeedback] = useState("");
    const [alertType, setAlertType] = useState("info");
    const [loadingGroups, setLoadingGroups] = useState(false);
    const token = localStorage.getItem("token");
    const location = useLocation();
    const api_host = process.env.REACT_APP_API_URL || myConstClass.REACT_APP_API_URL;
    useEffect(() => {
      //useHistory handle here
      // need to map?
      console.log("location use", location.state)

      if (typeof(location.state) !== 'undefined' && location.state != null) {
        console.log('Not Undefined and Not Null')
        setLoadingGroups(false);
        console.log("path pass",location.state.path);
        setUpdGroup(location.state.path); //set the inital grp here
        onChange(location.state.onchoiceChange); //?
        // setlinkPropspass(location.state.test);
      }
      else
      {
          console.log('Data are Undefined Or Null')
      }
    },[]);
    useEffect(() => {

        if (groups.length === 0) {
        generateGroups();
        }
    }, []);

    useEffect(() => {
        // action on update of groupMenu
        setLoadingGroups(false);

    }, [groups]);

    const onChange = (selectedOption) => {
        setChoice(selectedOption);
        console.log(`Option selected:`, selectedOption);
 
      };

    const onDismiss = () => setVisible(false);

    const generateGroups = () => {
        var uri = `${api_host}/hierarchy/entities/navigate`;
        var encodedURI = encodeURI(uri);
        console.log("encoded URI:", encodedURI)
        console.log("decoded URI:", decodeURI(encodedURI))
        setLoadingGroups(true);
        myAxiosInstance.get(encodedURI, {
        headers: {
            'Authorization': `ApiKey ${token}`
        }})
        .then(res => {
            const groupList = res.data;
            console.log('This is your data', groupList);
            if (groupList.length > 0) {
            setGroupMenu(groupList);
            console.log("length of groupMenu:", groupList.length);
            console.log("groupMenu:", groupList);
            setLoadingGroups(false);
            } else {
            console.log("Empty menu!")
            setLoadingGroups(false);
            }
        })
        .catch(error => {
            const errorObject = JSON.parse(JSON.stringify(error));
            console.log(errorObject);
            setFeedback(`Axios request error (原因: ${errorObject}).`);
            setAlertType("danger");
            setVisible(true);
        })
    }

    const handleCallback = (childData, childKey) => {
      console.log("Parent received:", childData);
      setUpdGroup(childData);
      setChoice("");

      setSelectedNodeName(childKey);
      setVisible(false);
    }
    const customgoBack = () =>{
       history.push({
          pathname: "/dashboard",
          state: {
            selectedGroup: location.state.path,
        }
       })
     }
     



    // const SendDataToParent1 = (you) => { // the callback. Use a better name
    //   console.log("you test123 in parent parent value",you);
    //   setDirection(you)
    // }
  return (
      <>
        <h2>水錶报警</h2>
        <div className="App">
          <div className="demo-container">
            <div className="column-left">
              <GroupMenuNew
                groups={groups}
                loading={loadingGroups}
                parentCallback={handleCallback}
                initialGroup={
                  selectedGroup
                }
              />
            </div>          
            <div className="column-right">
              <Alert color={alertType} isOpen={visible} toggle={onDismiss}>
                {feedback}
              </Alert>
              <Container className="themed-container" fluid={true}>

                {selectedGroup !== "" ? (
                  <Row className="justify-content-md-center">
                    <Col xs lg="2">&nbsp;</Col>
                      <Form>
                        <FormGroup>
                          <h2>
                            选定群组: {selectedNodeName}
                          </h2>
                          <div>
                            <h2>警报选项</h2></div>  
                            <div>
                            <CountData onchoiceChange={choice} selectedGroup ={selectedGroup} />
                            </div>
                        
                          <div>
                            <Select 
                              disabled={loading} //prevent the user from interact dropdown while loading
                              isMulti
                              placeholder={"请选择警报类型"}
                              options={options} 
                              isClearable={true}
                              // defaultInputValue={choice}
                              // value={data.filter(obj => selectedValue.includes(obj.value))}
                              onChange={onChange} value={choice} />     
                              
                          </div>
                        </FormGroup>
                      </Form>
                    
                    
                    <Col xs lg="2">&nbsp;</Col>
                  </Row>
                ) : (
                  <h3>请选择群组</h3>
                )}
                <Row>
                  <Col>
                    {showProgress && (
                      <>
                        <p>. . . 进行中 . . .</p>
                        <div
                          style={{
                            width: "100%",
                            height: "100",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                          }}
                        >
                          <Loader type="ThreeDots" color="#2BAD60" height="100" width="100" />
                        </div>
                      </>
                    )}
                  </Col>
                </Row>
                <Row className="justify-content-md-center">
                      {choice.length !==0? (
                      <ShowAlarmTablev2 
                        onchoiceChange ={choice}
                        selectedGroup={selectedGroup}
                        minRows={0} 
                      />): (<div></div>)}
                </Row>
              </Container>
              <div>
                {/* {location.state?         <button onClick={history.goBack()}>返回</button>
              : <div></div>} */}
                {location.state?         <button onClick= {customgoBack}>返回</button>
              : <div></div>}

              </div>
            </div>
          </div>
        </div>
      </>
  )
}

export default Meteralarm;
