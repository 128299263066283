import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Alert, Button, Form, FormGroup } from 'reactstrap';
import myAxiosInstance from '../utils/axios-intercept';
import GroupMenuNew from "../GroupMenuNew";
import './GroupTree.css';
//import './ModifyGroup.css';
import Loader from 'react-loader-spinner';
import * as myConstClass from '../utils/fileWithConstants';
import { QueryClientProvider, QueryClient } from 'react-query';
import ModifyGroupTable from '../PagingComponent/ModifyGroupTable';
import { render } from '@testing-library/react';

const uploadFileRef = React.createRef();

const queryClient = new QueryClient();

const DeviceId_Debug = [
  {
    Header: "模块ID",
    className: "t-cell-1 text-left",
    accessor: "deviceId",
  }
]
const CustomerBIllingId_Debug = [
  {
    Header: "客户编号",
    className: "t-cell-1 text-left",
    accessor: "CustomerBIllingId",
  }
]
const PathExist_Debug = [
  {
    Header: "群组",
    className: "t-cell-1 text-left",
    accessor: "paths",
  },
  {
    Header: "模块ID",
    className: "t-cell-1 text-left",
    accessor: "deviceId",
  }
]

const ModifyGroup = (props) => {

  const [groups, setGroupMenu] = useState([]);
  const [selectedGroup, setUpdGroup] = useState("");
  const [selectedNodeName, setSelectedNodeName] = useState("");
  const [showProgress, setProgressMsg] = useState(false);
  const [visible, setVisible] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [alertType, setAlertType] = useState("info");
  const [selectedFile, setSelectedFile] = useState(null);
  const [loadingGroups, setLoadingGroups] = useState(false);
  const [hideTemplateDownload, setHideTemplateDownload] = useState(false);
  const [errorID_type, seterrorID_type] = useState("")
  const [errorID, seterrorID] = useState([]);
  const [errorMessage, seterrorMessage] = useState("");

  const token = localStorage.getItem("token");
  const username = localStorage.getItem("username");
  const api_host = process.env.REACT_APP_API_URL || myConstClass.REACT_APP_API_URL;

  useEffect(() => {
    if (groups.length === 0) {
      generateGroups();
    }
  }, []);

  useEffect(() => {
    // action on update of groupMenu
    setLoadingGroups(false);
  }, [groups]);

  const onDismiss = () => setVisible(false);

  const generateGroups = () => {

    var uri = `${api_host}/hierarchy/entities/navigate`;
    var encodedURI = encodeURI(uri);
    console.log("encoded URI:", encodedURI)
    console.log("decoded URI:", decodeURI(encodedURI))

    setLoadingGroups(true);

    myAxiosInstance.get(encodedURI, {
      headers: {
        'Authorization': `ApiKey ${token}`
      }
    })
      .then(res => {
        const groupList = res.data;
        console.log('This is your data', groupList);
        if (groupList.length > 0) {
          setGroupMenu(groupList);
          console.log("length of groupMenu:", groupList.length);
          console.log("groupMenu:", groupList);
          setLoadingGroups(false);
        } else {
          console.log("Empty menu!")
          setLoadingGroups(false);
        }
      })
      .catch(error => {
        const errorObject = JSON.parse(JSON.stringify(error));
        console.log(errorObject);
        setFeedback(`Axios request error (原因: ${errorObject}).`);
        setAlertType("danger");
        setVisible(true);
      })

  }


  const handleCallback = (childData, childKey) => {
    console.log("Parent received:", childData);

    setUpdGroup(childData);
    setSelectedNodeName(childKey);

    setVisible(false);

    console.log("Upload file ref:", uploadFileRef);

    if (uploadFileRef.current !== null) {
      uploadFileRef.current.value = "";
    }


  }

  // On file select (from the pop up)
  const onFileChange = event => {

    // Update the selected file
    setSelectedFile(event.target.files[0]);

  };

  const showFileUpload = e => {
    e.preventDefault();
    uploadFileRef.current.click();
  }

  const addUpload = () => {

    const uri = `${api_host}/hierarchy/upload/addDevices`;
    const encodedURI = encodeURI(uri);
    const column = "模块ID";

    console.log("encoded URI:", encodedURI);
    console.log("decoded URI:", decodeURI(encodedURI));

    const formData = new FormData();

    // Update the formData object
    formData.append(
      "dataFile",
      selectedFile,
      selectedFile.name
    );

    // Update the formData object with the path
    formData.append(
      "path",
      selectedGroup
    );

    // Update the formData object with the column
    formData.append(
      "column",
      column
    );

    formData.append(
      "username",
      username
    );


    // Details of the uploaded file
    console.log("before writing selectedFile");
    console.log(selectedFile.name);
    console.log(selectedFile.type);
    // console.log(selectedFile.lastModifiedDate.toDateString());
    console.log("FormData: ", formData);
    console.log("after writing selectedFile");

    setVisible(false);
    setProgressMsg(true);

    // Request made to the backend api
    // Send formData object

    myAxiosInstance.post(encodedURI, formData,
      {
        headers: {
          'Authorization': `ApiKey ${token}`,
        }
      }
    ).then(res => {
      console.log("successful response:", res);
      if (res.data.length > 0 && res.data.failure.length > 0) {
        console.log("failure: ", res.data.failure);
        console.log("errorID: ", res.data.failure);
        seterrorMessage("ID不存在");
        setAlertType("success");
      }
      setProgressMsg(false);
      setFeedback(`${selectedFile.name} 已成功处理.`);
      seterrorID(res.data.failure);
      seterrorID_type("deviceId");
      setVisible(true);

      generateGroups();
      //setUpdGroup("");
      setSelectedFile(null);

      //reset the upload file to empty
      console.log("Upload file ref:", uploadFileRef);

      if (uploadFileRef.current !== null) {
        uploadFileRef.current.value = "";
      }

    })
      .catch(error => {
        var errmsg = "";
        const errorObject = JSON.parse(JSON.stringify(error));
        console.log("error ", error);
        console.log("errorObject ", errorObject);
        if (String(error).includes("422")) {
          if (error.response.data) {
            console.log("data ", error.response.data)
            errmsg = error.response.data["error"]
            console.log("errorMessage:",errmsg)
            var ex = error.response.data["exception"]
            if (ex) {
              if (ex === "MissingFieldException") {
                seterrorID_type("column");
                errmsg = "模块ID不存在於表格";
              }
              else if (ex === "IdNotExistException") {
                seterrorMessage("模块ID不存在");
                seterrorID(errmsg);
                seterrorID_type("deviceId");
                errmsg = "模块ID不存在";
              }
              else if (ex === "IdnotnumericException") {
                seterrorMessage("模块ID非数字");
                seterrorID(errmsg);
                seterrorID_type("deviceId");
                errmsg = "模块ID非数字 ";
              }
              else if (ex === "CustomerBIllingIdnotnumericException") {
                seterrorMessage("客户编号非数字");
                seterrorID(errmsg);
                seterrorID_type("CustomerBIllingId")
                errmsg =  "客户编号非数字 ";
              }
              else if (ex === "CustomerBIllingIdNotExistException") {
                seterrorMessage("客户编号不存在");
                seterrorID(errmsg);
                seterrorID_type("deviceId")
                errmsg =  "客户编号不存在";
              }
              else if (ex === "IdDuplicateException") {
                seterrorMessage("模块ID重复");
                seterrorID(errmsg);
                seterrorID_type("deviceId");
                errmsg = "模块ID重复 ";
              }
              else if (ex === "CustomerBIllingIdDuplicateException") {
                seterrorMessage("客户编号重复");
                seterrorID(errmsg);
                seterrorID_type("CustomerBIllingId")
                errmsg = "客户编号重复 ";
              }
              else if (ex === "PathExistException") {
                seterrorMessage("已存在群组");
                seterrorID(errmsg)
                seterrorID_type("PathExist")
                console.log("test: ",errmsg)
                errmsg = "已存在群组 ";
              }
            }
          }
          setFeedback("EXCEL 数据错误 :" + errmsg);
        }
        else
          setFeedback(`Axios request error (原因: ${errorObject}).`);

        setProgressMsg(false);
        setAlertType("danger");
        setVisible(true);

        generateGroups();
        setSelectedFile(null);

      })
  };

  const updUpload = () => {
    // Update the fields of existing devices

    const uri = `${api_host}/hierarchy/upload/modifyDevices`;
    const encodedURI = encodeURI(uri);

    console.log("encoded URI:", encodedURI);
    console.log("decoded URI:", decodeURI(encodedURI));

    /*
    setFeedback(`Under construction. Coming soon!`)
    setAlertType("success");
    setVisible(true);
    return;
    */

    const formData = new FormData();

    // Update the formData object with the dataFile
    formData.append(
      "dataFile",
      selectedFile,
      selectedFile.name
    );

    // Update the formData object with the path
    formData.append(
      "path",
      selectedGroup
    );

    setVisible(false);
    setProgressMsg(true);

    // Request made to the backend api
    // Send formData object
    myAxiosInstance.post(encodedURI, formData,
      {
        headers: {
          'Authorization': `ApiKey ${token}`,
        }
      })
      .then(res => {
        var failureList = "";
        console.log("successful response:", res);
        if (res.data && res.data.failure.length > 0) {
          console.log("failure: ", res.data.failure);
          console.log("errorID: ", res.data.failure);
          seterrorMessage("ID不存在");
          setAlertType("success");
        }
        setProgressMsg(false);
        setFeedback(`${selectedFile.name} 已成功处理.`);
        seterrorID(res.data.failure);
        seterrorID_type("deviceId");
        setVisible(true);

        /*generateDownload(selectedGroup);*/
        generateGroups();
        //setUpdGroup("");
        setSelectedFile(null);

        //reset the upload file to empty
        console.log("Upload file:", uploadFileRef);
        

        if (uploadFileRef.current !== null) {
          uploadFileRef.current.value = "";
        }
      })
      .catch(
        error => {
          const errorObject = JSON.parse(JSON.stringify(error));
          console.log(error);
          console.log(errorObject);
          var errmsg = "";

          if (String(error).includes("422")) {
            if (error.response.data) {
              console.log("data ", error.response.data)
              errmsg = error.response.data["error"]
              var ex = error.response.data["exception"]
              if (ex) {
                if (ex === "MissingFieldException") {
                  seterrorID_type("column")
                  errmsg = "不存在 ";
                }
                else if (ex === "IdNotExistException") {
                  seterrorID_type("column")
                  errmsg = "模块ID不存在 ";
                }
                else if (ex === "IdnotnumericException") {
                  seterrorMessage("模块ID非数字");
                  seterrorID(errmsg);
                  seterrorID_type("deviceId")
                  errmsg = "模块ID非数字 ";
                }
                else if (ex === "CustomerBIllingIdnotnumericException") {
                  seterrorMessage("客户编号非数字");
                  seterrorID(errmsg);
                  seterrorID_type("CustomerBIllingId")
                  errmsg = "客户编号非数字 ";
                }
                else if (ex === "CustomerBIllingIdNotExistException") {
                  seterrorMessage("客户编号不存在");
                  seterrorID(errmsg);
                  seterrorID_type("deviceId")
                  errmsg =  "客户编号不存在";
                }
                else if (ex === "IdDuplicateException") {
                  seterrorMessage("模块ID重复");
                  seterrorID(errmsg);
                  seterrorID_type("deviceId")
                  errmsg = "模块ID重复 ";
                }
                else if (ex === "CustomerBIllingIdDuplicateException") {
                  seterrorMessage("客户编号重复");
                  seterrorID(errmsg);
                  seterrorID_type("CustomerBIllingId")
                  errmsg = "客户编号重复 ";
                }
                else if (ex === "PathExistException") {
                  seterrorMessage("已存在群组");
                  seterrorID(errmsg);
                  seterrorID_type("PathExist")
                  console.log("test: ",errmsg)
                  errmsg = "已存在群组 ";
                }

              }
            }
            setFeedback("EXCEL 数据错误 :" + errmsg);
          }
          else
            setFeedback(`Axios request error (原因: ${errorObject}).`);

          setProgressMsg(false);
          setAlertType("danger");
          setVisible(true);
        }
      )

  }

  const removeUpload = () => {

    const uri = `${api_host}/hierarchy/upload/removeDevices`;
    const column = "模块ID";

    const encodedURI = encodeURI(uri);
    console.log("encoded URI:", encodedURI);
    console.log("decoded URI:", decodeURI(encodedURI));

    const formData = new FormData();

    // Update the formData object with the dataFile
    formData.append(
      "dataFile",
      selectedFile,
      selectedFile.name
    );

    // Update the formData object with the path
    formData.append(
      "path",
      selectedGroup
    );

    // Update the formData object with the column
    formData.append(
      "column",
      column
    );

    formData.append(
      "username",
      username
    );

    // Details of the uploaded file
    console.log("before writing selectedFile");
    console.log(selectedFile.name);
    console.log(selectedFile.type);
    // console.log(selectedFile.lastModifiedDate.toDateString());
    console.log("FormData: ", formData);
    console.log("after writing selectedFile");

    setVisible(false);
    setProgressMsg(true);

    // Request made to the backend api
    // Send formData object
    myAxiosInstance.post(encodedURI, formData,
      {
        headers: {
          'Authorization': `ApiKey ${token}`,
        }
      }
    ).then(res => {
      console.log("successful response:", res);

      setProgressMsg(false);
      setFeedback(`${selectedFile.name} 已成功处理.`)
      setAlertType("success");
      setVisible(true);

      generateGroups();
      //setUpdGroup("");
      setSelectedFile(null);

      //reset the upload file to empty
      console.log("Upload file:", uploadFileRef);

      if (uploadFileRef.current !== null) {
        uploadFileRef.current.value = "";
      }

    })
      .catch(error => {
        const errorObject = JSON.parse(JSON.stringify(error));
        console.log(error);
        console.log(errorObject);

        setProgressMsg(false);
        setFeedback(`Axios request error (原因: ${errorObject}).`);
        setAlertType("danger");
        setVisible(true);
      })

  };

  const getTemplate = (event) => {

    event.preventDefault();

    const fileURL = myConstClass.METER_TEMPLATE;
    console.log("template:", fileURL);
    console.log("constant template name:", myConstClass.METER_TEMPLATE);

    fetch(fileURL, {
      method: 'GET',
      headers: {
        'Authorization': `ApiKey ${token}`,
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
    })
      .then((res) => res.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(
          new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          myConstClass.METER_TEMPLATE_FILENAME
        );

        console.log("Link to download:", link);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);

        //do not show the download template button anymore
        setHideTemplateDownload(true);
      });


  }

  const Export_excel = () => {

    const uri = `${api_host}/hierarchy/export?path=` + selectedGroup;
    const encodedURI = encodeURI(uri);

    const filename = selectedGroup + ".xlsx"

    console.log("encoded URI:", encodedURI);
    setProgressMsg(true);
    fetch(encodedURI,
      {
        method: 'GET',
        headers: {
          'Authorization': `ApiKey ${token}`,
          'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }
      })
      .then((res) => res.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(
          new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          filename
        )
        // .catch((err)=>{
        //   console.log("errer: ",err)
        // });

        console.log("Link to download:", link);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);

        //do not show the download template button anymore
        setProgressMsg(false);
      });

  }

  return (
    <>
      <h2>更新小区</h2>
      <div className="App">
        <div className="demo-container">
          <div className="column-left">
            <GroupMenuNew
              groups={groups}
              loading={loadingGroups}
              parentCallback={handleCallback}
              initialGroup={
                selectedGroup
              }
            />
          </div>
          <div className="column-right">
            <Alert color={alertType} isOpen={visible} toggle={onDismiss}>
              {feedback}
            </Alert>
            <Container
              className="themed-container" fluid={true}
            >
              {selectedGroup !== "" ? (
                <Row className="justify-content-md-center">
                  <Col xs lg="2">&nbsp;</Col>
                  <Col md="auto">
                    <Form>
                      <FormGroup>
                        <h2>
                          选定群组: {selectedNodeName}
                        </h2>
                        <input
                          type="file"
                          name="dataFile"
                          id="dataFile"
                          accept=".xls,.xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                          onChange={onFileChange}
                          label={"选择文件"}
                          ref={uploadFileRef}
                          style={{ display: "none" }}
                        />
                        <input
                          type="image"
                          alt="File Submit"
                          src="/images/file-submit.png"
                          style={{ "width": "auto" }}
                          onClick={showFileUpload}
                        />
                        <Button
                          color="primary"
                          size="sm"
                          active
                          onClick={(event) => getTemplate(event)}
                          hidden={hideTemplateDownload}
                        >
                          下载EXCEL数据库模板文件
                        </Button>
                        <Button
                          color="primary"
                          size="sm"
                          active
                          onClick={Export_excel}
                        >
                          导出
                        </Button>
                      </FormGroup>
                    </Form>
                    {selectedFile && (
                      <Row>
                        <Col>
                          <h3>所选文件: {selectedFile.name}</h3>
                        </Col>
                      </Row>
                    )}
                    {selectedFile && (
                      <Row>
                        <Col>
                          <Button
                            color="primary"
                            size="sm"
                            active
                            onClick={(event) => updUpload(event)}
                          >
                            更新水表
                          </Button>
                        </Col>
                        <Col>
                          <Button
                            color="primary"
                            size="sm"
                            active
                            onClick={(event) => addUpload(event)}
                          >
                            添加水表
                          </Button>
                        </Col>
                        <Col>
                          <Button
                            color="primary"
                            size="sm"
                            active
                            onClick={(event) => removeUpload(event)}
                          >
                            删除水表
                          </Button>
                        </Col>
                      </Row>
                    )}
                  </Col>
                  <Col xs lg="2">&nbsp;</Col>
                </Row>
              ) : (
                <h3>请选择群组</h3>
              )}
              <Row>
                <Col>
                  {showProgress && (
                    <>
                      <p>. . . 进行中 . . .</p>
                      <div
                        style={{
                          width: "100%",
                          height: "100",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center"
                        }}
                      >
                        <Loader type="ThreeDots" color="#2BAD60" height="100" width="100" />
                      </div>
                    </>
                  )}
                </Col>
              </Row>
              {
                errorMessage !== "" && errorID_type === "deviceId"&& 
                <QueryClientProvider client={queryClient}>
                  <ModifyGroupTable
                  columns = {DeviceId_Debug}
                  errorID = {errorID}
                  errorMessage = {errorMessage}
                  />
                </QueryClientProvider>                
              }
              {
                errorMessage !== "" && errorID_type === "CustomerBIllingId"&& 
                <QueryClientProvider client={queryClient}>
                  <ModifyGroupTable
                  columns = {CustomerBIllingId_Debug}
                  errorID = {errorID}
                  errorMessage = {errorMessage}
                  />
                </QueryClientProvider>                
              }
              {
                errorMessage !== "" && errorID_type === "PathExist"&& 
                <QueryClientProvider client={queryClient}>
                  <ModifyGroupTable
                  columns = {PathExist_Debug}
                  errorID = {errorID}
                  errorMessage = {errorMessage}
                  />
                </QueryClientProvider>                
              }
            </Container>
          </div>
        </div>
      </div>
    </>
  )
}

export default ModifyGroup;
