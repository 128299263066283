import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Alert, Button, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { withRouter } from "react-router";
import myAxiosInstance from '../utils/axios-intercept';
import GroupMenuNew from "../GroupMenuNew";
//import './ModifyGroup.css';
import UpdateLogTable from '../PagingComponent/UpdateLogTable';
import * as myConstClass from '../utils/fileWithConstants';
import { nthIndex } from '../utils/commonFunc';
import { QueryClientProvider, QueryClient } from 'react-query';

const queryClient = new QueryClient();

const UpdateLog = () => {

    const [visible, setVisible] = useState(false);
    const [feedback, setFeedback] = useState("");
    const [alertType, setAlertType] = useState("info");

    const onDismiss = () => setVisible(false);

    return (
        <>
            <h2>设备管理记录</h2>
            <div className="App">
                <div className="demo-container">
                        <Alert color={alertType} isOpen={visible} toggle={onDismiss}>
                            {feedback}
                        </Alert>
                        <Container
                            className="themed-container" fluid={true}
                        >
                            <>
                                <QueryClientProvider client={queryClient}>
                                    <UpdateLogTable/>
                                </QueryClientProvider>
                            </>                       
                        </Container>
                    </div>
                </div>
        </>
    )
}

export default withRouter(UpdateLog);