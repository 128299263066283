import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Alert, Button, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import myAxiosInstance from '../utils/axios-intercept';
import GroupMenuNew from "../GroupMenuNew";
//import './ModifyGroup.css';
import FaultDevicesTable from '../PagingComponent/FaultDevicesTable';
import * as myConstClass from '../utils/fileWithConstants';
import { nthIndex } from '../utils/commonFunc';
import { QueryClientProvider, QueryClient } from 'react-query';

const listHeaderDebug = [
  {
    Header: "IMEI",
    accessor: "imei",
    className: "t-cell-1 text-left"
  },
  {
    Header: "水表号码",
    className: "t-cell-1 text-left",
    accessor: "deviceId"
  },
  {
    Header: "最后更新",
    accessor: "timestampstring",
    className: "t-cell-6 text-left",
    Cell: ({ cell: { value } }) => {
      if (typeof value !== 'undefined') {
        const timeStampIndex = nthIndex(value, ":", 2);
        const displayTimeStamp = value.substring(0, timeStampIndex);
        return (
          `${displayTimeStamp}`
        )
      } else {
        return "";
      }
    }
  },
  {
    Header: "客户地址",
    accessor: "MeterLocation",
    className: "t-cell-5a text-left",
    disableFilters: true
  },
  {
    Header: "水表口径 (DN)",
    accessor: "MeterBodyDiameter",
    className: "t-cell-2 text-left"
  },
  {
    Header: "基表号",
    accessor: "MeterBodyId",
    className: "t-cell-1 text-left"
  },

];

const queryClient = new QueryClient();

const uploadFileRef = React.createRef();

const FaultDevices = (props) => {

  const [groups, setGroupMenu] = useState([]);
  const [selectedGroup, setUpdGroup] = useState("");
  const [selectedNodeName, setSelectedNodeName] = useState("");
  const [visible, setVisible] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [alertType, setAlertType] = useState("info");
  const [loadingGroups, setLoadingGroups] = useState(false);
  const [timestamp, settimestamp] = useState(3)

  const token = localStorage.getItem("token");
  const api_host = process.env.REACT_APP_API_URL || myConstClass.REACT_APP_API_URL;

  useEffect(() => {
    if (groups.length === 0) {
      generateGroups();
    }
  }, []);

  useEffect(() => {
    // action on update of groupMenu
    setLoadingGroups(false);

  }, [groups]);

  const onDismiss = () => setVisible(false);

  const generateGroups = () => {
    var uri = `${api_host}/hierarchy/entities/navigate`;
    var encodedURI = encodeURI(uri);
    console.log("encoded URI:", encodedURI)
    console.log("decoded URI:", decodeURI(encodedURI))

    setLoadingGroups(true);

    myAxiosInstance.get(encodedURI, {
      headers: {
        'Authorization': `ApiKey ${token}`
      }
    })
      .then(res => {
        const groupList = res.data;
        console.log('This is your data', groupList);
        if (groupList.length > 0) {
          setGroupMenu(groupList);
          console.log("length of groupMenu:", groupList.length);
          console.log("groupMenu:", groupList);
          setLoadingGroups(false);
        } else {
          console.log("Empty menu!")
          setLoadingGroups(false);
        }
      })
      .catch(error => {
        const errorObject = JSON.parse(JSON.stringify(error));
        console.log(errorObject);
        setFeedback(`Axios request error (原因: ${errorObject}).`);
        setAlertType("danger");
        setVisible(true);
      })

  }

  const handleCallback = (childData, childKey) => {
    console.log("Parent received:", childData);

    setUpdGroup(childData);
    setSelectedNodeName(childKey);

    setVisible(false);

    console.log("Upload file ref:", uploadFileRef);

    if (uploadFileRef.current !== null) {
      uploadFileRef.current.value = "";
    }


  }

  const Export_excel = () => {

    const uri = `${api_host}/hierarchy/export?path=` + selectedGroup+`&timestamp=` + timestamp;
    const encodedURI = encodeURI(uri);

    const filename = selectedGroup + ".xlsx"

    console.log("encoded URI:", encodedURI);
    fetch(encodedURI,
      {
        method: 'GET',
        headers: {
          'Authorization': `ApiKey ${token}`,
          'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }
      })
      .then((res) => res.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(
          new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          filename
        )
        // .catch((err)=>{
        //   console.log("errer: ",err)
        // });

        console.log("Link to download:", link);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);

        //do not show the download template button anymore
      });

  }

  return (
    <>
      <h2>故障设备</h2>
      <div className="App">
        <div className="demo-container">
          <div className="column-left">
            <GroupMenuNew
              groups={groups}
              loading={loadingGroups}
              parentCallback={handleCallback}
              initialGroup={
                selectedGroup
              }
            />
          </div>
          <div className="column-right">
            <Alert color={alertType} isOpen={visible} toggle={onDismiss}>
              {feedback}
            </Alert>

            <Container
              className="themed-container" fluid={true}
            >
              {selectedGroup !== "" ? (

                <>
                  <Row>
                    <Col>
                      <InputGroup>
                        <InputGroupAddon
                          addonType="prepend"
                        >
                          <InputGroupText>
                          最后更新日:
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="select"
                          value={timestamp}
                          onChange={(e) =>{settimestamp(Number(e.target.value))}}
                        >
                          <option value="3">3日前更新</option>
                          <option value="7">7日前更新</option>
                          <option value="30">30日前更新</option>
                        </Input>
                      </InputGroup>
                    </Col>
                  </Row>
                  <span className="float-right ">
                    <Button
                      onClick={Export_excel}
                    >
                      导出数据
                    </Button>
                  </span>
                  <QueryClientProvider client={queryClient}>
                    <FaultDevicesTable
                      columns={listHeaderDebug}
                      path={selectedGroup}
                      timestamp={Date.now() - parseInt(timestamp)*60*60*24*1000}
                      outdatetime={timestamp}
                    />
                  </QueryClientProvider>
                </>
              ) : (
                <h3>请选择群组</h3>
              )}

            </Container>
          </div>
        </div>
      </div>
    </>
  )
}

export default FaultDevices;