import React from 'react';
import './UserTable.css';
import '../styles.css';

const UserTable = (props) => {
    console.log('UserTable:', props.users)
    return (
        <table>
            <thead>
                <tr className="theader">
                    <th>用户名</th>
                    <th>全名</th>
                    <th>修改密码</th>
                    <th>清除</th>
                </tr>
            </thead>
            <tbody>
                { props.users.length > 0 ? (
                    props.users.map(user => {
                        console.log('Rendering user:', user);
                        console.log('User full name:', user.full_name);
                        return (
                            <tr>
                                <td className="t-cell-5a text-left">{user.username}</td>
                                <td className="t-cell-5a text-left">
                                    {
                                        user.full_name
                                    }
                                </td>
                                <td className="t-cell-8 text-center">
                                <button class="btn btn-secondary btn-xs" style={{width:'70%'}} onClick={() => props.setEditing(user.username, true)}>修改密码</button>
                                </td>
                                <td className="t-cell-8 text-center">
                                <button class="btn btn-secondary btn-xs" style={{width:'70%'}} onClick={() => props.deleteUser(user.username)}>清除</button>
                                </td>
                            </tr>
                        )
                    })
                ) : (
                    <tr>
                        <td colSpan={4}>未找到相应的用户</td>
                    </tr>
                )   
                }
            </tbody>
        </table>
    )
}

export default UserTable;