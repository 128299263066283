import React, {useState} from 'react';
import { Label, Input } from "reactstrap";

const EditUserForm = (props) => {

    //useEffect(() => {
    //    setUser(props.currentUser)
    //}, [props])

    const [user, setUser] = useState(props.currentUser);
    const [confirmPassword, setConfirmPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [passwordShown, setPasswordShown] = useState(false);
    const initErrors = [];
    const [errors, setError] = useState(initErrors);

    const handleChange = (e) => {

        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        console.log('name:', name);
        console.log('value:', value);

        if (name === 'confirmPassword') {
            setConfirmPassword(value);
        } else if (name === 'newPassword') {
            setNewPassword(value);
        }

        setError([]);

    }

    const handleSubmit = e => {
        e.preventDefault();

        let formIsValid = true;
        setError([]);

        if (!newPassword.trim() || typeof newPassword === "undefined") {
            setError(errors => errors.concat("确认新密码不能为空"));
            formIsValid = false;
        }
        if (newPassword.trim().indexOf(' ') >= 0) {
            setError(errors => errors.concat("确认新密码不能包含空格"));
            formIsValid = false;
        }
        if (!confirmPassword.trim() || typeof confirmPassword === "undefined") {
            setError(errors => errors.concat("旧密码不能为空"));
            formIsValid = false;
        }
        if (confirmPassword.trim().indexOf(' ') >= 0) {
            setError(errors => errors.concat("旧密码不能包含空格"));
            formIsValid = false;
        }

        if (newPassword.trim() && confirmPassword.trim()) {
            if (newPassword.trim() !== confirmPassword.trim()) {
                setError(errors => errors.concat("密码不匹配"));
                formIsValid = false;
            }
        }

        if (formIsValid) {
        //modify the user's password in backend storage
            props.editUser(user, newPassword);
            setNewPassword("");
            setConfirmPassword("");
            props.setEditing(false);
        }

    }

    const togglePasswordVisibility = event => {
        if (event.target.checked) {
            setPasswordShown(true);   
        } else {
            setPasswordShown(false);
        }
    };

    return (

        <div className="container">
            <form onSubmit={handleSubmit} className="form-signin">
                <div className="row">
                    <div className="col">
                        <h3>输入{' '}{user}{' '}的新密码
                        </h3>
                        <input
                            type={passwordShown ? "text" : "password"}
                            name="newPassword"
                            value={newPassword}
                            onChange={handleChange}
                            placeholder="输入密码"
                        />
                        <input
                            type={passwordShown ? "text" : "password"}
                            name="confirmPassword"
                            value={confirmPassword}
                            onChange={handleChange}
                            placeholder="确认密码"
                        />
                        <div className="form-group">
                            <Label check>
                                <Input 
                                    type="checkbox"
                                    style={{width: 'auto'}}
                                    onChange={togglePasswordVisibility}/>{' '} 
                                    显示密码
                            </Label>
                        </div>
                        <button class="btn btn-primary btn-xs" style={{display: 'inline'}} onClick={handleSubmit} >修改密码</button>{' '}
                        <button class="btn btn-primary btn-xs" style={{display: 'inline'}} onClick={() => props.setEditing(false)} >取消</button>
                    </div>
                </div>
            </form>
            {errors && errors.length > 0 && (
                errors.map((msg, id) =>
                    <h3 key={id} className="text-danger">{msg}</h3>
                )
            )}
        </div>
    )
}

export default EditUserForm;