import React, {useState} from 'react';
import { Label, Input, Alert, Row, Col, Container } from "reactstrap";
import axios from 'axios';
import "./UserButton.css";
import * as myConstClass from '../utils/fileWithConstants';

const UpdUserPwd = (props) => {

    const [user, setUser] = useState(props.currentUser);
    const [confirmPassword, setConfirmPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [passwordShown, setPasswordShown] = useState(false);
    const initErrors = [];
    const [errors, setError] = useState(initErrors);
    const [visible, setVisible] = useState(false);
    const [feedback, setFeedback] = useState("");
    const [alertType, setAlertType] = useState("info");

    const onDismiss = () => setVisible(false);

    const handleChange = (e) => {

        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        console.log('name:', name);
        console.log('value:', value);

        if (name === 'confirmPassword') {
            setConfirmPassword(value);
        } else if (name === 'newPassword') {
            setNewPassword(value);
        }

        setError([]);

    }

    const handleSubmit = e => {
        e.preventDefault();

        let formIsValid = true;
        setError([]);

        if (!newPassword.trim() || typeof newPassword === "undefined") {
            setError(errors => errors.concat("确认新密码不能为空"));
            formIsValid = false;
        }
        if (newPassword.trim().indexOf(' ') >= 0) {
            setError(errors => errors.concat("确认新密码不能包含空格"));
            formIsValid = false;
        }
        if (!confirmPassword.trim() || typeof confirmPassword === "undefined") {
            setError(errors => errors.concat("旧密码不能为空"));
            formIsValid = false;
        }
        if (confirmPassword.trim().indexOf(' ') >= 0) {
            setError(errors => errors.concat("旧密码不能包含空格"));
            formIsValid = false;
        }

        if (newPassword.trim() && confirmPassword.trim()) {
            if (newPassword.trim() !== confirmPassword.trim()) {
                setError(errors => errors.concat("密码不匹配"));
                formIsValid = false;
            }
        }

        if (formIsValid) {
        //modify the user's password in backend storage
            editUser(newPassword);
            setNewPassword("");
            setConfirmPassword("");
        }

    }

    const editUser = (newPassword) => {

        const token = localStorage.getItem("token");
        const editUsername = localStorage.getItem("username");
        const userinfo = {
            "password": newPassword.trim()
        }
        const api_host = process.env.REACT_APP_API_URL || myConstClass.REACT_APP_API_URL;
        const uri = `${api_host}/user/`+editUsername;
        const encodedURI = encodeURI(uri);
        console.log("encoded URI:", encodedURI)
        console.log("decoded URI:", decodeURI(encodedURI))

        //API call to update the user information
        axios.post(encodedURI, userinfo, {
          headers: {
            'Authorization': `ApiKey ${token}`
          }
        })
        .then ((res) => {
            console.log("res:", res.data);
            console.log("status:", res.status);
            setFeedback(`用户 ${user} 密码已成功修改.`);
            setAlertType("success");
            setVisible(true);         
        })
        .catch ((e) => {
            const errorObject=JSON.parse(JSON.stringify(e));
            console.log(errorObject);
            setFeedback(`用户 ${user} 密码修改不成功 (原因: ${errorObject}).`);
            setAlertType("danger");
            setVisible(true);
        })
    }

    const togglePasswordVisibility = event => {
        if (event.target.checked) {
            setPasswordShown(true);   
        } else {
            setPasswordShown(false);
        }
    };

    return (

        <div className="App">
        <Alert color={alertType} isOpen={visible} toggle={onDismiss}>
            {feedback}
        </Alert>
        <Container 
            className="themed-container"
            style={{
              "display": "flex",
              "height": "100vh",
              "margin": "0px",
              "padding": "0px",
              "justifyContent": "center"
            }}
            fluid
        >
        <Row>
        <Col>
        <form onSubmit={handleSubmit} className="form-signin">
            <div className="row">
                <div className="col">
                    <h2>输入{' '}{user}{' '}的新密码
                    </h2>
                    <input
                        type={passwordShown ? "text" : "password"}
                        name="newPassword"
                        value={newPassword}
                        onChange={handleChange}
                        placeholder="输入密码"
                        style={{border:'1px solid #ced4da'}}
                    />
                    <input
                        type={passwordShown ? "text" : "password"}
                        name="confirmPassword"
                        value={confirmPassword}
                        onChange={handleChange}
                        placeholder="确认密码"
                        style={{border:'1px solid #ced4da'}}
                    />
                    <div className="form-group">
                        <Label check>
                            <Input 
                                type="checkbox"
                                style={{width: 'auto'}}
                                onChange={togglePasswordVisibility}/>{' '} 
                                显示密码
                        </Label>
                    </div>
                    <button class="btn btn-primary btn-xs" style={{display: 'inline'}} onClick={handleSubmit} >修改密码</button>{' '}

                    {errors && errors.length > 0 && (
                        errors.map((msg, id) =>
                            <h3 key={id} className="text-danger">{msg}</h3>
                        )
                    )}

                </div>
            </div>
        </form>
        </Col>
        </Row>

        </Container>
        </div>
    )
}

export default UpdUserPwd;